import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TravelService } from '../../services/travel.service';
import { TravelRoutingModule } from './travel-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DxModule } from 'src/app/modules/dx.module';
import { VAITSNComponentsModule } from '../../components/vaitsn-components.module';

import { TravelVouchersComponent } from './travel-vouchers/travel-vouchers.component';
import { TravelVoucherDetailsComponent } from './travel-voucher-details/travel-voucher-details.component';



@NgModule({
  declarations: [
    TravelVouchersComponent,
    TravelVoucherDetailsComponent,
  ],
  imports: [
    CommonModule,
    TravelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    VAITSNComponentsModule,
    // Material Modules
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule,
    // DevExtreme
    DxModule,
  ],
  providers: [
    TravelService,
    DatePipe
  ],
  exports: [
    CommonModule
  ]
})
export class TravelModule { }
