import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SimplePageLayoutComponent } from 'src/app/modules/core/layouts/simple-page-layout/simple-page-layout.component';
import { LoginGuard } from '../core/guards/login.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RefreshPasswordComponent } from './pages/refresh-password/refresh-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UserInvitationComponent } from './pages/user-invitation/user-invitation.component';

const routes: Route[] = [{
  path: 'login',
  canActivate: [LoginGuard],
  component: LoginComponent,
},
{
  path: 'forgot-password',
  component: ForgotPasswordComponent,
},
{
  path: 'reset-password/:token',
  component: ResetPasswordComponent,
},
{
  path: 'user-invitation/:token',
  component: UserInvitationComponent,
},
{
  path: 'reset-expired-password/:token',
  component: RefreshPasswordComponent,
}
      

  // {
  //   path: '',
  //   component: SimplePageLayoutComponent,
  //   children: [
  //     {
  //       path: 'login',
  //       canActivate: [LoginGuard],
  //       component: LoginComponent,
  //     },
  //     {
  //       path: 'forgot-password',
  //       component: ForgotPasswordComponent,
  //     },
  //     {
  //       path: 'reset-password/:token',
  //       component: ResetPasswordComponent,
  //     },
  //     {
  //       path: 'user-invitation/:token',
  //       component: UserInvitationComponent,
  //     },
  //     {
  //       path: 'reset-expired-password/:token',
  //       component: RefreshPasswordComponent,
  //     },
  //   ],
  // },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthFlowRoutingModule { }
