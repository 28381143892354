<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <h1 class="form-h1">Training</h1>

    <form [formGroup]="eventTrainingForm" (ngSubmit)="onSubmit()">
        <div class="topics-grid-sm-1col topics-grid-lg-3cols">
            <div *ngFor="let topic of eventTopics">
                <div class="vaitsn-form-control">
                    <mat-checkbox [formControlName]="topic.entityName" [id]="topic.entityName">
                        {{topic.displayName}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="other-presenters-section">
            <mat-label>Additional Presenters</mat-label> <br>
            <div class="addiontal-presenter-lookup" *ngFor="let presenter of additionalPresenters; let i = index">
                <div>
                    <app-lookup-coach [currentvaUser]="presenter" (selectVAUser$)="selectedPresenter($event, i)"></app-lookup-coach>
                </div>
                <div class="remove-presenter-btn">
                    <vaitsn-button color="warn" role="button" [hasLoading]="false" size="square" customWidth="100%" customHeight="45px" class="vaitsn-btn-primary" (click)="removeAdditionalPresenter(i)">X</vaitsn-button>
                </div>
            </div>
            <div class="insert-row-container">
                <vaitsn-button color="secondary" role="button" [hasLoading]="false" size="square" customWidth="100%" customHeight="40px" class="vaitsn-btn-primary" (click)="insertNewPresenterRow()">Add Additional Presenter</vaitsn-button>
            </div>
        </div>
        <div class="eval-score-container">
            <mat-label>Evaluation Score and Measurements</mat-label>
            <div class="eval-score-grid">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Evaluation Average</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-field">
                            <input type="number" matInput formControlName="evalAvg" placeholder="1-5">
                        </mat-form-field>
                    </div>
                    <div *ngIf="isInputInvalid('evalAvg')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Evaluation Average is Required (0-5).
                        </div>
                    </div>
                </div>
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Pre Training (%)</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-field">
                            <input type="number" matInput formControlName="preK" placeholder="0-100">
                        </mat-form-field>
                    </div>
                    <div id="measurementsError" *ngIf="eventTrainingForm.errors?.['measurements']"
                        class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Pre and Post <span style="text-decoration: underline;">OR</span> Knowledge Measures are required.
                        </div>
                    </div>
                    <div id="prePostMinMaxError" *ngIf="!eventTrainingForm.errors?.['measurements'] 
                        && (eventTrainingForm.controls['preK'].errors?.['min'] || eventTrainingForm.controls['preK'].errors?.['max'] 
                            || eventTrainingForm.controls['postK'].errors?.['min'] || eventTrainingForm.controls['postK'].errors?.['max']
                            || eventTrainingForm.controls['regKm2'].errors?.['min'] || eventTrainingForm.controls['regKmPre'].errors?.['min'])"
                        class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Enter valid Pre and Post (0-100) <span style="text-decoration: underline;">OR</span> Knowledge Measures.
                        </div>
                    </div>
                </div>
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Post Training (%)</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-field">
                            <input type="number" matInput formControlName="postK" placeholder="0-100">
                        </mat-form-field>
                    </div>
                </div>
                <div class="vaitsn-form-grid-cell">
                    <mat-label>RegKM No Inc</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-field">
                            <input type="number" matInput formControlName="regKm2">
                        </mat-form-field>
                    </div>
                </div>
                <div class="vaitsn-form-grid-cell">
                    <mat-label>RegKM Inc</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-field">
                            <input type="number" matInput formControlName="regKmPre">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="grid-1col">
                <!-- TODO: fix issue with comments resize overlapping elements below it -->
                <div id="otopic2" class="grid-textarea">
                    <mat-label>Comments</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-text-area">
                            <textarea matInput formControlName="otopic2" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2" placeholder="Enter Any Comments"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="vaitsn-form-submit-container" [ngClass]="{'disabled': eventTrainingForm.invalid }">
            <vaitsn-button color="secondary" (click)="onBackClicked()">Previous</vaitsn-button>
            <div></div>
            <vaitsn-button color="primary" class="vaitsn-btn-primary"
                role="submit" (submitted)="onSubmit()">Next</vaitsn-button>
        </div>
    </form>
</div>