import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UsersRoutingModule } from './users-routing.module';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { SharedModule } from '../../shared/shared.module';
import { UserRoleManagementComponent } from './components/user-role-management/user-role-management.component';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    UserManagementComponent,
    AddUserComponent,
    EditUserComponent,
    UserRoleManagementComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    TableModule,
    TooltipModule,
    DialogModule,
    ButtonModule
  ],
})
export class UsersModule {}
