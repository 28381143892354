export interface IProgramSearchQuery {
  region?: number | null,
  code?: string;
  name?: string;
  address?: string;
  classroom?: string; // Name or StateId
  created?: string;
};

export interface IProgramClassroomSearchQuery {
  programRegionId?: number | null,
  programCode?: string;
  programName?: string;
  programAddress?: string;
  classroom?: string; // Name or StateId
};
