export enum UserRole {
    Admin = "admin",
    InternalUser = "internal user",
    ExternalUser = 'external user'
}

export enum DocumentStatus {
    NotReviewed = "Not Reviewed",
    UnderReview = "Under Review",
    Reviewed = "Reviewed",
    Final = "Final"
};

export enum CategoryStatus {
    InProgress = "In Progress",
    Complete = "Complete",
};

export enum PropertyStatus {
    Active = "active",
    Closed = "closed",
};

export class Constants {
    public static documentStatuses: string[] = [
        DocumentStatus.NotReviewed,
        DocumentStatus.UnderReview,
        DocumentStatus.Reviewed,
        DocumentStatus.Final
    ]

    public static categoryStatuses: string[] = [
        CategoryStatus.InProgress,
        CategoryStatus.Complete,
    ]

    public static propertyStatuses: string[] = [
        PropertyStatus.Active,
        PropertyStatus.Closed,
    ]

    public static maxFileSize: number = 1000000000; // 1GB
}