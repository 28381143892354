<p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
<div class="shadow table-border">
  <p-table [value]="categoryGroups | async" dataKey="id" #dt [globalFilterFields]="['name', 'documentNames']" [resizableColumns]="true" [loading]="loading"
    responsiveLayout="scroll" [rowHover]="true" [expandedRowKeys]="expandedRows"> 
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between flex-wrap">
        <div><h2>{{property?.name | titlecase}}</h2></div>
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <span class="p-input-icon-left ml-auto me-4 pb-2">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search($event, dt)" placeholder="Search keyword" />
          </span>
          <button pButton (click)="showUploadDialog()" label="Documents" icon="pi pi-plus"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn class="text-center" style="cursor: pointer; " (click)="expandAllToggle()">  
          <ng-container [ngSwitch]="expandCategories" >
            <i class="pi pi-angle-down" *ngSwitchCase="true"></i>
            <i class="pi pi-angle-right" *ngSwitchCase="false"></i>
          </ng-container>
        </th>
        <th pSortableColumn="number" pResizableColumn class="text-center">#<p-sortIcon field="number"></p-sortIcon></th>
        <th pSortableColumn="name" pResizableColumn>Category<p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="status" pResizableColumn>Status<p-sortIcon field="status"></p-sortIcon></th>
        <th>Last Updated</th>
        <th pResizableColumn class="text-center">Documents</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-categoryGroup let-expanded="expanded">
      <tr pDroppable="dnd" (drop)="dropFunction($event, categoryGroup)" style="height:2.5rem" class="property-row">
        <td>
          <button type="button" pButton pRipple (click)="expand(categoryGroup.id)"
            class="p-button-text p-button-rounded p-button-plain p-mr-2"
            [icon]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></button>
        </td>
        <td class="text-center">
          <span class="p-text-bold p-ml-2">{{categoryGroup?.number ?? '0'}}</span>
        </td>
        <td style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <div class="p-text-bold p-ml-2 pointer"  role="button" routerLink="/app/documents/{{ propertyId }}/{{categoryGroup.id}}">{{categoryGroup.name }}</div>
        </td>
        <td>
          <app-category-status [status]="categoryGroup?.status" [editable]="allowStatusChange" (selectionChange)="updateCategoryStatus(categoryGroup.id, $event)"></app-category-status>
        </td>
        <td>
          <span class="p-text-bold p-ml-2">{{categoryGroup.documents.length > 0 ? (findLatest(categoryGroup.documents) | date) : ''}}</span>
        </td>
        <td class="text-center">
          <span class="p-text-bold p-ml-2">{{categoryGroup.documents.length > 0? categoryGroup.documents.length : ''}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-categoryGroup>
      <tr style="background-color:#eee">
        <td colspan="6">
          <div class="p-3">
            <p-table [value]="categoryGroup.documents" dataKey="id" [rowHover]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="name">Name<p-sortIcon field="name" class="p-1"></p-sortIcon></th>
                  <th pSortableColumn="status">Status<p-sortIcon field="status" class="p-1"></p-sortIcon></th>
                  <th pSortableColumn="createDate">Upload Date<p-sortIcon field="createDate" class="p-1"></p-sortIcon></th>
                  <th pResizableColumn class="p-1"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-file>
                <tr>
                  <td style="overflow: hidden; text-overflow: ellipsis; max-width: 40ch;">
                    <ng-container [ngSwitch]="fileIcon(file.name)" >
                      <i class="pi pi-file-pdf" *ngSwitchCase="'pdf'"></i>
                      <i class="pi pi-file-excel" *ngSwitchCase="'excel'"></i>
                      <i class="pi pi-image" *ngSwitchCase="'images'"></i>
                      <i class="pi pi-file" *ngSwitchCase="'file'"></i>
                    </ng-container>
                    {{file.name}}
                  </td>
                  <td><app-document-status [editable]="false" [status]="file.status"></app-document-status></td>
                  <td>{{file.createDate | date}}</td>
                  <td style="width: 180px">
                    <app-document-category [categories]="categories" [category]="file.category" (selectionChange)="updateCategory($event, file)"></app-document-category>
                    <button
                      pTooltip="Edit"
                      type="button"
                      pButton
                      pRipple
                      class="p-button-text p-button-rounded p-button-plain p-mr-2"
                      icon="bi bi-pencil-square"
                      title="Edit"
                      (click)="showDocumentDetails(file)"
                    ></button>
                    <button
                      pTooltip="Delete"
                      type="button"
                      pButton
                      pRipple
                      class="p-button-text p-button-rounded p-button-plain p-mr-2"
                      icon="bi bi-trash3-fill"
                      title="Delete"
                      (click)="deleteDocument(file.id)"
                    ></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <td colspan="6"><h4 class="text-center m-3" style="width: max-content;" >No categories/documents found.</h4></td>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Delete Document" [(visible)]="showConfirmModal" [modal]="true" [style]="{width: '400px'}"
  [baseZIndex]="5000" [draggable]="false" [resizable]="false">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column">
      <div class="text-center">
        <p>Are you sure you want to delete this document?<br>This document will be deleted immediately. You can't undo
          this action.</p>
      </div>
    </div>
  </ng-template>

  <ng-template let-message pTemplate="footer">
    <button pButton  type="button" (click)="onCancelDelete()" label="Cancel" class="p-button-primary p-button-text"></button>
    <button pButton  type="button" (click)="onConfirmDelete()" label="Delete" class="p-button-danger p-button-text"></button>
  </ng-template>
</p-dialog>