import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { 
  Subject, 
  filter, 
  map, 
  takeUntil, 
  tap 
} from 'rxjs';
//for coaches
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
//lookup program and classroom and child
import { ILookupAssociatedClassProgramChild } from '../../models/interfaces/lookup-associated-class-program-child.interface';
//TPITOS  -- used for posting
import { Tpitos } from '../../models/interfaces/tpitos.interface';
import { TpitosService } from '../../services/tpitos.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
  selector: 'app-tpitos',
  templateUrl: './tpitos.component.html',
  styleUrls: ['./tpitos.component.scss']
})

export class TpitosComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;  
  public TpitosForm: FormGroup = null;
  public TpitosFormSubmit: Tpitos = null;

  constructor(
    public vaUserService: VAUserService,
    private tpitosService: TpitosService,
    private router: Router,
    private toast: ToastService,
  ){}

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.TpitosForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
        }
      })
    ).subscribe()
  }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.TpitosForm.patchValue({
      Program: value.programId, 
    });
    this.TpitosForm.patchValue({
      Classroom: value.classroomId, 
    });
    this.TpitosForm.patchValue({
      Child: value.childId, 
    });
    this.TpitosForm.patchValue({
      LookupId: value.id
    })
  }
  
  public selectedVAUser( value: IVAUser){
    this.currentVAUser = value;
    this.TpitosForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
  }

  private initForm(){
    this.TpitosForm = new FormGroup({
      User: new FormControl(null, [Validators.required]),
      DateSubmitted: new FormControl({ value: new Date(), disabled: true}),
      Classroom: new FormControl('', [Validators.required]),
      ObservationDate: new FormControl('', [Validators.required]),
      Program: new FormControl('', [Validators.required]),
      Child: new FormControl(''),
      TeacherName: new FormControl('', [Validators.required]),
      Cbry: new FormControl('', [Validators.required]),
      Cbrn: new FormControl('', [Validators.required]),
      Dwry: new FormControl('', [Validators.required]),
      Dwrn: new FormControl('', [Validators.required]),
      Ppiy: new FormControl('', [Validators.required]),
      Ppin: new FormControl('', [Validators.required]),
      Caey: new FormControl('', [Validators.required]),
      Caen: new FormControl('', [Validators.required]),
      Refy: new FormControl('', [Validators.required]),
      Refn: new FormControl('', [Validators.required]),
      Cbey: new FormControl('', [Validators.required]),
      Cben: new FormControl('', [Validators.required]),
      Rdcy: new FormControl('', [Validators.required]),
      Rdcn: new FormControl('', [Validators.required]),
      Smdy: new FormControl('', [Validators.required]),
      Smdn: new FormControl('', [Validators.required]),
      Srty: new FormControl('', [Validators.required]),
      Srtn: new FormControl('', [Validators.required]),
      Eay: new FormControl('', [Validators.required]),
      Ean: new FormControl('', [Validators.required]),
      Tcpy: new FormControl('', [Validators.required]),
      Tcpn: new FormControl('', [Validators.required]),
      Eepy: new FormControl('', [Validators.required]),
      Eepn: new FormControl('', [Validators.required]),
      Cwfy: new FormControl('', [Validators.required]),
      Cwfn: new FormControl('', [Validators.required]),
      TCRedYes: new FormControl('', [Validators.required]),
      TCRedNo: new FormControl('', [Validators.required]),
      ClassRedYes: new FormControl('', [Validators.required]),
      ClassRedNo: new FormControl('', [Validators.required]),
    });
  }

  public isInputValid = (formControlName: string): boolean => (!this.TpitosForm.get(formControlName)?.valid && (this.TpitosForm.get(formControlName).dirty || (this.TpitosForm.get(formControlName).touched)));

  onSubmit(){
    this.TpitosForm.markAllAsTouched();
    if (!this.TpitosForm.valid){
      console.warn("error happened: ", this.TpitosForm.value);
    } else {
      this.finalizeTpitosFormData();
      this.tpitosService.post(this.TpitosFormSubmit)   
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }

  finalizeTpitosFormData(){
    this.TpitosForm.patchValue ({ User: this.currentVAUser.id});
      this.TpitosFormSubmit = {
        VAUserId: this.TpitosForm.controls['User'].value,
        ClassroomId: this.TpitosForm.controls['Classroom'].value,
        Observation: this.TpitosForm.controls['ObservationDate'].value,
        TeacherName: this.TpitosForm.controls['TeacherName'].value,
        CBRY: this.TpitosForm.controls['Cbry'].value,
        CBRN: this.TpitosForm.controls['Cbrn'].value,
        DWRY: this.TpitosForm.controls['Dwry'].value,
        DWRN: this.TpitosForm.controls['Dwrn'].value,
        PPIY: this.TpitosForm.controls['Ppiy'].value,
        PPIN: this.TpitosForm.controls['Ppin'].value,
        CAEY: this.TpitosForm.controls['Caey'].value,
        CAEN: this.TpitosForm.controls['Caen'].value,
        REFY: this.TpitosForm.controls['Refy'].value,
        REFN: this.TpitosForm.controls['Refn'].value,
        CBEY: this.TpitosForm.controls['Cbey'].value,
        CBEN: this.TpitosForm.controls['Cben'].value,
        RDCY: this.TpitosForm.controls['Rdcy'].value,
        RDCN: this.TpitosForm.controls['Rdcn'].value,
        SMDY: this.TpitosForm.controls['Smdy'].value,
        SMDN: this.TpitosForm.controls['Smdn'].value,
        SRTY: this.TpitosForm.controls['Srty'].value,
        SRTN: this.TpitosForm.controls['Srtn'].value,
        EAY:  this.TpitosForm.controls['Eay'].value,
        EAN:  this.TpitosForm.controls['Ean'].value,
        TCPY: this.TpitosForm.controls['Tcpy'].value,
        TCPN: this.TpitosForm.controls['Tcpn'].value,
        EEPY: this.TpitosForm.controls['Eepy'].value,
        EEPN: this.TpitosForm.controls['Eepn'].value,
        CWFY: this.TpitosForm.controls['Cwfy'].value,
        CWFN: this.TpitosForm.controls['Cwfn'].value,
        TCRedYes: this.TpitosForm.controls['TCRedYes'].value,
        TCRedNo:  this.TpitosForm.controls['TCRedNo'].value,
        ClassRedYes:  this.TpitosForm.controls['ClassRedYes'].value,
        ClassRedNo:   this.TpitosForm.controls['ClassRedNo'].value,
        Created:  this.TpitosForm.controls['DateSubmitted'].value,
        CreatedByUser: 'null',
        LastUpdatedUser: 'null',
      };
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}


