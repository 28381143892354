import { IChild } from "./interfaces/child.interface";
import { IClassroom } from "./interfaces/classroom.interface";
import { IEventParticipant } from "./interfaces/event-participant.interface";
import { IEventPresenter } from "./interfaces/event-presenter.interface";
import { IEvent, IEventDetails, IEventType } from "./interfaces/event.interface";
import { IProgram } from "./interfaces/program.interface";
import { IRegion } from "./interfaces/region.interface";

export class EventObj {
    event: IEvent
    eventRegion: IRegion
    eventType: IEventType
    eventChild: IChild
    eventProgram: IProgram
    eventClassroom: IClassroom
    eventDetail: IEventDetails
    eventPresenters: Array<IEventPresenter>
    eventParticipants: Array<IEventParticipant>

    public constructor() {
        this.event = {} as IEvent;
        this.eventRegion = {} as IRegion;
        this.eventType = {} as IEventType;
        this.eventChild = {} as IChild;
        this.eventProgram = {} as IProgram;
        this.eventClassroom = {} as IClassroom;
        this.eventDetail = {} as IEventDetails;
        this.eventPresenters = [];
        this.eventParticipants = [];
    }
}