import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { filter, takeUntil, map, Subject, Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import { ReportService } from '../../services/report.service';
import { VAUserPermission, VAUserRole, VAUserService } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
import { IParamObj } from '../../models/interfaces/param-query.interface';

export interface ReportListItem {
  buttonText: string;
  reportName: string;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  reports: ReportListItem[] = [
    { buttonText: 'Training Activities', reportName: 'TrainingActivities' },
    { buttonText: 'Technical Assistance Activities', reportName: 'TechnicalAssistanceActivities' },
    { buttonText: 'Coaching Services', reportName: 'CoachingServices' },
    { buttonText: 'Travel Voucher', reportName: 'TravelVoucher' }
  ];
  VAUserRole = VAUserRole;

  public currentVAUser: IVAUser = null;
  public isCoach = false;
  private isAdmin = false;
  private isSupervisor = false;
  public isSpecialist = false;
  public isConsultant = false;

  showThenBlock = true;

  private vaUserSearchText$ = new Subject<string>();
  public vaUsers$: Observable<Array<IVAUser>>; //?
  private _subscribedSubjects$ = new Subject<boolean>();
  private baseFilter = ODataFilterBuilder('and');

  currentReportName: string | null = null;

  formGroup = new FormGroup({
    coach: new FormControl<IVAUser | null>(null, [Validators.required]),
    begin: new FormControl<Date | null>(null, [Validators.required]),
    end: new FormControl<Date | null>(null, [Validators.required]),
  });

  constructor(
    private reportService: ReportService,
    public vaUserService: VAUserService,
  ) { }

  /**
   * Get logged in user
   * And: if current user is admin/supervisor, get list of available VAUsers to choose from
   */
  ngOnInit() {
    this.getCurrentAssociatedVAUser();
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

  /**
   * Get list of coaches,
   * with region filter based on logged in user's role:
   * - Admin: region > 0 (i.e. get coach in any region)
   * - Supervisor: region = user's home region (i.e. get coaches in same region)
   * - Else: region = 0 (i.e. none since coach doesn't need search)
   */
  getCurrentAssociatedVAUser() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(u => u !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;

        this.isCoach = this.vaUserService.userHasRole(vaUser, VAUserRole.Coach);
        this.isAdmin = this.vaUserService.userHasRole(vaUser, VAUserRole.Admin);
        this.isSupervisor = this.vaUserService.userHasRole(vaUser, VAUserRole.Supervisor);
        this.isSpecialist = this.vaUserService.userHasPermission(vaUser,VAUserPermission.Specialist);
        this.isConsultant = this.vaUserService.userHasPermission(vaUser,VAUserPermission.Consultant);

        if (this.isAdmin) {
          this.baseFilter.gt('regionId', 0);
        }
        else if (this.isSupervisor) {
          this.baseFilter.eq('regionId', vaUser.regionId);
        }
        else {
          this.baseFilter.eq('regionId', 0);
        }
        
        if(this.isConsultant || (this.isAdmin || this.isSupervisor)) {
            this.reports.splice(3,0,{ buttonText: 'Child-Specific Coaching Services', reportName: 'ChildSpecificCoachingServices' });
        }
        if (this.isSpecialist || (this.isAdmin || this.isSupervisor)) {
            this.reports.push({ buttonText: 'CLASS Environment Scores', reportName: 'ClassEnvironmentScores' });
        }

        this.baseFilter.and(`VAUserRoles/any(userrole: userrole/role/normalizedName eq '${VAUserRole.Coach}')`);
        this.baseFilter.and(`VAUserPermissions/any(userperm: userperm/permission/normalizedName in `
          + `('${VAUserPermission.Specialist}', '${VAUserPermission.Consultant}'))`);

        if (this.isAdmin || this.isSupervisor) {
          this.getVAUsers();
        }
        if (this.isCoach) {
          this.formGroup.patchValue({ coach: this.currentVAUser });
        }
      })).subscribe();
  }

  private getVAUsers() {
    this.vaUsers$ = this.vaUserSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        let newFilter = new ODataFilterBuilder()
          .and(this.baseFilter)
          .eq('deleted', null)
          .and(ODataFilterBuilder('or')
            .contains('firstName', searchString)
            .contains('lastName', searchString)
            .contains('username', searchString));
        const params = {
          $filter: newFilter.toString(),
          $top: 10,
        } as IParamObj;
        return this.vaUserService.get(params);
      }),
    );
  }

  public vaUserDisplay = (user: IVAUser): string => (user) ? user.firstName + ' ' + user.lastName + ' | ' + user.username : '';

  public getEventValue(event: Event): any {
    return (event.target as HTMLInputElement).value;
  }

  public vaUserSearch(searchString: string) {
    this.vaUserSearchText$.next(searchString);
  }

  showElse = (reportName: string) => {
    this.formGroup.reset();

    if (this.isCoach) { // todo must be coach only?
      this.formGroup.patchValue({ coach: this.currentVAUser });
    }

    this.currentReportName = reportName;

    this.showThenBlock = false;
  };

  cancelElse(): void {
    this.showThenBlock = true;
  }

  submitElse(): void {
    this.reportService.generateReport(
          this.currentReportName,
          {
            coachEmail: this.formGroup.value.coach.normalizedEmail,
            begin: this.formGroup.value.begin,
            end: this.formGroup.value.end
          }
        ).subscribe();

    this.showThenBlock = true;
  }
}
