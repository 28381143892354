import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, combineLatest, filter, map, takeUntil, tap } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';

import { GUID, ILookupAssociatedClassProgramChild, IRegion, IVAUser } from '../../models';
import { VAUserRole, VAUserService } from '../../services/va-user.service';
import { DXODataService } from '../../services/dx-odata.service';
import { EventService } from '../../services/event.service';
import { RegionService } from '../../services/region.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  @ViewChild('eventReportGrid', { static: false }) eventsReportGrid: DxDataGridComponent;
  private _subscribedSubjects$ = new Subject<boolean>();
  public defaultDates = new Array<any>();
  public currentVAUser: IVAUser = null;
  public eventsReportDataSource: DataSource = null;
  public lookupPrograms: Array<ILookupAssociatedClassProgramChild> = [];
  public regions: Array<IRegion> = [];
  public filteredRegions: Array<IRegion> = [];
  public selectedRegion: IRegion = null;

  // TODO: Find out how to prevent a single call going out before the filters are finish initing.
  constructor(
    private router: Router,
    private eventService: EventService,
    public vaUserService: VAUserService,
    private dxOdataService: DXODataService,
    private regionService: RegionService,
  ) { }

  ngOnInit() {
    this.getDateDefaultFilters();

    this.getRequiredData().subscribe({
      next: value => {
        this.currentVAUser = value[0];

        this.regions = value[1];
        this.filteredRegions = value[1];
        if (this.currentVAUser?.regionId > 0) {
          this.selectedRegion = this.regions.find(r => r.id == this.currentVAUser.regionId);
        }

        let gridFilters = this.getEventFilters(this.currentVAUser);

        this.eventsReportDataSource = new DataSource({
          store: this.dxOdataService.context.EventsReport,
          filter: gridFilters,

          // eventDate comes back with a Z (zulu time) from the backend
          // for some reason. We just remove it here.
          postProcess: (data) => {

            data.forEach((item) => {

                if(item.eventDate.includes("Z")){
                    item.eventDate = item.eventDate.replace(/Z/g,'');
                }
                
            });

            return data;
          }
        });
      },
      error: err => {
        this.handlePageLoadError();
      }
    });

  }

  /**
   * Notify user of error
   * Return to previous step
   * @param err 
   */
  private handlePageLoadError(err?: string) {
    err && console.error(err);
  }

  /**
 * Get required data for grid
 * 1: 
 * 2: Get current VAUser
 * @returns 
 */
  private getRequiredData = () => {
    const getCurrentUser = this.getCurrentVAUser();
    const getRegions = this.getRegions();
    return combineLatest([getCurrentUser, getRegions]);
  }
  
  private getCurrentVAUser = () => {
    return this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(u => u !== null),
      takeUntil(this._subscribedSubjects$),
    );
  }

  private getRegions() {
    return this.regionService.get().pipe(
      filter(r => r !== null),
      takeUntil(this._subscribedSubjects$),
    );
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value?.toLowerCase();
  }

  public filterRegions(searchString: string) {
    this.filteredRegions = this.regions.filter(region => 
      region.name.toLowerCase().includes(searchString) || region.code.toLowerCase().includes(searchString)
    );
  }

  public regionDisplay = (region: IRegion): string => region?.name;


  getEventFilters(vaUser: IVAUser): Array<any> {
    let guid = new GUID(this.currentVAUser?.id);

    const filters: any[] = [];
    if ((this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) 
      || (this.vaUserService.userHasRole(vaUser, VAUserRole.Supervisor))
    ) {
      if (!this.selectedRegion) {
        filters.push(
          ['regionId', '=', (this.currentVAUser) ? this.currentVAUser.regionId : 0]
        );
      } else {
        filters.push(
          ['regionId', '=', this.selectedRegion.id]
        );
      }
    } else {
      filters.push(
        ['MainPresenterId', '=', guid],
        ['eventDate', '>=', this.defaultDates[0]],
        ['eventDate', '<=', this.defaultDates[1]]
      );
    }
    return (filters.length <= 0) ? null : filters;
  }

  onRegionChange(event: any) {
    this.eventsReportDataSource.filter(this.getEventFilters(this.currentVAUser));
    this.eventsReportGrid.instance.refresh();
  }

  /**
   * List events for past 31 days (events older than 31 days should not be editable)
   */
  getDateDefaultFilters() {
    let today = new Date();

    let begin = new Date(new Date().setDate(today.getDate() - 31));
    begin.setHours(0, 0, 0, 0);
    
    let end = new Date(new Date().setDate(today.getUTCDate() + 1));
    end.setHours(0, 0, 0, 0);
    
    this.defaultDates.push(begin, end);
  }

  public newEvent() {
    this.eventService.refreshCurrentEvent().pipe(tap((_) => this.router.navigate(['/app/event/details/', 0]))).subscribe();
  }

  /**
   * if user is admin or a coach, allow navigate to event edit page
   */
  public editEvent(e: MouseEvent, id: number) {
    if(this.vaUserService.userHasRole(this.currentVAUser, VAUserRole.Admin) 
      || this.vaUserService.userHasRole(this.currentVAUser, VAUserRole.Coach)){
        this.eventService.refreshCurrentEvent(id).pipe(tap((_) => this.router.navigate(['/app/event/details/', id]))).subscribe();
    }
    else{
      e.preventDefault;
    }
  }

  // TODO: Implement Preview page for drafting etc.. at late date.
  // public viewEvent(id: number) {
  //     this.eventService.refreshCurrentEvent(id).pipe(tap((_) => this.router.navigate(['/app/event/preview/', id]))).subscribe();
  //   }

  ngOnDestroy(): void {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}
