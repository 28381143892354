<link rel="stylesheet" href="https://use.typekit.net/cwv7ovo.css">

<div class="vaitsn-error-card">
  <div class="lockIcon">
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
      <g clip-path="url(#clip0_444_378)">
        <path
          d="M72 32H68V24C68 12.96 59.04 4 48 4C36.96 4 28 12.96 28 24V32H24C19.6 32 16 35.6 16 40V80C16 84.4 19.6 88 24 88H72C76.4 88 80 84.4 80 80V40C80 35.6 76.4 32 72 32ZM48 68C43.6 68 40 64.4 40 60C40 55.6 43.6 52 48 52C52.4 52 56 55.6 56 60C56 64.4 52.4 68 48 68ZM60.4 32H35.6V24C35.6 17.16 41.16 11.6 48 11.6C54.84 11.6 60.4 17.16 60.4 24V32Z"
          fill="#CACED2" />
      </g>
      <defs>
        <clipPath id="clip0_444_378">
          <rect width="96" height="96" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <p class="error-title">401 - Unauthorized</p>
  <p class="error-message">The account you are currently logged in with does not have the right permissions for
    viewing this page.
    <br>
    <br>
    If you believe this is an error, contact your administrator.
  </p>

  <a id="back-to-dashboard" class="vaitsn-hyperlink" routerLink="">Return to dashboard</a>
</div>