import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { ChildSpecificScale } from "../models/interfaces/child-specific-scale.interface";

@Injectable({
    providedIn: 'root'
})

export class ChildSpecificScaleService {
    
    constructor (
        private http: HttpClient,
    ) { }

    /**
    * Post Child specific scale form data to api
    */
        // public postChildSpecificScaleForm ( ChildSpecificScaleFormSubmittedData : any ) {
        //     return this.http.post<ChildSpecificScale>(environment.apiBaseURL + "ChildSpecificScale/CreateChildSpecificScale", ChildSpecificScaleFormSubmittedData).subscribe();
        // }

    public post(event: ChildSpecificScale): Observable<IActionResult>{
        return this.postChildSpecificScaleForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postChildSpecificScaleForm( ChildSpecificScaleFormSubmittedData: ChildSpecificScale): Observable<IActionResult>{
        return this.http.post<IActionResult>(environment.apiBaseURL + "ChildSpecificScale/CreateChildSpecificScale", ChildSpecificScaleFormSubmittedData);
    }
}