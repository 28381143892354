<div class="d-flex flex-column justify-content-start align-items-center">
  <div class="card shadow-sm" style="max-width: 435px">
    <div class="card-header">Reset Your Expired Password</div>
    <div class="card-body">
      <div class="form-text">
        Your password has expired, please set a new one to continue.
      </div>
      <app-password-confirmation (passwordSubmit)="onPasswordSubmit($event)">
      </app-password-confirmation>
    </div>
  </div>
</div>
