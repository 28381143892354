import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProperty, Property } from '../../models/property';
import { environment } from "../../../../../environments/environment";
import { IApiDataResponse, IApiResponse } from "../../models/api-response.interface";

@Injectable({
  providedIn: 'root',
})
export class PropertyApiService {
  private PROPERTY_API = `${environment.apiBaseURL}Property`;

  constructor(private http: HttpClient) {}

  public createProperty(property: Property): Observable<IApiDataResponse<IProperty>> {
    return this.http.post<IApiDataResponse<IProperty>>(`${this.PROPERTY_API}`, property);
  }

  public deleteProperty(id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${this.PROPERTY_API}?id=${id}`);
  }

  public updateProperty(property: Property): Observable<IApiDataResponse<IProperty>> {
    return this.http.put<IApiDataResponse<IProperty>>(`${this.PROPERTY_API}`, property);
  }

  public updatePropertyCategoryStatus(request: {categoryId: string, newStatus: string}): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.PROPERTY_API}/UpdateCategoryStatus`, request);
  }

  public getProperties(status: string = null): Observable<IApiDataResponse<IProperty[]>> {
    return this.http.get<IApiDataResponse<IProperty[]>>(`${this.PROPERTY_API}/List${status ? '?status='+`${status}`:''}`);
  }

  public getPropertyById(id: string): Observable<IApiDataResponse<IProperty>> {
    return this.http.get<IApiDataResponse<IProperty>>(`${this.PROPERTY_API}/ById?id=${id}`);
  }

  public setPropertyFavorite(id: string, favorite: boolean): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.PROPERTY_API}/SetFavorite?propertyId=${id}&favorite=${favorite}`, {});
  }
}
