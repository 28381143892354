import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Constants, CategoryStatus } from 'src/app/modules/shared/constants';

@Component({
    selector: 'app-category-status',
    styleUrls: ['./category-status.component.scss'],
    template: `
    <div *ngIf="editable; else readonly">
        <p-dropdown [options]="statusOptions" [ngModel]="status" (onChange)="onValueChange($event.value)" placeholder="Select a Status" appendTo="body" class="category-status-dropdown">
            <ng-template pTemplate="selectedItem">
                <div *ngIf="status">
                    <ng-container [ngSwitch]="status">
                        <p-chip *ngSwitchCase="CategoryStatus.InProgress" [label]="status" styleClass="mr-2" ></p-chip>
                        <p-chip *ngSwitchCase="CategoryStatus.Complete" [label]="status" styleClass="mr-2 green-chip" ></p-chip>
                    </ng-container>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <ng-template #readonly>
        <ng-container [ngSwitch]="status">
            <p-chip *ngSwitchCase="CategoryStatus.InProgress" [label]="status" styleClass="mr-2" ></p-chip>
            <p-chip *ngSwitchCase="CategoryStatus.Complete" [label]="status" styleClass="mr-2 green-chip" ></p-chip>
        </ng-container>
    </ng-template>
    `
})
export class CategoryStatusComponent {
    @Input() status: string;
    @Input() editable: boolean;
    @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
    public statusOptions = Constants.categoryStatuses;
    public CategoryStatus = CategoryStatus;

    constructor() { }

    onValueChange(status: string) {
        this.selectionChange.emit(status);
    }
}