import { Injectable } from "@angular/core";

export class ASQ {
    VAUserId: string;
    Created: string;
    Date: string;
    AgeMonth: number;
    Communication: string;
    GrossMotor: string;
    FineMotor: string;
    ProblemSolving: string;
    PersonalSocial: string;
    IsAdjustedForPrematurity: boolean;
    LookupAssociatedClassProgramChildId: number;
    CreatedByUser: string;
    LastUpdatedUser: string;
  
    constructor() {
      this.VAUserId = '';
      this.Created = new Date().toDateString();
      this.Date = new Date().toDateString();
      this.AgeMonth = null;
      this.Communication = '';
      this.GrossMotor = '';
      this.FineMotor = '';
      this.ProblemSolving = '';
      this.PersonalSocial = '';
      this.IsAdjustedForPrematurity = null;
      this.LookupAssociatedClassProgramChildId = null;
      this.CreatedByUser = null;
      this.LastUpdatedUser = null;

    }
}