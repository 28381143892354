import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { UserService } from "../services/user/user.service";


@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  @Input() appHasRole: string[];
  private _stop$ = new Subject<boolean>();
  private _isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef 
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef 
   *   -- the templateRef to be potentially rendered
   * @param {UserService} userService 
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.user$.pipe(
    	takeUntil(this._stop$)
    ).subscribe((user) => {
      if (!user.roles) {
        this.viewContainerRef.clear();
      }
      let hasRole = false;
      this.appHasRole.some(role => hasRole = (user.roles.includes(role)));
      if (hasRole) {
        if (!this._isVisible) {
          this._isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this._isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }

  ngOnDestroy() {
    this._stop$.next(true);
    this._stop$.complete();
  }
}