import { Injectable } from "@angular/core";

 export class Tpitos {
    VAUserId: string;
    ClassroomId: string;
    // ProgramID: string;
    // ClassroomName: string;
    Observation: string;
    TeacherName: string;
    CBRY: number;
    CBRN: number;
    DWRY: number;
    DWRN: number;
    PPIY: number;
    PPIN: number;
    CAEY: number;
    CAEN: number;
    REFY: number;
    REFN: number;
    CBEY: number;
    CBEN: number;
    RDCY: number;
    RDCN: number;
    SMDY: number;
    SMDN: number;
    SRTY: number;
    SRTN: number;
    EAY: number;
    EAN: number;
    TCPY: number;
    TCPN: number;
    EEPY: number;
    EEPN: number;
    CWFY: number;
    CWFN: number;
    TCRedYes: number;
    TCRedNo: number;
    ClassRedYes: number;
    ClassRedNo: number;
    Created: string;
    CreatedByUser: string;
    LastUpdatedUser: string;
  
    constructor() {
        this.VAUserId = null;
        this.ClassroomId = null;
        // this.ProgramID = null;
        // this.ClassroomName = null;
        this.Observation = new Date().toLocaleDateString();
        this.TeacherName = null;
        this.CBRY = null;
        this.CBRN = null;
        this.DWRY = null;
        this.DWRN = null;
        this.PPIY = null;
        this.PPIN = null;
        this.CAEY = null;
        this.CAEN = null;
        this.REFY = null;
        this.REFN = null;
        this.CBEY = null;
        this.CBEN = null;
        this.RDCY = null;
        this.RDCN = null;
        this.SMDY = null;
        this.SMDN = null;
        this.SRTY = null;
        this.SRTN = null;
        this.EAY = null;
        this.EAN = null;
        this.TCPY = null;
        this.TCPN = null;
        this.EEPY = null;
        this.EEPN = null;
        this.CWFY = null;
        this.CWFN = null;
        this.TCRedYes = null;
        this.TCRedNo = null;
        this.ClassRedYes = null;
        this.ClassRedNo = null;
        this.Created = new Date().toDateString();
        this.CreatedByUser = null;
        this.LastUpdatedUser = null;
    }
  
  }