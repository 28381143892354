<app-user-editor (submitUser)="onSubmit($event)" [adminForm]="true">
  <ng-container roleManagement>
    <app-user-role-management></app-user-role-management>
  </ng-container>
  <ng-container title> New User </ng-container>
  <button cancelButton class="btn btn-secondary me-0 me-md-2" (click)="onCancel()">
    Cancel
  </button>
  <button submitButton type="submit" class="btn btn-primary me-0 ms-2 mt-md-0" [disabled]="submitting">
    <ng-container *ngIf="submitting; else default">
      <div class="spinner-border spinner-border-sm" role="status"></div>
      Creating...
    </ng-container>

    <ng-template #default>Create</ng-template>
  </button>
</app-user-editor>