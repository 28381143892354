import { IVAUser, IVAUserPermission, IVAUserRole } from "./interfaces/va-user.interface";

export class VAUser implements IVAUser {
    id: string;
    username: string;
    email: string;
    normalizedEmail: string;
    firstName: string;
    normalizedFirstName: string;
    lastName: string;
    normalizedLastName: string;
    regionId: number;
    phoneNumber: string;
    homeAddress: string;
    createdByUser: string;
    created: string;
    lastUpdatedUser: string;
    lastUpdated: string;
    deleted: string;

    vaUserRoles?: Array<IVAUserRole>
    vaUserPermissions?: Array<IVAUserPermission>

    constructor(init?: Partial<IVAUser>) {
        if (init?.id) this.id = init.id;
        this.firstName = init?.firstName ?? '';
        this.normalizedFirstName = init?.firstName.toUpperCase() ?? '';
        this.lastName = init?.lastName ?? '';
        this.normalizedLastName = init?.lastName.toUpperCase() ?? '';
        this.regionId = init?.regionId ?? 1;
        this.username = Array.from(init?.firstName)[0] + init?.lastName ?? '';
        this.email = init?.email ?? '';
        this.normalizedEmail = init?.email.toUpperCase() ?? '';
        this.phoneNumber = init?.phoneNumber ?? '';
        this.vaUserRoles = init?.vaUserRoles ?? [];
        this.vaUserPermissions = init?.vaUserPermissions ?? [];
    }
}