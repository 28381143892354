import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IDocument, Document, ICategoryGroup, IDocumentCategory } from '../../models/document';
import { environment } from "../../../../../environments/environment";
import { IApiDataResponse, IApiResponse } from "../../models/api-response.interface";
import { IDocumentVersion } from '../../models/document-version';
import { ICopyRequest } from '../../models/selection-node';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  private DOCUMENT_API = `${environment.apiBaseURL}File`;

  constructor(private http: HttpClient) {}

  public downloadDocument(documentId: string): Observable<boolean> {
    return this.http.get<IApiDataResponse<string>>(`${this.DOCUMENT_API}/GetFileTokenForFileInfo?fileInfoId=${documentId}`).pipe(
      map(res => {
        window.open(`${this.DOCUMENT_API}/Download/${res.data}`);
        return true;
      }),
    )
  }

  public downloadDocumentVersion(versionId: string): Observable<boolean> {
    return this.http.get<IApiDataResponse<string>>(`${this.DOCUMENT_API}/GetFileToken?fileId=${versionId}`).pipe(
      map(res => {
        window.open(`${this.DOCUMENT_API}/Download/${res.data}`);
        return true;
      })
    )
  }

  public uploadMultipleDocuments(files: FormData, propertyId: string): Observable<HttpEvent<any>> {
    return this.http.post<HttpEvent<any>>(`${this.DOCUMENT_API}/UploadMultipleToProperty/${propertyId}`, files, {observe: 'events', reportProgress: true});
  }

  public uploadDocumentVersion(file: FormData, documentId: string): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.DOCUMENT_API}/UploadToFileInfo/${documentId}`, file);
  }

  public copyDocument(request: ICopyRequest): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.DOCUMENT_API}/Copy`, request);
  }

  public deleteDocument(id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${this.DOCUMENT_API}/FileInfo/${id}`);
  }

  public deleteDocumentVersion(id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${this.DOCUMENT_API}/${id}`);
  }

  public updateDocument(document: Document): Observable<IApiDataResponse<IDocument>> {
    return this.http.post<IApiDataResponse<IDocument>>(`${this.DOCUMENT_API}/SaveFileInfo`, document);
  }

  public getPropertyDocuments(propertyId: string): Observable<IApiDataResponse<ICategoryGroup[]>> {
    return this.http.get<IApiDataResponse<ICategoryGroup[]>>(`${this.DOCUMENT_API}/ByProperty?propertyId=${propertyId}`);
  }

  public getPropertyDocumentsByCategory(propertyId: string, categoryId: string): Observable<IApiDataResponse<Document[]>> {
    return this.http.get<IApiDataResponse<Document[]>>(`${this.DOCUMENT_API}/ByCategory?propertyId=${propertyId}&categoryId=${categoryId}`);
  }

  public getPropertyCategories(propertyId: string = null): Observable<IApiDataResponse<IDocumentCategory[]>> {
    return this.http.get<IApiDataResponse<IDocumentCategory[]>>(`${this.DOCUMENT_API}/Categories/List${propertyId ? '?propertyId='+`${propertyId}`:''}`);
  }

  public getDocumentVersions(documentId: string): Observable<IApiDataResponse<IDocumentVersion[]>> {
    return this.http.get<IApiDataResponse<IDocumentVersion[]>>(`${this.DOCUMENT_API}/ByFileInfo?fileInfoId=${documentId}`);
  }

  public mergeDocuments(from: string, to: string): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.DOCUMENT_API}/Merge?fromId=${from}&toId=${to}`, {});
  }
}
