import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { UploadProgressComponent } from '../../components/upload-progress/upload-progress.component';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private _uploadCompleteSubject: BehaviorSubject<UploadCompleteEvent> = new BehaviorSubject<UploadCompleteEvent>(null);
    private _uploadInProgressSubject: BehaviorSubject<UploadInProgressEvent> = new BehaviorSubject<UploadInProgressEvent>(null);
    private _isProgressModalOpen: boolean;

    public get uploadsCompleted$(): Observable<UploadCompleteEvent> {
        return this._uploadCompleteSubject.asObservable();
    }

    public get uploadsInProgress$(): Observable<UploadInProgressEvent> {
        return this._uploadInProgressSubject.asObservable();
    }

    constructor(private dialogService: DialogService) { }

    public uploadCompleteNotify(event: UploadCompleteEvent): void {
        this._uploadCompleteSubject.next(event);
    }

    public uploadInProgressNotify(event: UploadInProgressEvent): void {
        if (!this._isProgressModalOpen) {
            this.dialogService.open(UploadProgressComponent, {
                width: "400px",
                position: "bottom-right",
                dismissableMask: false,
                styleClass: "upload-progress-modal",
                modal: false
            });
            this._isProgressModalOpen = true;
        }
        this._uploadInProgressSubject.next(event);
    }

    public uploadProgressClosedNotify(): void {
        this._isProgressModalOpen = false;
    }

}


export interface UploadCompleteEvent {
    propertyId: string;
    categoryIds: string[];
}

export interface UploadInProgressEvent {
    fileCount: number;
    progressSubject: BehaviorSubject<number>;
}
