import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { PiccoloScores } from "../models/interfaces/piccolo.interface";


@Injectable({
    providedIn: 'root'
})

export class PiccoloScoresService {

    constructor(
        private http: HttpClient,
    ) { }

    /**
    * Post piccolo scores form data to api
    */
    //   public postPiccoloScoresForm ( PiccoloScoresFormSubmittedData : any ) {
    //     return this.http.post<PiccoloScores>(environment.apiBaseURL + "PiccoloScores/CreatePiccoloScores", PiccoloScoresFormSubmittedData).subscribe();
    // }

    public post(event: PiccoloScores): Observable<IActionResult> {
        return this.postPiccoloScoresForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postPiccoloScoresForm ( PiccoloScoresFormSubmittedData : PiccoloScores ): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "PiccoloScores/CreatePiccoloScores", PiccoloScoresFormSubmittedData);
    }
}