import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subject, Subscriber, filter, map, of, takeUntil, tap } from 'rxjs';
import { DxFileUploaderComponent } from 'devextreme-angular';

import { EventObj, IEventParticipant, IEventParticipantStaffTitle, IVAUser } from 'src/app/modules/vaitsn/models';
import { EventService } from '../../../services/event.service';
import { ExcelReaderService } from '../../../services/excel-reader.service';
import { EventParticipantStaffTitleService } from '../../../services/event-participant-staff-title.service';
import { VAUserService } from '../../../services/va-user.service';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event-participants.component.html',
  styleUrls: ['./event-participants.component.scss']
})
export class EventParticipantsComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;
  public currentEventId: number = 0;
  public isFormLoading: boolean = true;
  public staffTitles: Array<IEventParticipantStaffTitle> = [];
  private _subscribedSubjects$ = new Subject<boolean>();
  public participants: Array<IEventParticipant> = [];
  private _currentVAUser: IVAUser = null;
  public participantRequired = false;
  public invalidParticipantsInList = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public vaUserService: VAUserService,
    private eventService: EventService,
    private excelReaderService: ExcelReaderService,
    private eventParticipantStaffTitleService: EventParticipantStaffTitleService,
  ) { }

  public ngOnInit() {
    this.currentEventId = +this.activatedRoute.snapshot.paramMap.get('eventId');

    this.vaUserService.currentAssociatedVAUser$.pipe(
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => this._currentVAUser = vaUser)
    ).subscribe();

    this.getStaffTitles();
    this.initParticipants();
  }

  public staffTitleDisplay = (st: IEventParticipantStaffTitle): string => st?.name + ' | ' + st?.abbreviation;

  private getStaffTitles() {
    this.eventParticipantStaffTitleService.get().pipe(
      filter(st => st !== null),
      takeUntil(this._subscribedSubjects$),
      map((staffTitles) => {
        this.staffTitles = staffTitles;
      })).subscribe();
  }

  public insertNewRow() {
    const newParticipant = {
      id: 0,
      eventId: this.currentEventId,
      firstName: null,
      lastName: null,
      staffTitleId: null,
      partner: null,
      isVqb5: false,
    } as IEventParticipant;

    this.participants.push(newParticipant);
  }

  public initParticipants() {
    if (this.currentEventId > 0 && !this.eventService.$currentEvent.value.event.regionId) {
      this.eventService.refreshCurrentEvent(this.currentEventId).pipe(
        takeUntil(this._subscribedSubjects$),
        tap((_) => {
          this.getParticipants();
        })).subscribe();
    } else {
      if (this.eventService.$currentEvent.value.event.regionId) {
        this.getParticipants();
      } else {
        this.isFormLoading = false;
      }
    }
  }

  getParticipants() {
    const eventObj = this.eventService.$currentEvent.value;
    this.participants = eventObj.eventParticipants;
    this.isFormLoading = false;
  }

  public onFileChange(e: any) {
    let file = e.value[0];
    this.excelReaderService.readExcel(file).pipe(map((data) => this.loadParticipants(data))).subscribe();
  }

  loadParticipants(data: Array<any>) {
    data.forEach((p) => {
      const participant = {
        id: 0,
        eventId: this.currentEventId,
        firstName: p.FirstName,
        lastName: p.LastName,
        staffTitleId: this.getStaffTitleId(p.StaffTitle),
        isVqb5: p.IsVqb5,
        partner: p.Partner,
      } as IEventParticipant;
      this.participants.push(participant);
    })
  }

  private getStaffTitleId(staffTitle: string): number {
    if(!staffTitle) return 4;
    
    if (staffTitle.toUpperCase() === 'D' || staffTitle.toUpperCase() === 'DIRECTOR') {
      return 1;
    }
    if (staffTitle.toUpperCase() === 'TC' || staffTitle.toUpperCase() === 'T' || staffTitle.toUpperCase() === 'C' 
      || staffTitle.toUpperCase().includes('TEACHER') || staffTitle.toUpperCase().includes('CAREGIVER')) {
      return 2;
    }
    if (staffTitle.toUpperCase() === 'P' || staffTitle.toUpperCase() === 'PARTNER') {
      return 3;
    }
    if (staffTitle.toUpperCase() === 'F' || staffTitle.toUpperCase().includes('PARENT') 
      || staffTitle.toUpperCase().includes('GUARDIAN')) {
      return 5;
    }

    return 4;
  }

  removeParticipant(index: number) {
    this.participants.splice(index, 1);
  }

  removeAll() {
    this.participants = [];
  }

  /** At least one participant required */
  public onSubmit() {

    this.invalidParticipantsInList = !this.validateParticipants();

    if(this.participants.length <= 0) {
        this.participantRequired = true;
        return;
    }
    else if(this.invalidParticipantsInList){
        this.participantRequired = false;
        return;
    }

    this.participantRequired = false;
    let updatedObject = this.mapParticipantsToCurrentEventObj();
    
    this.eventService.$currentEvent.next(updatedObject);
    this.finalizeEvent();

    if(this.currentEventId === 0) {
      this.eventService.post().pipe(tap((_) => {
        this.router.navigate(['app/dashboard']);
      })).subscribe()
    } else {
      this.eventService.update().pipe(tap((_) => {
        this.router.navigate(['app/dashboard']);
      })).subscribe()
    }
  }

  private validateParticipants() : boolean {
    
    let invalidParticipants = this.participants.filter(
        p => !p.firstName || p.firstName.trim() === '' ||
        !p.lastName || p.lastName.trim() === '' || p.staffTitleId === null
    );

    return invalidParticipants.length <= 0;
  }

  /**
   * Remove invalid participant rows. If no valid rows exist,
   * return null (the shared event object will not be updated)
   * otherwise, return the new event object with the updated list
   * of participants (the caller will then save the new event object)
   * Also transform participant.partner from string to null if empty
   */
  private filterValidParticipantsToCurrentEventObj() : EventObj|null  {

    let eventObj = this.eventService.$currentEvent.value;

    this.participants.forEach(p => {
      if (!p.firstName || p.firstName.trim() === '' || !p.lastName || p.lastName.trim() === ''
        || p.staffTitleId === null) {
        p.id = -1;
        return;
      }

      if(p.partner !== null && p.partner.trim() === '') {
        p.partner = null;
      }
    });

    this.participants = this.participants.filter(p => p.id !== -1);

    if(this.participants.length > 0)
    {
        eventObj.eventParticipants = this.participants;
        return eventObj;
    }
    else
    {
        return null;
    }

  }

  private mapParticipantsToCurrentEventObj() : EventObj {
    let eventObj = this.eventService.$currentEvent.value;
    eventObj.eventParticipants = this.participants;
    return eventObj;
  }

  private finalizeEvent() {
    let eventObj = this.eventService.$currentEvent.value;

    if (eventObj.event.childId == 0 || !eventObj.event.childId) eventObj.event.childId = null;

    // eventObj.eventDetail.created = new Date().toISOString();
    // eventObj.eventDetail.createdByUser = this._currentVAUser.username;
    // eventObj.eventDetail.lastUpdated = new Date().toISOString();
    // eventObj.eventDetail.lastUpdatedUser = this._currentVAUser.username;

    this.eventService.$currentEvent.next(eventObj);
  }

  public onBackClicked(): void {
    let updatedObject = this.filterValidParticipantsToCurrentEventObj();
    if (updatedObject != null)
    {
        this.eventService.$currentEvent.next(updatedObject);
    }
    this.location.back(); // Back to either Coaching or Training step
  }

  public ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}
