import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  ILookupAssociatedClassProgramChild,
  IParamObj,
  IProgram,
  IProgramSearchQuery
} from '../../models';
import { LookupAssociatedClassProgramChildService } from '../../services/lookup-associated-class-program-child.service';

@Component({
  selector: 'app-lookup-program',
  templateUrl: './lookup-program.component.html',
  styleUrls: ['./lookup-program.component.scss']
})
export class LookupProgramComponent implements OnInit {
  @Input() currentProgram: IProgram = null;
  @Input() disabled: boolean = false;
  @Output() select$ = new EventEmitter<ILookupAssociatedClassProgramChild>();

  public isNotSearching: boolean = true;
  public associatedPrograms$: Observable<Array<ILookupAssociatedClassProgramChild>>;
  private programSearchText$ = new Subject<string>();
  private programODataFilterBuilder = ODataFilterBuilder();

  public searchQuery: IProgramSearchQuery = {
    code: '',
    name: '',
    address: '',
    created: ''
  };

  constructor(private lacpcService: LookupAssociatedClassProgramChildService) { }

  ngOnInit() {
    this.getPrograms();
  }

  onSelect(associatedPrograms: ILookupAssociatedClassProgramChild) {
    this.currentProgram = associatedPrograms.program;
    this.isNotSearching = true;
    this.select$.emit(associatedPrograms);
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public programSearch(searchString: string) {
    this.buildFilter();
    this.programSearchText$.next(searchString);
  }

  public initSearchFilters() {
    this.isNotSearching = false;
    this.searchQuery = {
      code: '',
      name: '',
      address: '',
      created: ''
    }
  }

  private buildFilter() {
    this.programODataFilterBuilder = ODataFilterBuilder("and")
      .eq('childId', null)
      .contains('Program/code', this.searchQuery.code)
      .contains('Program/name', this.searchQuery.name)
      .contains('Program/address', this.searchQuery.address)
  }

  private getPrograms() {
    this.associatedPrograms$ = this.programSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        const params = { $filter: this.programODataFilterBuilder.toString(), $expand: 'Program($orderby=created desc)', $top: 8 } as IParamObj;
        return this.lacpcService.get(params)
      })
    );
  }
}
