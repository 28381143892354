import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { User } from 'src/app/modules/core/models/user';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { UserApiService } from 'src/app/modules/core/services/user-api/user-api.service';

@Injectable()
export class AddUserService {
  constructor(private userApi: UserApiService, private toast: ToastService) {
  }

  public create(user: User): Observable<boolean> {
    return this.userApi.createUser(user).pipe(
      map((response: IApiResponse) => {
        this.toast.success(response.message);
        return true;
      })
    );
  }

  public cancel(): void {
    // TODO: Implement when Will's styles PR goes through.
  }
}
