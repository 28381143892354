import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { MChatR } from "../models/interfaces/m-chat-r.interface";

@Injectable({
    providedIn: 'root'
})

export class MChatRService {
    
    constructor (
        private http: HttpClient,
    ) { }

    /**
    * Post mchatr form data to api
    */
    // public postMChatRForm ( MChatRFormSubmittedData : any ) {
    //     return this.http.post<MChatR>(environment.apiBaseURL + "MChatR/CreateMChatR", MChatRFormSubmittedData).subscribe();
    // }

    public post(event: MChatR): Observable<IActionResult> {
        return this.postMChatRForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postMChatRForm ( MChatRFormSubmittedData : MChatR ): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "MChatR/CreateMChatR", MChatRFormSubmittedData);
    }
}