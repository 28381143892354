import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";
import { IChildStatus } from "../models/interfaces/child-status.interface";
import { IActionResult } from "../models/interfaces/action-result.interface";

@Injectable({
    providedIn: 'root'
})

export class ChildStatusService {

    constructor(private http: HttpClient, private userService: UserService ){ }

    /**
     * Returns an array of child statuses
     */
    public get(): Observable<Array<IChildStatus>> {
       return this.getChildStatus().pipe(map((result) => result.data as Array<IChildStatus>));
    }

    private getChildStatus(): Observable<IActionResult>{
        return this.http.get<IActionResult>(environment.apiBaseURL + 'ChildStatus/ChildStatus');
    }
}