<div *ngIf="isNotSearching; else isSearching" class="program-not-searching-grid">
    <div class="program-col">
        <vaitsn-button color="primary" (click)="initSearchFilters()" size="square" customWidth="100%" [disabled]="disabled"
            customHeight="45px">
            <mat-icon class="search-icon" aria-hidden="false" aria-label="search" fontIcon="search"></mat-icon>
        </vaitsn-button>
    </div>
    <div>
        <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="text" class="va-input-field" matInput placeholder="Program"
                [value]="(currentProgram == null ? '' : currentProgram?.code + ' | ' + currentProgram?.name)" readonly [disabled]="disabled">
        </mat-form-field>
    </div>
</div>
<ng-template #isSearching>
    <div class="program-search-border-box">
        <div class="program-grid">
            <div>
                <a><mat-icon class="cancel-icon" aria-hidden="false" (click)="isNotSearching = !isNotSearching" aria-label="cancel"
                        fontIcon="cancel"></mat-icon></a>
            </div>
            <div class="program-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.code" placeholder="Program code" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
            <div class="program-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.name" placeholder="Program name" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
            <div class="program-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.address" placeholder="Program address" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
        </div>
        <div>
            <div *ngIf="(searchResults$ | async)?.length <= 0" class="no-search-results-text">
                No results found. Search using the above filter fields...
            </div>
            <a *ngFor="let result of searchResults$ | async"
                (click)="onSelect(result)">
                <div class="program-grid data">
                    <div></div>
                    <div class="program-data-col">
                        {{result.code}}
                    </div>
                    <div class="program-data-col">
                        {{result.name}}
                    </div>
                    <div class="program-data-col">
                        {{result.address}}
                    </div>
                </div>
            </a>
        </div>
    </div>
</ng-template>