import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import {
  IApiDataResponse,
  IApiResponse,
} from 'src/app/modules/core/models/api-response.interface';
import { IUser, User } from 'src/app/modules/core/models/user';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { UserApiService } from 'src/app/modules/core/services/user-api/user-api.service';

@Injectable()
export class EditUserService {
  private _user: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);

  get userSnapshot(): User | null {
    return this._user.value;
  }

  get user$(): Observable<User | null> {
    return this._user.asObservable();
  }

  constructor(private userApi: UserApiService, private toast: ToastService) {}

  public initializeForUser(userId: string) {
    return this.userApi.getUserById(userId).pipe(
      map((response: IApiDataResponse<IUser>) => {
        this._user.next(new User(response.data));
      }),
    );
  }

  public save(user: User): Observable<boolean> {
    return this.userApi.updateUser(user).pipe(
      map((response: IApiResponse) => {
        this.toast.success(response.message);
        return true;
      })
    );
  }

  public cancel(): void {
    // TODO: Implement when Will's styles PR goes through.
  }
}
