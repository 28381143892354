import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App
import { ForbiddenComponent } from './modules/core/pages/error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './modules/core/pages/error-pages/not-found/not-found.component';
import { UnauthorizedComponent } from './modules/core/pages/error-pages/unauthorized/unauthorized.component';
import { SimplePageLayoutComponent } from './modules/core/layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './modules/core/layouts/app-layout/app-layout.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { AdminGuard } from './modules/core/guards/admin.guard';
import { DocumentsByPropertyComponent } from './modules/core/pages/documents-by-property/documents-by-property.component';
import { DocumentsByCategoryComponent } from './modules/core/pages/documents-by-category/documents-by-category.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'app',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/vaitsn/vaitsn.module').then((m) => m.VAITSNModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'documents/:propertyId', 
        component: DocumentsByPropertyComponent
      },
      {
        path: 'documents/:propertyId/:categoryId', 
        component: DocumentsByCategoryComponent
      },
    ],
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}