import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventRoutingModule } from '../pages/event/event-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// App
import { LookupClassroomComponent } from './lookup-classroom/lookup-classroom.component';
import { LookupCoachComponent } from './lookup-coach/lookup-coach.component';
import { LookupProgramComponent } from './lookup-program/lookup-program.component';
import { LookupProgramClassroomComponent } from './lookup-program-classroom/lookup-program-classroom.component';
import { LookupProgramClassroomChildComponent } from './lookup-program-classroom-child/lookup-program-classroom-child.component';
import { SearchProgramODataComponent } from './search-program-odata/search-program-odata.component';
import { SearchProgramClassroomODataComponent } from './search-program-classroom-odata/search-program-classroom-odata.component';
import { VaitsnButtonComponent } from './vaitsn-button/vaitsn-button.component';

@NgModule({
  declarations: [
    LookupClassroomComponent,
    LookupCoachComponent,
    LookupProgramComponent,
    LookupProgramClassroomComponent,
    LookupProgramClassroomChildComponent,
    SearchProgramODataComponent,
    SearchProgramClassroomODataComponent,
    VaitsnButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EventRoutingModule,
    // Material
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule
  ],
  exports: [
    LookupClassroomComponent,
    LookupCoachComponent,
    LookupProgramComponent,
    LookupProgramClassroomComponent,
    LookupProgramClassroomChildComponent,
    SearchProgramODataComponent,
    SearchProgramClassroomODataComponent,
    VaitsnButtonComponent,
  ]
})
export class VAITSNComponentsModule {}
