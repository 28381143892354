<div class="d-flex flex-column justify-content-start align-items-center">
  <div class="card shadow-sm" style="max-width: 435px">
    <div class="card-header">User Invitation</div>
    <div class="card-body">
      <p>Please set a password to finish setting up your account.</p>
      <app-password-confirmation (passwordSubmit)="onSubmit($event)">
      </app-password-confirmation>
    </div>
  </div>
</div>
