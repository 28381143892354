import { Injectable } from '@angular/core';
import ODataContext from 'devextreme/data/odata/context';
import { environment } from 'src/environments/environment';
import { TokenService } from '../../core/services/token/token.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { ITravelVoucherReport } from '../models/interfaces/travel.interface';

@Injectable({
    providedIn: 'root'
})

export class DXODataService {
    private oDataContext: ODataContext;
    constructor(private authService: AuthService, private tokenService: TokenService) {

        this.oDataContext = new ODataContext({
            url: environment.apiBaseURL + 'odata',
            version: 4,
            errorHandler(error) {
                console.error('odata context error', error);
                if (error.httpStatus === 401) {
                    authService.logout();
                }
            },
            beforeSend(options) {
                options.headers = {
                    Authorization: 'Bearer ' + tokenService.token
                };
            },
            entities: {
                Regions: {
                    key: 'id',
                    keyType: 'Int32'
                },
                VAUsers: {
                    key: 'id',
                    keyType: 'Int32'
                },
                Classrooms: {
                    key: 'id',
                    keyType: 'Int32'
                },
                VPrograms: {
                    key: 'id',
                    keyType: 'Int32'
                },
                LookupAssociatedClassProgramChildren: {
                    key: 'id',
                    keyType: 'Int32'
                },
                EventTypes: {
                    key: 'id',
                    keyType: 'Int32'
                },
                Events: {
                    key: 'id',
                    keyType: 'Int32'
                },
                EventDetails: {
                    key: 'id',
                    keyType: 'Int32'
                },
                EventPresenters: {
                    key: 'id',
                    keyType: 'Int32'
                },
                EventParticipants: {
                    key: 'id',
                    keyType: 'Int32'
                },
                ChildASQQuestionnaires: {
                    key: 'id',
                    keyType: 'Int32'
                },
                TravelLocations: {
                    key: 'id',
                    KeyType: 'Int32'
                },
                TravelLocationTypes: {
                    key: 'id',
                    KeyType: 'Int32'
                },
                //VIEWS
                VAUsersReport: {
                    key: 'id',
                    keyType: 'Guid'
                },
                EventsReport: {
                    key: 'id',
                    keyType: 'Int32',
                    deserializeDates: false
                },
                TravelVouchersReport: {
                    key: 'id',
                    keyType: 'Int32',
                    deserializeDates: false,
                }
            }
        });
    }

    get context(): any {
        return this.oDataContext;
    }
}
