import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { 
  Subject, 
  filter, 
  map, 
  takeUntil, 
} from 'rxjs';
//for vaUsers
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
//lookup program and classroom and child
import { ILookupAssociatedClassProgramChild } from '../../models/interfaces/lookup-associated-class-program-child.interface';
//Piccolo  -- used for posting
import { PiccoloScores } from '../../models/interfaces/piccolo.interface';
import { PiccoloScoresService } from '../../services/piccolo-scores.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
  selector: 'app-piccolo-scores',
  templateUrl: './piccolo-scores.component.html',
  styleUrls: ['./piccolo-scores.component.scss']
})

export class PiccoloScoresComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;
  public PiccoloScoresForm: FormGroup = null;
  public PiccoloScoresFormSubmit: PiccoloScores = null;

  constructor(
    public vaUserService: VAUserService,
    private piccoloScoresService: PiccoloScoresService,
    private router: Router,
    private toast: ToastService,
    ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.PiccoloScoresForm.patchValue({ User: vaUser.firstName + ' ' + vaUser.lastName })
        }
      })
    ).subscribe()
  }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.PiccoloScoresForm.patchValue({
      Program: value.programId, 
    });
    this.PiccoloScoresForm.patchValue({
      Classroom: value.classroomId, 
    });
    this.PiccoloScoresForm.patchValue({
      Child: value.childId, 
    });
    this.PiccoloScoresForm.patchValue({
      LookupId: value.id
    })
  }

  public selectedVAUser(user: IVAUser){
    this.currentVAUser = user;
    this.PiccoloScoresForm.patchValue({ User: user.firstName + ' ' + user.lastName })
  }

  private initForm() {
    this.PiccoloScoresForm = new FormGroup(
      {
        User: new FormControl(null, [Validators.required]),
        DateSubmitted: new FormControl({ value: new Date(), disabled: true }, [Validators.required]),
        Classroom: new FormControl('', [Validators.required]),
        Program: new FormControl('', [Validators.required]),
        Child: new FormControl('', [Validators.required]),
        AgeMonth: new FormControl('', [Validators.required]),
        LookupId: new FormControl('', [Validators.required]),
        ObservationDate: new FormControl(new Date().toLocaleDateString(), [Validators.required]),
        AffectionTotal: new FormControl('', [Validators.required]),
        AffectionComment: new FormControl(''),
        ResponsivenessTotal: new FormControl('', [Validators.required]),
        ResponseComment: new FormControl(''),
        EncouragementTotal: new FormControl('', [Validators.required]),
        EncouragementComment: new FormControl(''),
        TeachingTotal: new FormControl('', [Validators.required]),
        TeachingComment: new FormControl(''),
      }
    );
  }

  public isInputValid = (formControlName: string): boolean => (!this.PiccoloScoresForm.get(formControlName)?.valid && (this.PiccoloScoresForm.get(formControlName).dirty || (this.PiccoloScoresForm.get(formControlName).touched)));

  onSubmit() {
    this.PiccoloScoresForm.markAllAsTouched();
    if (!this.PiccoloScoresForm.valid){
      console.warn("error happened: ", this.PiccoloScoresForm.value);
    } else {
      this.finalizePiccoloScoresFormData();
      this.piccoloScoresService.post(this.PiccoloScoresFormSubmit) 
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }
  
  finalizePiccoloScoresFormData(){
    this.PiccoloScoresForm.patchValue({ User: this.currentVAUser.id });
    this.PiccoloScoresFormSubmit = {
      VAUserId: this.PiccoloScoresForm.controls['User'].value,
      Created: this.PiccoloScoresForm.controls['DateSubmitted'].value,
      LookupAssociatedClassProgramChildId: this.PiccoloScoresForm.controls['LookupId'].value,
      AgeMonth: this.PiccoloScoresForm.controls['AgeMonth'].value,

      Observation: this.PiccoloScoresForm.controls['ObservationDate'].value,
      AffectionTotal: this.PiccoloScoresForm.controls['AffectionTotal'].value,
      AffectionComment: this.PiccoloScoresForm.controls['AffectionComment'].value,
      ResponsivenessTotal: this.PiccoloScoresForm.controls['ResponsivenessTotal'].value,
      ResponsivenessComment: this.PiccoloScoresForm.controls['ResponseComment'].value,
      EncouragementTotal: this.PiccoloScoresForm.controls['EncouragementTotal'].value,
      EncouragementComment: this.PiccoloScoresForm.controls['EncouragementComment'].value,
      TeachingTotal: this.PiccoloScoresForm.controls['TeachingTotal'].value,
      TeachingComment: this.PiccoloScoresForm.controls['TeachingComment'].value,
      CreatedByUser: 'null',
      LastUpdatedUser: 'null',
    };
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}