import {
  NgModule
} from '@angular/core';
import {
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxNumberBoxModule,
  DxFormModule,
  DxRadioGroupModule,
  DxButtonModule,
  DxTextBoxModule,
  DxTextAreaModule,
  DxDateBoxModule,
  DxDataGridModule,
  DxLookupModule,
  DxScrollViewModule,
  DxLoadPanelModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxFileUploaderModule,
  DxProgressBarModule
} from 'devextreme-angular';

@NgModule({
  imports: [
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    DxRadioGroupModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxDataGridModule,
    DxLookupModule,
    DxScrollViewModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxFileUploaderModule,
    DxProgressBarModule
  ],
  exports: [
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    DxRadioGroupModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxDataGridModule,
    DxLookupModule,
    DxScrollViewModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxFileUploaderModule,
    DxProgressBarModule
  ]
})
export class DxModule {}
