import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Subject,
  filter,
  map,
  takeUntil,
  tap
} from 'rxjs';

import {
  ClassScoresRequest,
  ILookupAssociatedClassProgramChild,
  IRegion,
  IVAUser,
} from '../../models';
import { RegionService } from '../../services/region.service';
import { ClassScoresService } from '../../services/class-scores.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { VAUserRole, VAUserService } from '../../services/va-user.service';

interface FormOption {
  value: string;
  label: string;
  controls: string[]; // Array of control names to show based on this option
}

@Component({
  selector: 'app-class-scores',
  templateUrl: './class-scores.component.html',
  styleUrls: ['./class-scores.component.scss']
})

export class ClassScoresComponent implements OnInit, OnDestroy {
  public VAUserRole = VAUserRole;
  private _subscribedSubjects$ = new Subject<boolean>();
  private currentVAUser: IVAUser = null;
  public form: FormGroup = null;
  public formDataAsRequest: ClassScoresRequest = null;
  public regions: Array<IRegion> = [];
  public filteredRegions: Array<IRegion> = [];
  public PrePosts: Array<any> = [];
  public toolTypeOptions: FormOption[] = [
    { 
      value: 'Infant',
      label: 'Infant CLASS',
      controls: [
        'Relational Climate',
        'Teacher Sensitivity',
        'Facilitated Exploration',
        'Early Language Support',
      ] 
    },
    { 
      value: 'Toddler',
      label: 'Toddler CLASS',
      controls: [
        'Teacher Sensitivity',
        'Positive Climate',
        'Negative Climate',
        'Regard For Child Perspective',
        'Behavior Guidance',
        'Facilitated Learning Development',
        'Quality Feedback',
        'Language Modeling',
      ]
    },
  ];

  constructor(
    public vaUserService: VAUserService,
    private regionService: RegionService,
    private classScoresService: ClassScoresService,
    private router: Router,
    private toast: ToastService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
    this.getRegions();
    this.getPrePosts();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.form.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
          this.form.patchValue({ ObserverFirstName: this.currentVAUser.firstName });
          this.form.patchValue({ ObserverLastName: this.currentVAUser.lastName });
        }
      })
    ).subscribe()
  }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.form.patchValue({
      Program: value.programId,
    });
    this.form.patchValue({
      ClassroomId: value.classroomId,
    });
  }

  public selectedVAUser(value: IVAUser) {
    this.currentVAUser = value;
    this.form.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
    this.form.patchValue({ ObserverFirstName: this.currentVAUser.firstName });
    this.form.patchValue({ ObserverLastName: this.currentVAUser.lastName });
  }

  private getRegions() {
    this.regionService.get().pipe(
      takeUntil(this._subscribedSubjects$),
      map((regions) => {
        this.regions = regions;
        this.filteredRegions = regions;
      })).subscribe();
  }

  public filterRegions(searchString: string) {
    this.filteredRegions = this.regions.filter(region => region.name.includes(searchString) || region.code.includes(searchString))
  }

  public regionDisplay = (region: IRegion): string => region.name;

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  private getPrePosts() {
    this.PrePosts = [
      { PrePostID: 1, PrePostCD: 'Pre', boolValue: false },
      { PrePostID: 2, PrePostCD: 'Post', boolValue: true }
    ]
  }

  private initForm() {
    this.form = new FormGroup({
      User: new FormControl(null, [Validators.required]),
      DateSubmitted: new FormControl({ value: new Date(), disabled: true }),
      ObservationDate: new FormControl(new Date(), [Validators.required]),
      ObserverFirstName: new FormControl('', [Validators.required]),
      ObserverLastName: new FormControl('', [Validators.required]),
      ClassroomId: new FormControl('', [Validators.required]),
      ClassNotes: new FormControl(null),
      PrePost: new FormControl('', [Validators.required]),
      toolType: new FormControl(null, [Validators.required]),
      formArray: this.fb.array([])
    });

    this.form.get('toolType').valueChanges.subscribe(value => {
      this.updateFormArray(value);
    });
  }

  get formArray() {
    return this.form.get('formArray') as FormArray;
  }

  get selectedToolTypeValue() {
    return this.form.get('toolType').value;
  }

  get toolTypeControls() {
    const option = this.toolTypeOptions.find(o => o.value === this.selectedToolTypeValue);
    return option ? option.controls : [];
  }

  /**
   * Creates a new FormGroup with required controls based on the selected option
   * and adds it to the FormArray
   */
  addFormControls() {
    const option = this.toolTypeOptions.find(o => o.value === this.selectedToolTypeValue);
    if (option) {
      const group = this.fb.group({});
      option.controls.forEach(control => {
        group.addControl(
          control,
          new FormControl(null, [Validators.required, Validators.min(1), Validators.max(7)]));
      });
      this.formArray.push(group);
    }
  }

  /**
   * Removes a form group from the FormArray
   */
  removeFormControls() {
    const controlsToRemove = this.formArray.controls;

    controlsToRemove.forEach((group, index) => {
      this.formArray.removeAt(index);
    });
  }

  /**
   * Remove previous selected tool type's controls from the form
   * Then add new selected tool type's controls to the form
   * @param value 
   */
  updateFormArray(value:string){
    const option = this.toolTypeOptions.find(o => o.value === value);
    if (option) {
      this.removeFormControls();
      this.addFormControls();
    }
  }

  /**
   * pattern: /^(?=(\D*\d\D*){0,15}$)-?([0-9]+)?(\.?[0-9]{1,1})?$/
   * ^ : beginning of string
   * (?=(\D*\d\D*){0,15}$): Positive Lookahead 15 chars max
   * \. : here goes a dot
   * \d{1,x} : there should be between one and x digits here
   * $ : end of the string you're testing
   * @param number The number we are testing against
   * @param precision The precision the decimal number should use
   */
  public isInvalidDecimalFormat(formControlName: string, precision: number, array: boolean = false, index: number = null): boolean {
    let control = null;

    if(array === true && index !== null){
      const formGroup = this.formArray.at(index) as FormGroup;
      control = formGroup.get(formControlName) as FormControl;
    } else { 
      control = this.form.get(formControlName);
    }

    let decimal = control?.value;
    if (decimal == null || decimal <= 1 || decimal > 7) return false; // skip evaluation (falls under required and min/max checks)

    const validFormat = new RegExp(`^(?=(\\D*\\d\\D*){0,15}$)-?([0-9]+)?(\\.?[0-9]{1,${precision}})?$`)
    return !validFormat.test(decimal.toString());
  }

  public isInputInvalid (formControlName: string, array: boolean = false, index: number = null): boolean {
    let control = null;

    if(array === true && index !== null){
      const formGroup = this.formArray.at(index) as FormGroup;
      control = formGroup.get(formControlName) as FormControl;
    } else { 
      control = this.form.get(formControlName);
    }

    return !control?.valid
      && (control.dirty
        || (control.touched))
  }

  /**
   * @todo recheck all decimal fields are valid
   * @returns true if all existing controls in form array have valid decimal formats
   */
  decimalControlsValid(){
    return true;
    // const controlsToRemove = this.formArray.controls;

    // controlsToRemove.forEach((group, index) => {
    //   if(this.isInvalidDecimalFormat())
    // });
  }

  /**
   * On click form Submit button:
   * 1. check if valid
   * 2. if not valid, cancel submit
   * 3. if valid, build request from form data
   * 4. post form data request
   * 5. on success, display success message
   */
  public onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid || !this.decimalControlsValid()) {
      console.warn("error happened: ", this.form.value);
    } else {
      this.buildRequestFromFormData();
      this.classScoresService.post(this.formDataAsRequest)
        .subscribe({
          next: (n) => {
            this.toast.success("Form submitted successfully.")
            this.router.navigate(['app/dashboard']);
          }
        });
    }
  }

  buildRequestFromFormData() {
    this.form.patchValue({ User: this.currentVAUser.id });

    let dynamicControls = this.form.value.formArray[0];

    var formDataFlattened = {
      VAUserId: this.form.controls['User'].value,
      ClassroomId: this.form.controls['ClassroomId'].value,
      Observation: this.form.controls['ObservationDate'].value,
      ObserverName: this.form.controls['ObserverFirstName'].value + ' ' + this.form.controls['ObserverLastName'].value,
      Notes: this.form.controls['ClassNotes'].value,
      IsPost: this.form.controls['PrePost'].value.boolValue,
    };

    for (const key of Object.keys(dynamicControls)) {
      const noWhitespaceStr = key.replace(/\s+/g, '');
      formDataFlattened[noWhitespaceStr as keyof typeof formDataFlattened] = dynamicControls[key];
    }
  
    this.formDataAsRequest = formDataFlattened;
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
