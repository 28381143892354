import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

// DevExtreme
import { DxModule } from 'src/app/modules/dx.module';

// App
import { EventRoutingModule } from './event-routing.module';
//import { EventReportPreviewComponent } from './event-report-preview/event-report-preview.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventCoachingComponent } from './event-coaching/event-coaching.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { EventTrainingComponent } from './event-training/event-training.component';
import { VAITSNComponentsModule } from '../../components/vaitsn-components.module';

@NgModule({
    declarations: [
        //EventReportPreviewComponent,
        EventDetailsComponent,
        EventCoachingComponent,
        EventParticipantsComponent,
        EventTrainingComponent,
    ],
    imports: [
        EventRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        VAITSNComponentsModule,
        // Material Modules
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatInputModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatIconModule,
        // DevExtreme
        DxModule,
    ],
    exports: [
        CommonModule,
    ]
})
export class EventModule { }
