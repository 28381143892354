<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div *ngIf="!isFormLoading" class="form-container">
    <h1 class="form-h1">Event Details</h1>

    <form [formGroup]="eventDetailForm" (ngSubmit)="onSubmit()">
        <div class="grid-lg-2cols-50-50 grid-sm-1cols">
            <ng-container id="coach" *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach*</mat-label>
                    <div class="vaitsn-form-control">
                        <app-lookup-coach [currentvaUser]="mainPresenter"
                            (selectVAUser$)="selectedMainPresenter($event)"></app-lookup-coach>
                    </div>
                    <div *ngIf="eventDetailForm.touched && !this.mainPresenter" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Coach is Required.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div id="region" class="vaitsn-form-grid-cell">
                <mat-label>Region*</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="text" matInput formControlName="Region"
                            (keyup)="filterRegions(getEventValue($event))" [matAutocomplete]="regionAuto">
                        <mat-autocomplete #regionAuto="matAutocomplete" [displayWith]="regionDisplay">
                            <mat-option *ngFor="let region of filteredRegions" [value]="region"
                                (click)="onRegionChanged($event)">
                                {{region.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('Region')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Region is Required.
                    </div>
                </div>
            </div>

            <div id="eventDate" class="vaitsn-form-grid-cell">
                <mat-label>Date*</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="EventDate" formControlName="eventDate" [min]="minDate"
                            [max]="maxDate" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle class="vaitsn-date-button" matIconSuffix [for]="EventDate"><mat-icon
                                class="vaitsn-date-icon" matDatepickerToggleIcon><svg xmlns="http://www.w3.org/2000/svg"
                                    width="11px" height="12px" fill="none">
                                    <path
                                        d="M9.9 1.09091H9.35V0H8.25V1.09091H2.75V0H1.65V1.09091H1.1C0.495 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.495 12 1.1 12H9.9C10.505 12 11 11.5091 11 10.9091V2.18182C11 1.58182 10.505 1.09091 9.9 1.09091ZM9.9 10.9091H1.1V3.81818H9.9V10.9091Z"
                                        fill="#195DC3" />
                                </svg></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #EventDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('eventDate')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Date is Required.
                    </div>
                </div>
            </div>

            <div id="length" class="vaitsn-form-grid-cell">
                <mat-label>Hours*</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]*$/" step=".5" min="0" matInput placeholder="0"
                            formControlName="Length">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('Length')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Hours is Required.
                    </div>
                </div>
            </div>

            <div id="eventType" class="vaitsn-form-grid-cell">
                <mat-label>Event Type*</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="text" matInput formControlName="EventType"
                            (keyup)="filterEventTypesBySearch(getEventValue($event))" [matAutocomplete]="eventTypeAuto"
                            (change)="onEventTypeChanged($event)">
                        <mat-autocomplete #eventTypeAuto="matAutocomplete" [displayWith]="eventTypeDisplay">
                            <mat-option *ngFor="let eventType of filteredEventTypes" [value]="eventType"
                                (click)="onEventTypeChanged($event)">
                                {{ eventType.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('EventType')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Event Type is Required.
                    </div>
                </div>
            </div>

            <div *ngIf="showChildField" id="child" class="vaitsn-form-grid-cell">
                <mat-label>Child</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="text" matInput formControlName="Child" (keyup)="childSearch(getEventValue($event))"
                            [matAutocomplete]="childrenAuto">
                        <mat-autocomplete #childrenAuto="matAutocomplete" [displayWith]="childDisplay">
                            <mat-option *ngFor="let child of children$ | async" [value]="child">
                                {{child.code}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('Child')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child is Required for this Event Type.
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-lg-4cols-25 grid-sm-1cols">
            <div id="isLocationProgram" *ngIf="showLocationTypeField" class="vaitsn-form-grid-cell">
                <div class="vaitsn-form-control">
                    <mat-label>Location Type*</mat-label> <br>
                    <mat-radio-group formControlName="isLocationProgram" class="vaitsn-radio-list">
                        <mat-radio-button class="vaitsn-radio-button" [value]="true"
                            (change)="isLocationProgramChanged($event)">
                            Program
                        </mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]="false"
                            (change)="isLocationProgramChanged($event)">
                            Other
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputInvalid('isLocationProgram')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Location Type is Required.
                    </div>
                </div>
            </div>
            <div id="programType" *ngIf="showIsProgramVqb5Field" class="vaitsn-form-grid-cell">
                <div class="vaitsn-form-control">
                    <mat-label>Program Type*</mat-label> <br>
                    <mat-radio-group formControlName="isProgramVqb5" class="vaitsn-radio-list">
                        <mat-radio-button class="vaitsn-radio-button" [value]="true"
                            (change)="isProgramVqb5Changed($event)">
                            VQB5
                        </mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]="false"
                            (change)="isProgramVqb5Changed($event)">
                            Non-VQB5
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputInvalid('isLocationProgram')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program Type is Required.
                    </div>
                </div>
            </div>
        </div>

        <div id="programClassroom" *ngIf="eventDetailForm.value.isLocationProgram && eventDetailForm.value.isProgramVqb5 === true" 
            class="example-full-width">
            <div class="vaitsn-form-grid-cell">
                <mat-label>Program / Classroom*</mat-label>
                <div class="vaitsn-form-control">
                    <app-search-program-classroom-odata [regionFilter]="regionFilter" [searchAll]="searchAllProgClass"
                        [currentProgram]="currentProgram" [currentClassroom]="currentClassroom" [vqb5]="eventDetailForm.value.isProgramVqb5"
                        (select$)="selectedProgramClassroom($event)">
                    </app-search-program-classroom-odata>
                </div>
                <div *ngIf="eventDetailForm.errors?.['invalidCoaching']" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program and Classroom are Required for the selected Event Type. Click the magnifying glass to search.
                    </div>
                </div>
                <div *ngIf="eventDetailForm.errors?.['invalidTrng']" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program and Classroom are Required for the selected Event Type + Location Type. Click the magnifying glass to search.
                    </div>
                </div>
                <div *ngIf="eventDetailForm.errors?.['invalidTA']" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program and Classroom are Required for the selected Event Type + Program Type. Click the magnifying glass to search.
                    </div>
                </div>
            </div>
        </div>

        <div id="nonVqb5Program" *ngIf="eventDetailForm.value.isLocationProgram && eventDetailForm.value.isProgramVqb5 === false" 
            class="example-full-width">
            <div class="vaitsn-form-grid-cell">
                <mat-label>Non-VQB5 Program*</mat-label>
                <div class="vaitsn-form-control">
                    <app-search-program-odata 
                        [currentProgram]="currentProgram" [regionFilter]="regionFilter" [nonvqb5Only]="true" [searchAll]="searchAllProgClass"
                        (select$)="selectedProgram($event)">
                    </app-search-program-odata>
                </div>
                <div *ngIf="eventDetailForm.errors?.['invalidTA'] && eventDetailForm.value.Program === null " class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program is Required for the selected Event Type + Program Type. Click the magnifying glass to search.
                    </div>
                </div>
            </div>
        </div>
        <div id="otherClassroom" *ngIf="eventDetailForm.value.isLocationProgram && eventDetailForm.value.isProgramVqb5 === false" 
            class="example-full-width">
            <div class="vaitsn-form-grid-cell">
                <mat-label>Other Classroom*</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input matInput formControlName="otherClassroom">
                    </mat-form-field>
                </div>
                <div *ngIf="eventDetailForm.errors?.['invalidTA'] && (eventDetailForm.value.otherClassroom === null || eventDetailForm.value.otherClassroom === '')" 
                    class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Other Classroom is Required for the selected Event Type + Program Type.
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-lg-2cols-50-50 grid-sm-1cols">
            <div id="isRemote" class="vaitsn-form-grid-cell">
                <div class="vaitsn-form-control">
                    <mat-label>Attendance Type*</mat-label> <br>
                    <mat-radio-group formControlName="IsRemote" class="vaitsn-radio-list">
                        <mat-radio-button class="vaitsn-radio-button" [value]="false">In Person</mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]="true">Virtual</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputInvalid('IsRemote')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Attendance Type is Required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-submit-container" [ngClass]="{'disabled': eventDetailForm.invalid }">
            <vaitsn-button color="primary" mat-flat-button type="submit" (submitted)="onSubmit()"
                [customWidth]="'100%'">
                Next
            </vaitsn-button>
        </div>
    </form>
</div>