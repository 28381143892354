import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import {
    ChildReferralRequest,
    IActionResult,
    IChild,
} from "../models";

@Injectable({
    providedIn: 'root'
})

export class ChildReferralService {

    constructor(private http: HttpClient) { }

    /**
     * Post child referral form data to api 
     */
    public post(event: ChildReferralRequest): Observable<IChild> {
        return this.postChildReferral(event).pipe(map((result) => result.data as IChild));
    }

    public postChildReferral(request: ChildReferralRequest): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "ChildReferral/CreateChildReferral", request);
    }
}





