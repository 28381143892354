import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { 
  Subject, 
  filter, 
  map, 
  takeUntil, 
} from 'rxjs';
//for vaUsers
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
//lookup program and classroom and child
import { ILookupAssociatedClassProgramChild } from '../../models/interfaces/lookup-associated-class-program-child.interface';
//MCHATR  -- used for posting
import { MChatR } from '../../models/interfaces/m-chat-r.interface';
import { MChatRService } from '../../services/mchatr.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
  selector: 'app-m-chat-rscores',
  templateUrl: './m-chat-rscores.component.html',
  styleUrls: ['./m-chat-rscores.component.scss']
})

export class MChatRScoresComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;
  public MChatRForm: FormGroup = null;
  public MChatRFormSubmit: MChatR = null;

  Referral: boolean = false;

  constructor(
    public vaUserService: VAUserService,
    private mchatrService: MChatRService,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.MChatRForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
        }
      })
    ).subscribe()
  }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.MChatRForm.patchValue({
      Program: value.programId, 
    });
    this.MChatRForm.patchValue({
      Classroom: value.classroomId, 
    });
    this.MChatRForm.patchValue({
      Child: value.childId, 
    });
    this.MChatRForm.patchValue({
      LookupId: value.id
    })
  }

  public selectedVAUser( value: IVAUser){
    this.currentVAUser = value;
    this.MChatRForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
  }

  private initForm(){
    this.MChatRForm = new FormGroup(
      {
        User: new FormControl(null, [Validators.required]),
        DateSubmitted: new FormControl({ value: new Date(), disabled: true }),
        AgeMonth: new FormControl('', [Validators.required]),
        LookupId: new FormControl('', [Validators.required]),
        ObservationDate: new FormControl(new Date().toLocaleDateString(), [Validators.required]),
        LowRiskScore: new FormControl('', [Validators.required]),
        MediumRiskScore: new FormControl('', [Validators.required]),
        HighRiskScore: new FormControl('', [Validators.required]),
        Referral: new FormControl(null, [Validators.required]),
        ReferralAgency: new FormControl(''),
      }
    );
  }

  public isInputValid = (formControlName: string): boolean => (!this.MChatRForm.get(formControlName)?.valid && (this.MChatRForm.get(formControlName).dirty || (this.MChatRForm.get(formControlName).touched)));

  public onSubmit(){
    this.MChatRForm.markAllAsTouched();
    if (!this.MChatRForm.valid){
      console.warn("error happened: ", this.MChatRForm.value);
    } else {
      this.finalizeMChatRFormData();
      this.mchatrService.post(this.MChatRFormSubmit)
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }

  finalizeMChatRFormData(){
    this.MChatRForm.patchValue({ User: this.currentVAUser.id });
    this.MChatRFormSubmit = {
      VAUserId: this.MChatRForm.controls['User'].value,
      Created: this.MChatRForm.controls['DateSubmitted'].value,
      AgeMonth: this.MChatRForm.controls['AgeMonth'].value,
      LookupAssociatedClassProgramChildId: this.MChatRForm.controls['LookupId'].value,
      Observation: this.MChatRForm.controls['ObservationDate'].value,
      LowRiskScore: this.MChatRForm.controls['LowRiskScore'].value,
      MediumRiskScore: this.MChatRForm.controls['MediumRiskScore'].value,
      HighRiskScore: this.MChatRForm.controls['HighRiskScore'].value,
      //Referral: this.MChatRForm.controls['Referral'].value,
      ReferralAgency: this.MChatRForm.controls['ReferralAgency'].value,
      CreatedByUser: 'a',
      LastUpdatedUser: 'b',
    };
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}
