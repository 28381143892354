import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";

import { ILanguage } from "../models/interfaces/language.interface";
import { IActionResult } from "../models/interfaces/action-result.interface";

@Injectable({
    providedIn: 'root'
})

export class LanguageService {

    constructor(private http: HttpClient, private userService: UserService ){ }

     /**
     * Returns an array of languages
     */
     public get(): Observable<Array<ILanguage>> {
        return this.getLanguages().pipe(map((result) => result.data as Array<ILanguage>));
     }
 
     private getLanguages(): Observable<IActionResult>{
         return this.http.get<IActionResult>(environment.apiBaseURL + 'Language/Language');
     }
}