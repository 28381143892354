<div *ngIf="isDataLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div *ngIf="!isDataLoading">
    <div>
        <div class="region-dropdown">
            <div *ngIf="(vaUserService.hasRole(VAUserRole.Supervisor) | async) || (vaUserService.hasRole(VAUserRole.Admin) | async)"
                class="region-dropdown">
                <mat-form-field appearance="outline" class="vaitsn-text-field">
                    <input type="text" matInput [(ngModel)]="selectedRegion"
                        (keyup)="filterRegions(getEventValue($event))" placeholder="Region Filter"
                        [matAutocomplete]="regionAuto">
                    <mat-autocomplete #regionAuto="matAutocomplete" [displayWith]="regionDisplay"
                        (optionSelected)="onRegionChange($event)">
                        <mat-option *ngFor="let region of filteredRegions" [value]="region">
                            {{region.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div>
                <div class="main-container">

                    <div class="travel-voucher-tbl-header-grid">
                        <div>
                            <h1>Travel Vouchers</h1>
                        </div>
                        <div class="new-button-container">
                            <vaitsn-button
                                *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async) || (vaUserService.hasRole(VAUserRole.Coach) | async)"
                                size="custom" customWidth="175px" customHeight="30px" (click)="newTravelVoucher()">
                                New Travel Voucher
                            </vaitsn-button>
                        </div>
                    </div>

                    <dx-data-grid #travelVoucherDxGrid id="travelVoucherDxGrid"
                        [dataSource]="travelVoucherReportDataSource" keyExpr="Id" [allowColumnReordering]="false"
                        [allowColumnResizing]="true" [columnAutoWidth]="true" [showBorders]="true"
                        [rowAlternationEnabled]="true" [wordWrapEnabled]="true">

                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

                        <dxo-paging [pageSize]="100"></dxo-paging>

                        <dxo-group-panel [visible]="false" [allowColumnDragging]="false"></dxo-group-panel>

                        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

                        <dxi-column
                            *ngIf="(vaUserService.hasRole(VAUserRole.Supervisor) | async) || (vaUserService.hasRole(VAUserRole.Admin) | async)"
                            dataField="username" caption="Username" 
                            [groupIndex]="0" [sortIndex]="0" sortOrder="asc"></dxi-column>

                        <dxi-column dataField="traveled" dataType="date" caption="Date" 
                            [groupIndex]="1" groupCellTemplate="traveledGRow" [calculateGroupValue]="calculateTraveledGroup"
                            [sortIndex]="1" sortOrder="desc"></dxi-column>
                        <div *dxTemplate="let row of 'traveledGRow'">
                            {{row.data.key | date: 'shortDate'}}
                        </div>

                        <dxi-column dataField="id" dataType="number" caption="Id"
                            [groupIndex]="2" groupCellTemplate="idGRow" [sortIndex]="3" sortOrder="asc"></dxi-column>
                        <div *dxTemplate="let row of 'idGRow'">
                            <div class="travel-voucher-id-group-row-grid">
                                <div>
                                    {{row.data.key}}
                                </div>
                                <div class="delete-tv-col">
                                    <a
                                        *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async) || (vaUserService.hasRole(VAUserRole.Coach) | async)">
                                        <mat-icon (click)="editRecord(row.data)">edit</mat-icon>
                                    </a>
                                    <a *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async)"><mat-icon
                                            (click)="deleteRecord(row.data)">delete</mat-icon></a>
                                </div>
                            </div>
                        </div>

                        <dxi-column dataField="travelRecordTraveledDateTime" dataType="datetime" format="shortTime"
                            [sortIndex]="2" sortOrder="asc" caption="Time"></dxi-column>

                        <dxi-column dataField="eventId" caption="Event" cellTemplate="event-row"></dxi-column>
                        <div *dxTemplate="let row of 'event-row'">
                            <div>
                                <div *ngIf="row.data.eventType; then eventTypeString else eventEmptyString"></div>
                                <ng-template #eventTypeString><b>{{row.data.eventType}}</b></ng-template>
                                <ng-template #eventEmptyString><b>Travel Only</b></ng-template>
                            </div>
                            <div>
                                {{row.data.eventId}}
                            </div>
                        </div>

                        <dxi-column dataField="from" caption="From" cellTemplate="from-row"></dxi-column>
                        <div *dxTemplate="let row of 'from-row'">
                            <div>
                                <div *ngIf="row.data.fromProgram; else elseFrom">
                                    <b>{{row.data.fromProgram}}</b>
                                </div>
                                <ng-template #elseFrom>
                                    <b>{{row.data.fromTravelLocationType}}</b>
                                </ng-template>
                            </div>
                            <div>
                                {{row.data.from}}
                            </div>
                        </div>

                        <dxi-column dataField="to" caption="To" cellTemplate="to-row"></dxi-column>
                        <div *dxTemplate="let row of 'to-row'">
                            <div>
                                <div *ngIf="row.data.toProgram; else elseTo">
                                    <b>{{row.data.toProgram}}</b>
                                </div>
                                <ng-template #elseTo>
                                    <b>{{row.data.toTravelLocationType}}</b>
                                </ng-template>
                            </div>
                            <div>
                                {{row.data.to}}
                            </div>
                        </div>

                    </dx-data-grid>

                </div>
            </div>
        </div>
    </div>