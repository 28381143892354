<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div *ngIf="!isFormLoading" class="form-container">

    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">CLASS Environment Score</h1>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container id="coach" *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Specialist]" (selectVAUser$)="setSelectedCoach($event)"></app-lookup-coach>
                    <div *ngIf="isInputValid('Coach')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Coach is required.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div id="dateSubmitted" class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div id="observed" class="vaitsn-form-grid-cell">
                <mat-label>Observation Date</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="Observed" formControlName="Observed" [min]="observedMinDate"
                            [max]="observedMaxDate" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="Observed"></mat-datepicker-toggle>
                        <mat-datepicker #Observed></mat-datepicker>
                    </mat-form-field>
                </div>

                <div *ngIf="isInputValid('Observed')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Observation Date is required.
                    </div>
                </div>
            </div>

        </div>

        <div class="vaitsn-form-grid">

            <div id="classroomId" class="vaitsn-form-grid-cell">
                <mat-label>Classroom</mat-label>
                <div class="vaitsn-form-control">
                    <app-lookup-program-classroom [classRegionFilter]="classRegionFilter"
                        (select$)="selectedProgramClass($event)"></app-lookup-program-classroom>
                </div>
                <div *ngIf="isInputValid('ClassroomId')"  class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Classroom is required. Click the magnifying glass to search.
                    </div>
                </div>
            </div>

        </div>

        <div class="vaitsn-form-grid-3col vaitsn-form-grid-3col-custom">

            <div id="preOrPost" class="vaitsn-form-grid-cell">
                <mat-label>Pre or Post</mat-label>
                <div class="vaitsn-form-control">
                    <mat-radio-group formControlName="PreOrPost" class="vaitsn-radio-list">
                        <mat-radio-button class="vaitsn-radio-button" [value]="false">Pre</mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]="true">Post</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputValid('PreOrPost')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Pre or Post is required.
                    </div>
                </div>
            </div>

            <div id="score" class="vaitsn-form-grid-cell">
                <mat-label>Score</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" matInput formControlName="Score" placeholder="Score (0.00)">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Score')" class="vaitsn-invalid-container-small">
                    <div *ngIf="form.controls['Score'].errors?.['required']" class="alert alert-danger">
                        Score is required.
                    </div>
                    <div *ngIf="form.controls['Score'].errors?.['min'] || form.controls['Score'].errors?.['max']" class="alert alert-danger">
                        Score must be between [0.01 - 3.00].
                    </div>
                </div>
                <div *ngIf="isInvalidDecimalFormat('Score', 2)" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Enter valid Score amount (0-2 digits after the decimal point).
                    </div>
                </div>
            </div>

        </div>

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px" mat-flat-button
                class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>

    </form>
</div> 