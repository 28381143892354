<nav class="vaitsn-mobile-navbar">
  <button class="vaitsn-canvas-toggle" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
    data-bs-keyboard="true" aria-controls="offcanvasNavbar">
    <mat-icon class="vaitsn-toggle-icon" color="primary">
      menu
    </mat-icon>
  </button>
  <img class="vaitsn-mobile-logo" src="assets/VAITSN_Logo_Transparent.png" />

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" data-bs-scroll="false"
    aria-labelledby="offcanvasNavbarLabel">
    <div class="vaitsn-canvas-header">
      <button class="vaitsn-canvas-toggle" data-bs-dismiss="offcanvas" aria-label="Close">
        <mat-icon class="vaitsn-toggle-icon" color="warn">
          close
        </mat-icon>
      </button>
      <span class="vaitsn-username">
        {{this.userName}}
      </span>
    </div>

    <div class="vaitsn-canvas-body" scroll="yes">
      <ul class="vaitsn-flex-box vaitsn-nav-list">
        <li *ngFor="let item of filteredMenuItems" class="vaitsn-nav-list-item">
          <ng-container *ngIf="!item.adminOnly || (vaUserService.hasRole(VAUserRole.Admin) | async)">
            <a [routerLink]="item.url" class="vaitsn-nav-link" data-bs-dismiss="offcanvas">
              <mat-icon class="vaitsn-nav-list-icon" color="primary">
                {{item.icon}}
              </mat-icon>
              <div class="vaitsn-nav-list-text">
                {{item.name}}
              </div>
            </a>
          </ng-container>
        </li>
      </ul>
      <ul class="vaitsn-flex-box">
        <div class="vaitsn-accordion">
          <div class="vaitsn-accordion-header">
            <button class="vaitsn-nav-link vaitsn-profile-button" data-bs-toggle="collapse"
              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <mat-icon class="vaitsn-nav-list-icon" color="primary">account_circle</mat-icon>
              <div class="vaitsn-nav-list-text">
                Account
              </div>
            </button>
          </div>
          <div id="collapseOne" class="vaitsn-accordion-collapse collapse">
            <div class="accordion-body">
              <hr class="vaitsn-dropdown-divider" />
              <li class="vaitsn-nav-list-item">
                <a class="vaitsn-nav-link" routerLink="/app/user/profile" data-bs-dismiss="offcanvas">
                  <div class="vaitsn-nav-list-text">
                    View Profile
                  </div>
                </a>
              </li>
              <li class="vaitsn-nav-list-item" *ngIf="!externalLogin">
                <a class="vaitsn-nav-link" routerLink="/app/user/change-password" data-bs-dismiss="offcanvas">
                  <div class="vaitsn-nav-list-text">
                    Change Password
                  </div>
                </a>
              </li>
              <li class="vaitsn-nav-list-item">
                <a class="vaitsn-nav-link" data-bs-dismiss="offcanvas">
                  <div class="vaitsn-nav-list-text">
                    v{{VERSION}}
                  </div>
                </a>
              </li>
              <hr class="vaitsn-dropdown-divider" />
              <li class=" vaitsn-nav-list-item">
                <a class="vaitsn-logout" (click)="logout()">
                  <div class="vaitsn-logout-text">
                    Sign Out
                  </div>
                </a>
              </li>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</nav>

<div class="vaitsn-desktop-navbar">
  <div class="vaitsn-sidebar" [ngClass]="collapsedMenu?'vaitsn-collapsed-menu':'vaitsn-expanded-menu'">
    <ul *ngIf="!loadingPermissions" class="vaitsn-flex-box">
      <img *ngIf="!collapsedMenu" class="vaitsn-logo" src="assets/VAITSN_Logo_Transparent.png" routerLink="" />
      <li *ngFor="let item of filteredMenuItems" [ngClass]="collapsedMenu?'vaitsn-nav-list-item':''">
        <ng-container *ngIf="!item.adminOnly || (vaUserService.hasRole(VAUserRole.Admin) | async)">
          <a [routerLink]="item.url" *ngIf="!collapsedMenu" class="vaitsn-nav-link">
            <mat-icon class="vaitsn-nav-list-icon" color="primary">
              {{item.icon}}
            </mat-icon>
            <div class="vaitsn-nav-list-text">
              {{item.name}}
            </div>
          </a>
        </ng-container>
      </li>
    </ul>
    <ul class="vaitsn-bottom-block">
      <li class="vaitsn-nav-list-item">
        <div class="dropdown">
          <a class="vaitsn-nav-link" type="button" id="navbarDropdownMenuLink" data-bs-toggle="dropdown"
            aria-expanded="false">
            <mat-icon class="vaitsn-nav-list-icon" color="primary">
              account_circle
            </mat-icon>
            <div class="vaitsn-nav-list-text">
              {{"Account"}}
            </div>
          </a>
          <a *ngIf="collapsedMenu" class="vaitsn-nav-link" type="button" id="navbarDropdownMenuLink"
            data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon color="primary" style="transform: scale(1.5); margin-left: 155px;">
              account_circle
            </mat-icon>
          </a>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
      <li>
        <div class="dropdown-item">
          {{ (user$ | async)?.userName }}
        </div>
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <div class="dropdown-item" routerLink="/app/user/profile">
          View Profile
        </div>
      </li>
      <li *ngIf="!externalLogin">
        <div class="dropdown-item" routerLink="/app/user/change-password">
          Change Password
        </div>
      </li>
      <li>
        <div class="dropdown-item">
          v{{VERSION}}
        </div>
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <div class="dropdown-item, vaitsn-logout" (click)="logout()">
          <div class="vaitsn-logout-text">
            Sign Out
          </div>
        </div>
      </li>
  </div>
</div>
</li>
<li class="vaitsn-nav-list-item">
  <a (click)="toggleMenu()" *ngIf="!collapsedMenu" class="vaitsn-nav-link">
    <mat-icon class="me-2" color="primary" style="transform: scale(1.5);">
      keyboard_double_arrow_left
    </mat-icon>
    <div class="vaitsn-nav-list-text">
      Collapse
    </div>
  </a>
  <a (click)="toggleMenu()" *ngIf="collapsedMenu" class="vaitsn-nav-link">
    <mat-icon color="primary" style="transform: scale(1.5); margin-left: 155px;">
      keyboard_double_arrow_right
    </mat-icon>
  </a>
</li>
</ul>
</div>
<div class="vaitsn-desktop-header" [ngClass]="collapsedMenu?'vaitsn-collapsed-header':'vaitsn-expanded-header'">
  <div class="vaitsn-username">
    {{this.userName}}
  </div>
</div>
</div>

<div class="p-4 my-container" [ngClass]="collapsedMenu?'vaitsn-collapsed-menu-cont':'vaitsn-expanded-menu-cont'">
  <router-outlet></router-outlet>
</div>