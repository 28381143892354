<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <h1 class="form-h1">Coaching / TA</h1>
    <p>Select all that apply.</p>
    <form [formGroup]="eventCoachingForm" (ngSubmit)="onSubmit()">
        <div class="topics-grid-sm-1col topics-grid-lg-3cols">
            <div *ngFor="let topic of eventTopics">
                <div class="vaitsn-form-control">
                    <mat-checkbox [formControlName]="topic.entityName" [id]="topic.entityName">
                        {{topic.displayName}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="custom-mat-error">
                <mat-error *ngIf="eventCoachingForm.errors?.['checkedBoxRequired']">
                    Select one or more topics to continue.
                </mat-error>
            </div>
        </div>
        <!-- TODO: fix issue with comments resize overlapping elements below it -->
        <div class="grid-1col">
            <div id="otopic1" class="grid-textarea">
                <mat-label>Comments</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-area">
                        <textarea matInput formControlName="otopic1" placeholder="Enter Any Comments"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="vaitsn-form-submit-container" [ngClass]="{'disabled': eventCoachingForm.invalid }">
            <vaitsn-button color="secondary" (click)="onBackClicked()">
                Previous
            </vaitsn-button>
            <div></div>
            <vaitsn-button color="primary" class="vaitsn-btn-primary" role="submit" (submitted)="onSubmit()">
                Next
            </vaitsn-button>
        </div>
    </form>
</div>