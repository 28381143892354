import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { User } from 'src/app/modules/core/models/user';
import { UserManagementService } from '../../services/user-management/user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [UserManagementService],
})
export class UserManagementComponent implements OnInit {
  public users$: Observable<User[]>;

  displayModal: boolean;
  currentUserId: string;

  constructor(
    private userManagementSvc: UserManagementService,
  ) {}

  ngOnInit(): void {
    this.users$ = this.userManagementSvc.users$;
    this.reloadUsers();
  }

  public deleteUser(id: string): void {
    this.currentUserId = id;
    this.displayModal = true;
  }

  onConfirm() {
    this.userManagementSvc
      .deleteUser(this.currentUserId)
      .pipe(
        tap(() => this.reloadUsers()),
      ).subscribe();
    this.displayModal = false;
  }

  onReject() {
    this.displayModal = false;
  }

  public forceResetPassword(id: string): void {
    this.userManagementSvc
      .forceResetPassword(id)
      .pipe(
        tap(() => this.reloadUsers()),
      ).subscribe();
  }

  public resendEmail(id: string): void {
    this.userManagementSvc
      .sendUserRegistrationEmail(id)
      .pipe(
        tap(() => this.reloadUsers()),
      ).subscribe();
  }

  private reloadUsers(): void {
    this.userManagementSvc.getUsers().subscribe();
  }
}
