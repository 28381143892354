import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IEventType } from "../models/interfaces/event.interface";
import { environment } from "src/environments/environment";
import { Observable, map } from "rxjs";
import { IActionOdataResult } from "../models/interfaces/action-result.interface";
import { IParamObj, IParamQuery } from "../models/interfaces/param-query.interface";

@Injectable({
    providedIn: 'root'
})
export class EventTypeService {
    constructor(private http: HttpClient) { }
    /**
     * Returns an array of event types based on http query params
     */
    public get(params?: IParamObj): Observable<Array<IEventType>> {
        const paramQuery: IParamQuery = (params) ? { params: params } : null;
        return this.getEventTypes(paramQuery).pipe(map((result) => result.value as Array<IEventType>));
    }

    private getEventTypes(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/EventTypes', httpParams);
    }
}