import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { TruncatePipe } from './truncate.pipe';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [UserEditorComponent, TruncatePipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputMaskModule, FileUploadModule],
  exports: [UserEditorComponent, TruncatePipe],
})
export class SharedModule {}
