import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { AuthFlowRoutingModule } from './auth-flow-routing.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PasswordConfirmationComponent } from './components/password-confirmation/password-confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserInvitationComponent } from './pages/user-invitation/user-invitation.component';
import { RefreshPasswordComponent } from './pages/refresh-password/refresh-password.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CoreModule } from '../core/core.module';
import { PasswordModule } from 'primeng/password';
import { VAITSNComponentsModule } from '../vaitsn/components/vaitsn-components.module';

// Material
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordConfirmationComponent,
    UserInvitationComponent,
    RefreshPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthFlowRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    DividerModule,
    PasswordModule,
    CoreModule,
    MatInputModule,
    MatFormFieldModule,
    VAITSNComponentsModule
  ],
})
export class AuthFlowModule {}
