<div class="d-flex flex-row align-items-start">
  <label for="role" class="vaitsn-input-label">Roles: </label>
</div>
<div class="row">
  <div class="d-flex flex-wrap align-items-center my-2">
    <div id="role-fields" class="form-check form-check-inline" *ngFor="let role of roles">
      <input class="form-check-input" type="radio" id="{{ role.name }}Checkbox" [checked]="selectedRole == role.name"
        (click)="onRoleSelect(role.name)" />
      <label class="vaitsn-input-label">
        {{ role.name.charAt(0).toUpperCase()+ role.name.slice(1) }}
      </label>
    </div>
  </div>
</div>