<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">ASQ3 Form</h1>
    </div>
    
    <form [formGroup]="ASQForm" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermisson.Consultant]" (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach>
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vaitsn-form-grid-cell">              
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle class="vaitsn-date-button" matIconSuffix [for]="DateSubmitted"><mat-icon  class="vaitsn-date-icon" matDatepickerToggleIcon><svg xmlns="http://www.w3.org/2000/svg" width="11px" height="12px" viewBox="0 0 11px 12px" fill="none">
                            <path d="M9.9 1.09091H9.35V0H8.25V1.09091H2.75V0H1.65V1.09091H1.1C0.495 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.495 12 1.1 12H9.9C10.505 12 11 11.5091 11 10.9091V2.18182C11 1.58182 10.505 1.09091 9.9 1.09091ZM9.9 10.9091H1.1V3.81818H9.9V10.9091Z" fill="#195DC3"/>
                            </svg></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        
            <div class="vaitsn-form-grid-cell">
                <mat-label>Program/ Classroom/ Child</mat-label>
                <div class="vaitsn-form-control">
                    <app-lookup-program-classroom-child (select$)="selectedProgram($event)" ></app-lookup-program-classroom-child>
                </div>
                <div *ngIf="isInputValid('LookupId')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program, Classroom, and Child are Required. Click the magnifying glass to search.
                    </div>
                </div>
            </div>

        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Observation Date</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="Date" formControlName="Date" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle class="vaitsn-date-button" matIconSuffix [for]="Date"><mat-icon  class="vaitsn-date-icon" matDatepickerToggleIcon><svg xmlns="http//www.w3.org/2000/svg" width="11px" height="12px" viewBox="0 0 11px 12px" fill="none">
                            <path d="M9.9 1.09091H9.35V0H8.25V1.09091H2.75V0H1.65V1.09091H1.1C0.495 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.495 12 1.1 12H9.9C10.505 12 11 11.5091 11 10.9091V2.18182C11 1.58182 10.505 1.09091 9.9 1.09091ZM9.9 10.9091H1.1V3.81818H9.9V10.9091Z" fill="#195DC3"/>
                          </svg></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                </div>

                <div *ngIf="isInputValid('Date')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Observation Date is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child Age (in months)</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min="0" max="36" matInput formControlName="AgeMonth">
                    </mat-form-field>
                </div>
                
                <div *ngIf="isInputValid('AgeMonth')" class="vaitsn-invalid-container-small" >
                    <div class="alert alert-danger">
                        Child Age is Required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Communication</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Communication">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Communication')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Communication is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Gross Motor</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="GrossMotor">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('GrossMotor')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Gross Motor is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Fine Motor</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="FineMotor">
                    </mat-form-field>
                </div>   
                <div *ngIf="isInputValid('FineMotor')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Fine Motor is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Problem Solving</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="ProblemSolving">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ProblemSolving')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Problem Solving is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Personal Social</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="PersonalSocial">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('PersonalSocial')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Personal Social is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <div class="vaitsn-form-control">
                    <mat-label>Adjust for Prematurity</mat-label> <br>
                    <mat-radio-group formControlName="AdjustForPrematurity" class="vaitsn-radio-list">
                        <mat-radio-button class="vaitsn-radio-button" [value]=true>Yes</mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]=false>No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputValid('AdjustForPrematurity')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Adjust For Prematurity is Required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>
    </form>
</div>