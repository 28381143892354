import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { passwordMatch } from '../../../core/validators/confirm.validator';
import { ErrorStateMatcher } from '@angular/material/core';


class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
  public form: FormGroup;
  public submitting: boolean = false;
  public isPasswordValid: boolean = false;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  errorMatcher = new CrossFieldErrorMatcher();

  // at least 1 of each: digit, lowercase character, uppercase, symbol
  // no whitespace
  // min length: 6
  private passwordPattern =
    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.*\\s).{6,}$';

  @Output() passwordSubmit: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        confirmedPassword: ['', [Validators.required]],
      },
      {
        validator: this.passwordValidator,
      }
    );
  }

  public validate(): boolean {
    if (!this.form.valid) return false;

    const formData = this.form.value;
    return formData.password === formData.confirmedPassword;
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('confirmedPassword').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  public changeVisibility(fieldName: string): void {
    if (fieldName === 'password') {
      var x = (document.getElementById('passwordInput') as HTMLInputElement).type;
      if (x === 'password') {
        this.showPassword = true;
        (document.getElementById('passwordInput') as HTMLInputElement).type =
          'text';
      }
      else if (x === 'text') {
        this.showPassword = false;
        (document.getElementById('passwordInput') as HTMLInputElement).type =
          'password';
      }
    }
    if (fieldName === 'confirm') {
      var y = (
        document.getElementById('confirmPasswordInput') as HTMLInputElement
      ).type;
      if (y === 'password') {
        this.showConfirmPassword = true;
        (
          document.getElementById('confirmPasswordInput') as HTMLInputElement
        ).type = 'text';
      }
      else if (y === 'text') {
        this.showConfirmPassword = false;
        (
          document.getElementById('confirmPasswordInput') as HTMLInputElement
        ).type = 'password';
      }
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = this.form.value;

    this.submitting = true;
    this.passwordSubmit.emit(formData.password);

    this.submitting = false;
  }



  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public isRequirementMet(requirement: string): boolean {
    switch (requirement) {
      case 'minLength':
        return this.password.value.length >= 6;
      case 'character':
        const passwordValue = this.password.value;
        const hasLowerCase = /[a-z]/.test(passwordValue);
        const hasUpperCase = /[A-Z]/.test(passwordValue);
        return hasLowerCase && hasUpperCase;
      case 'number':
        return /\d/.test(this.password.value);
      case 'special':
        return /\W/.test(this.password.value);
      default:
        return false;
    }
  }


  public getPasswordRequirementStatus(requirement: string): string {
    return this.isRequirementMet(requirement) ? 'valid' : 'invalid';
  }

  public getRequirementClass(requirement: string): string {
    return this.isRequirementMet(requirement) ? 'valid' : 'invalid';
  }

  public getPasswordRequirements(): string[] {
    return ['minLength', 'character', 'number', 'special'];
  }

  private updatePasswordValidity() {
    this.isPasswordValid = this.password.valid && this.form.valid;
  }
}


