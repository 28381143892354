<ng-container id="report-component">
    <div *ngIf="showThenBlock; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div id="report-list-block-content">
            <h1>Reports</h1>
            <h5>Choose a report to download (PDF).</h5>
            <mat-list role="list">
                <mat-list-item [id]="'item' + report.reportName" role="listitem" *ngFor="let report of reports">
                    <vaitsn-button size="large" (click)="showElse(report.reportName)">
                        {{report.buttonText}}
                    </vaitsn-button>
                </mat-list-item>
            </mat-list>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div id="report-params-block-content" class="d-flex flex-column justify-content-start align-items-center">
            <div class="card shadow-sm" style="max-width: 435px">
                <div class="card-header">{{currentReportName}}</div>
                <div class="card-body" [formGroup]="formGroup">
                    <div class="card-body-text">
                        Please choose report parameters.
                    </div>
                    <div class="vaitsn-form-grid-cell">
                        <mat-label>Coach</mat-label>
                        <div class="vaitsn-form-control">
                            <mat-form-field appearance="outline" class="vaitsn-text-field">
                                <input type="text" matInput (keyup)="vaUserSearch(getEventValue($event))"
                                    [matAutocomplete]="vaUserAuto"
                                    placeholder="Search by First Name, Last Name, or Username..."
                                    formControlName="coach" [readonly]="isCoach">
                                <mat-autocomplete #vaUserAuto="matAutocomplete" [displayWith]="vaUserDisplay">
                                    <mat-option *ngFor="let user of vaUsers$ | async" [value]="user">
                                        {{vaUserDisplay(user)}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="vaitsn-form-grid-cell vaitsn-field-normal">
                        <mat-label>Date Range: Begin date - End date</mat-label>
                        <div class="vaitsn-form-control">
                            <mat-form-field appearance="outline" class="vaitsn-date">
                                <mat-date-range-input [rangePicker]="picker">
                                    <input id="reportParamBegin" matStartDate formControlName="begin"
                                        placeholder="MM/DD/YYYY">
                                    <input id="reportParamEnd" matEndDate formControlName="end"
                                        placeholder="MM/DD/YYYY">
                                </mat-date-range-input>
                                <mat-datepicker-toggle class="vaitsn-date-button" matIconSuffix [for]="picker">
                                    <mat-icon class="vaitsn-date-icon" matDatepickerToggleIcon><svg
                                            xmlns="http://www.w3.org/2000/svg" width="11px" height="12px" fill="none">
                                            <path
                                                d="M9.9 1.09091H9.35V0H8.25V1.09091H2.75V0H1.65V1.09091H1.1C0.495 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.495 12 1.1 12H9.9C10.505 12 11 11.5091 11 10.9091V2.18182C11 1.58182 10.505 1.09091 9.9 1.09091ZM9.9 10.9091H1.1V3.81818H9.9V10.9091Z"
                                                fill="#195DC3" />
                                        </svg></mat-icon>
                                </mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="card-body-buttons">
                        <vaitsn-button id="params-cancel-btn" role="cancel" size="large" color="secondary"
                            (click)="cancelElse()">
                            Cancel
                        </vaitsn-button>
                        <vaitsn-button id="params-submit-btn" role="submit" size="large" color="primary"
                            (click)="submitElse()" [disabled]="!formGroup.valid">
                            Submit
                        </vaitsn-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>