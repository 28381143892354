import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vaitsn-button',
  templateUrl: './vaitsn-button.component.html',
  styleUrls: ['./vaitsn-button.component.scss'],
})
export class VaitsnButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'warn' = 'primary';
  @Input() size: 'large' | 'small' | 'square' | 'custom'= 'large';
  @Input() isLoading = false;
  @Input() hasLoading = true;
  @Input() role: 'submit' | 'cancel' | 'reset' | 'button' = 'submit';
  @Input() customHeight: String = '';
  @Input() customWidth: String = '';
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<void>();
  @Output() submitted = new EventEmitter<void>();

  onClick(): void {
    if (this.hasLoading) this.isLoading = true;
    this.clicked.emit();
    if (this.role =="submit") this.submitted.emit();
    setTimeout(() => {
      this.isLoading = false; 
    }, 3000); 
  }

  getButtonClasses(): string {
    return `button-${this.color} button-${this.size}`;
  }
}
