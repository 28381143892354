import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { IActionOdataResult, IActionResult } from "../models/interfaces/action-result.interface";
import { ITravelRecord, ITravelVoucher, ITravelVoucherRequest } from "../models/interfaces/travel.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IParamObj, IParamQuery } from "../models/interfaces/param-query.interface";

@Injectable()
export class TravelService {

    constructor(private http: HttpClient) { }

    /**
     * Returns an array of travel voucher records
     */
    public get(params?: IParamObj): Observable<Array<ITravelRecord>> {
        const paramQuery: IParamQuery = (params) ? { params: params } : null;
        return this.getTravelVoucherRecords(paramQuery).pipe(map((result) => result.value as Array<ITravelRecord>));
    }

    private getTravelVoucherRecords(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/TravelRecords', httpParams);
    }

    /**
     * Will create a new travel voucher and its connected travel records and Return the newly created voucher
     */
    public post(req: ITravelVoucherRequest): Observable<ITravelVoucher> {
        return this.postTravelVoucher(req).pipe(map((result) => result.data as ITravelVoucher))
    }

    private postTravelVoucher(req: ITravelVoucherRequest): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + 'TravelVoucher/CreateTravelVoucher', req);
    }

    /**
    * Will patch a travel voucher and its connected travel records and Return the newly created voucher
    */
    public patch(req: ITravelVoucherRequest): Observable<ITravelVoucher> {
        return this.patchTravelVoucher(req).pipe(map((result) => result.data as ITravelVoucher))
    }

    private patchTravelVoucher(req: ITravelVoucherRequest): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + 'TravelVoucher/PatchTravelVoucher', req);
    }

    /**
     * Will soft delte a travel voucher and its connected travel records
     */
    public delete(key: number): Observable<number> {
        return this.deleteTravelVoucher(key).pipe(map((result) => result.data as number))
    }

    private deleteTravelVoucher(key: number): Observable<IActionResult> {
        return this.http.delete<IActionResult>(environment.apiBaseURL + 'TravelVoucher/DeleteTravelVoucher(' + key + ')');
    }

}