import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  IChild,
  IClassroom,
  ILookupAssociatedClassProgramChild,
  IParamObj,
  IProgram,
  IProgramChildSearchQuery
} from '../../models';
import { LookupAssociatedClassProgramChildService } from '../../services/lookup-associated-class-program-child.service';

@Component({
  selector: 'app-lookup-classroom',
  templateUrl: './lookup-classroom.component.html',
  styleUrls: ['./lookup-classroom.component.scss']
})
export class LookupClassroomComponent implements OnInit {
  @Input() currentProgram: IProgram = null;
  @Input() currentClassroom: IClassroom = null;
  @Input() currentChild: IChild = null;
  @Output() select$ = new EventEmitter<ILookupAssociatedClassProgramChild>();

  public isNotSearching: boolean = true;
  public associatedClassPrograms$: Observable<Array<ILookupAssociatedClassProgramChild>>;
  private programSearchText$ = new Subject<string>();
  private programODataFilterBuilder = ODataFilterBuilder();

  public searchQuery: IProgramChildSearchQuery = {
    programCode: '',
    name: '',
    address: '',
    classroom: '',
    childCode: '',
    created: ''
  };

  constructor(
    private lacpcService: LookupAssociatedClassProgramChildService,
  ) { }

  ngOnInit() {
    this.getPrograms();
  }

  onSelect(associatedClassPrograms: ILookupAssociatedClassProgramChild) {
    this.currentProgram = associatedClassPrograms.program;
    this.currentClassroom = associatedClassPrograms.classroom;
    this.currentChild = associatedClassPrograms.child;
    this.isNotSearching = true;
    this.select$.emit(associatedClassPrograms);
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public programSearch(searchString: string) {
    this.buildFilter();
    this.programSearchText$.next(searchString);
  }

  public initSearchFilters() {
    this.isNotSearching = false;
    this.searchQuery = {
      programCode: '',
      name: '',
      address: '',
      classroom: '',
      childCode: '',
      created: ''
    }
  }

  private buildFilter() {
    this.programODataFilterBuilder = ODataFilterBuilder("and")
      .contains('Program/code', this.searchQuery.programCode)
      .contains('Program/name', this.searchQuery.name)
      .contains('Program/address', this.searchQuery.address)
      .contains('Classroom/name', this.searchQuery.classroom)
      .contains('Child/Code', this.searchQuery.childCode)
  }

  private getPrograms() {
    this.associatedClassPrograms$ = this.programSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        const params = { $filter: this.programODataFilterBuilder.toString(), $expand: 'Program($orderby=created desc),Classroom, Child', $top: 8 } as IParamObj;
        return this.lacpcService.get(params)
      })
    );
  }
}
