import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";

import { environment } from "src/environments/environment";
import { 
    IActionOdataResult,
    IActionResult,
    IEvent,
    IParamObj,
    IParamQuery,
    EventObj,
    IEventDetailTopic
} from "../models";

/**
 * @todo store information on main presenter VAUser
 */
@Injectable({
    providedIn: 'root'
})
export class EventService {
    public $currentEvent = new BehaviorSubject<EventObj>(new EventObj());

    private _EVENT_DETAIL_TOPICS: IEventDetailTopic[] = [
        { 
            displayName:                            '123 READ Refresher',
            entityName:                             'is123ReadRefresher',
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        }, 
        {
            displayName:                            'ASQ3/ASQ:SE-2 Tools',
            entityName:                             'isAsqCombo',
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        {
            displayName:                            'ASQ Screening',
            entityName:                             'isAsq3',
            showIfCoaching:                         true,
            showIfChildCoaching:                    true,
            showIfTA:                               true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Challenging Behaviors/Trauma',
            entityName:                             'isChallengingBehavior',
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'CLASS Observation',
            entityName:                             'isClassObservationTesting',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'CSEFEL Tools',
            entityName:                             'isCsefelTools',
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Curriculum',
            entityName:                             'isCurriculum',
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Curriculum (Others)',
            entityName:                             'isCurriculumOther',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Curriculum (STREAMin3)',
            entityName:                             'isCurriculumStreamIn3',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Diversity, Inclusion, Culture',
            entityName:                             'isDiversityInclusionCulture',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Early Learning Development System (ELDS)',
            entityName:                             'isEarlyLearningDevelopmentSystem',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Early Literacy',
            entityName:                             'isEarlyLiteracy',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Emotional Literacy',
            entityName:                             'isEmotionalLiteracy',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Family Engagement',
            entityName:                             'isFamilyEngagement',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Health and Safety',
            entityName:                             'isHealthAndSafety',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        },
        { 
            displayName:                            'I/T Endorsement',
            entityName:                             'isInfantToddlerEndorsement',
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true,
        },
        { 
            displayName:                            'Inclusion',
            entityName:                             'isDisaInclusion',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Infant Early Language Support',
            entityName:                             'isEarlyLanguageSupport',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Infant Facilitated Explorations',
            entityName:                             'isFacilitatedExploration',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Infant Relational Climate',
            entityName:                             'isRelationalClimate',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Infant Teacher Sensitivity',
            entityName:                             'isInfantTeacherSensitivity',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'LENA Coaching',
            entityName:                             'isLenaCoaching',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITS:                              true
        },
        { 
            displayName:                            'M-CHAT R/F',
            entityName:                             'isMchat',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Nurturing Responsive Interactions',
            entityName:                             'isNurturingAndResponsiveInteraction',
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'PICCOLO',
            entityName:                             'isPiccolo',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Pre/PostAssessments',
            entityName:                             'isPrePostAssessments',
            showIfCoaching:                         true,
            showIfChildCoachingITBCOnly:            true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Program Management/Leadership',
            entityName:                             'isProgramManagement',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'QIP/CIP',
            entityName:                             'isQipCip',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITS:                              true
        },
        { 
            displayName:                            'READ Coaching',
            entityName:                             'is123ReadCoaching',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Resources & Referrals',
            entityName:                             'isResourcesAndReferrals',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Screening, Observation & Monitoring',
            entityName:                             'isScreening',
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'SE Competencies',
            entityName:                             'isSeCompetencies',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'SE Development',
            entityName:                             'isSeDevelopment',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'SECP/SEIP',
            entityName:                             'isSecpSeip',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoaching:                    true,
            showIfITBC:                             true
        },
        { 
            displayName:                            'Toddler Behavior Guidance',
            entityName:                             'isBehaviorGuidance',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Facilitation of Learning and Development',
            entityName:                             'isFacilitationOfLearningAndDevelopment',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Language Modeling',
            entityName:                             'isLanguageModeling',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Negative Climate',
            entityName:                             'isNegativeClimate',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Positive Climate',
            entityName:                             'isPositiveClimate',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Quality Feedback',
            entityName:                             'isQualityOfFeedback',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Regard for Child Perspectives',
            entityName:                             'isRegardForChildPerspectives',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Toddler Teacher Sensitivity',
            entityName:                             'isToddlerTeacherSensitivity',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfChildCoachingITBCOnly:            true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true,
            showIfITS:                              true
        },
        { 
            displayName:                            'Wellness and Retention',
            entityName:                             'isWellnessAndRetention',
            showIfCoaching:                         true,
            showIfTA:                               true,
            showIfTraining:                         true,
            showIfClusterOrCombinedTraining:        true,
            showIfITBC:                             true
        },
    ];

    constructor(private http: HttpClient) { }

    /** List of event topics, with details on how to display each **/
    public get EVENT_DETAIL_TOPICS(): IEventDetailTopic[] {
        return this._EVENT_DETAIL_TOPICS;
    }
    
    public refreshCurrentEvent(id?: number): Observable<EventObj> {
        if (id) {
            return this.getEventFullDetails(id).pipe(map((result) => {
                this.$currentEvent.next(result.data as EventObj);
                return result.data as EventObj
            }));
        }
        this.$currentEvent.next(new EventObj());
        return this.$currentEvent.asObservable();
    }

    private getEventFullDetails = (id: number): Observable<IActionResult> => this.http.get<IActionResult>(environment.apiBaseURL + 'Event/GetEventFullDetails?key=' + id);

    /**
     * Will create a new event and Return the newly created event
     */
    public post(): Observable<EventObj> {
        return this.postEvent(this.$currentEvent.value).pipe(map((result) => result.data as EventObj))
    }

    private postEvent(event: EventObj): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + 'Event/CreateEvent', event);
    }


    /**
     * Will update an existing event and Return the newly updated event
     */
    public update(): Observable<EventObj> {
        return this.updateEvent(this.$currentEvent.value).pipe(map((result) => result.data as EventObj))
    }

    private updateEvent(event: EventObj): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + 'Event/UpdateEvent', event);
    }

    /**
     * Returns an array of event types based on http query params
     */
    public get(params?: IParamObj): Observable<Array<IEvent>> {
        const paramQuery: IParamQuery = (params) ? { params: params } : null;
        return this.getEvents(paramQuery).pipe(map((result) => result.value as Array<IEvent>));
    }

    private getEvents(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/Events', httpParams);
    }
}
