import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, Observable, tap } from 'rxjs';
import { User } from 'src/app/modules/core/models/user';
import { IUser2FAChangeRequest } from 'src/app/modules/core/models/user-2fa-change-request.interface';
import { EditUserService } from '../../services/edit-user/edit-user.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [EditUserService],
})
export class EditUserComponent implements OnInit {
  public user$: Observable<User | null>;
  public loading: boolean = false;
  public submitting: boolean = false;

  constructor(
    private editUserSvc: EditUserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.user$ = this.editUserSvc.user$;

      const userId = params['id'];

      this.loading = true;
      this.editUserSvc.initializeForUser(userId).pipe(
        finalize(() => this.loading = false)
      ).subscribe();
    });
  }

  public onSubmit(userChangeRequest: IUser2FAChangeRequest): void {
    const user = userChangeRequest.user;

    this.submitting = true;
    this.editUserSvc.save(user).pipe(
      tap(() => this.router.navigateByUrl('/app/admin/users')),
      finalize(() => this.submitting = false)
    ).subscribe();
  }

  public cancel(): void {
    this.editUserSvc.cancel();
  }

  public onCancel(): void {
    this.location.back(); 
  }
}
