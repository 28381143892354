<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">TPITOS</h1>
    </div>

    <form [formGroup]="TpitosForm" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Consultant]" (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach>
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Classroom</mat-label>
            <div class="vaitsn-form-control">
                <app-lookup-classroom (select$)="selectedProgram($event)" ></app-lookup-classroom>
            </div>
            <div *ngIf="isInputValid('Classroom')" class="vaitsn-invalid-container">
                <div class="alert alert-danger">
                    Classroom is Required. Click the magnifying glass to search.
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-2col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Observation Date</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="ObservationDate" formControlName="ObservationDate" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="ObservationDate"></mat-datepicker-toggle>
                        <mat-datepicker #ObservationDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ObservationDate')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Observation Date is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Teacher Name</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="text" matInput formControlName="TeacherName">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('TeacherName')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Teacher Name is Required.
                    </div>
                </div>
            </div>
        </div>
        
        <div class="vaitsn-form-grid-3col">
            
            <div class="vaitsn-form-grid-cell">
                <mat-label>CBRY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cbry">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cbry')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cbry Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CBRN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cbrn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cbrn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cbrn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>DWRY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Dwry">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Dwry')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Dwry Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                    <mat-label>DWRN</mat-label>
                    <div class="vaitsn-form-control" class="vaitsn-text-field">
                        <mat-form-field appearance="outline">
                            <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                                placeholder="0.00" formControlName="Dwrn">
                        </mat-form-field>
                    </div>
                    <div *ngIf="isInputValid('Dwrn')" class="vaitsn-invalid-container-small">
                        <div class="alert alert-danger">
                            Dwrn Score is Required.
                        </div>
                    </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                    <mat-label>PPIY</mat-label>
                    <div class="vaitsn-form-control" class="vaitsn-text-field">
                        <mat-form-field appearance="outline">
                            <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                                placeholder="0.00" formControlName="Ppiy">
                        </mat-form-field>
                    </div>
                    <div *ngIf="isInputValid('Ppiy')" class="vaitsn-invalid-container-small">
                        <div class="alert alert-danger">
                            Ppiy Score is Required.
                        </div>
                    </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>PPIN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Ppin">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Ppin')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Ppin Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CAEY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Caey">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Caey')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Caey Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CAEN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Caen">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Caen')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Caen Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>REFY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Refy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Refy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Refy Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>REFN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Refn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Refn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Refn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CBEY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cbey">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cbey')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cbey Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CBEN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cben">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cben')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cben Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>RDCY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Rdcy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Rdcy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Rdcy Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>RDCN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Rdcn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Rdcn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Rdcn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>SMDY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Smdy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Smdy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Smdy Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>SMDN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Smdn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Smdn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Smdn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>SRTY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Srty">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Srty')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Srty Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>SRTN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Srtn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Srtn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Srtn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>EAY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Eay">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Eay')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Eay Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>EAN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Ean">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Ean')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Ean Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>TCPY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Tcpy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Tcpy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Tcpy Score is Required..
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>TCPN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Tcpn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Tcpn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Tcpn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>EEPY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Eepy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Eepy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Eepy Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>EEPN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Eepn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Eepn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Eepn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CWFY</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cwfy">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cwfy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cwfy Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>CWFN</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="Cwfn">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Cwfn')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Cwfn Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>TCRedYes</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="TCRedYes">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('TCRedYes')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        TCRedYes Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>TCRedNo</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="TCRedNo">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('TCRedNo')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        TCRedNo Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>ClassRedYes</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="ClassRedYes">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ClassRedYes')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        ClassRedYes Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>ClassRedNo</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min=0 ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" matInput
                            placeholder="0.00" formControlName="ClassRedNo">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ClassRedNo')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        ClassRedNo Score is Required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>
    </form>
</div>