import { Injectable } from "@angular/core";

export class PiccoloScores {
    VAUserId: string;
    AgeMonth: number;
    Created: string;
    LookupAssociatedClassProgramChildId: number;
    Observation: string;
    AffectionTotal: number;
    AffectionComment: string;
    ResponsivenessTotal: number;
    ResponsivenessComment: string;
    EncouragementTotal: number;
    EncouragementComment: string;
    TeachingTotal: number;
    TeachingComment: string;
    CreatedByUser: string;
    LastUpdatedUser: string;
  
    constructor() {
        this.VAUserId = null;
        this.AgeMonth = null;
        this.Created = new Date().toDateString();
        this.LookupAssociatedClassProgramChildId = null;    
        this.Observation = new Date().toLocaleDateString();
        this.AffectionTotal = null;
        this.AffectionComment = '';
        this.ResponsivenessTotal = null;
        this.ResponsivenessComment = '';
        this.EncouragementTotal = null;
        this.EncouragementComment = '';
        this.TeachingTotal = null;
        this.TeachingComment = '';
        this.CreatedByUser = null;
        this.LastUpdatedUser = null;
    }
  
  }