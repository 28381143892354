export class ClassScoresRequest {
    VAUserId: string;
    ClassroomId: string;
    Observation: string;
    ObserverName: string
    Notes: string;
    IsPost: boolean;
    RelationalClimate?: number;
    TeacherSensitivity?: number;
    FacilitatedExploration?: number;
    EarlyLanguageSupport?: number;
    PositiveClimate?: number;
    NegativeClimate?: number;
    RegardForChildPerspective?: number;
    BehaviorGuidance?: number;
    FacilitatedLearningDevelopment?: number;
    QualityFeedback?: number;
    LanguageModeling?: number;
  }