import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/modules/core/models/user';
import { UserRoleManagementComponent } from '../../admin/users/components/user-role-management/user-role-management.component';
import { IUser2FAChangeRequest } from '../../core/models/user-2fa-change-request.interface';

export function requiredIfValidator(predicate: BooleanFn) {
  return ((formControl: FormControl) => {
    if (!formControl.parent) return null;
    if (predicate()) return Validators.required(formControl);
    return null;
  })
}

export interface BooleanFn {
  () : boolean;
}

@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit, AfterContentInit {
  public form: FormGroup;
  @ContentChild(UserRoleManagementComponent)
  userRoleManager: UserRoleManagementComponent;
  @Input() user: User | null;
  @Input() adminForm: boolean = false;
  @Output() submitUser: EventEmitter<IUser2FAChangeRequest> = new EventEmitter<IUser2FAChangeRequest>();

  constructor(private fb: FormBuilder, private location: Location) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      profilePic: [null],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      userName: [
        { value: '', disabled: !this.adminForm },
        [Validators.required, Validators.email],
      ],
      phoneNumber: ['', [requiredIfValidator(() => this.form.controls['twoFactorEnabled'].value == true && this.form.controls['twoFactorType'].value == 'text')]],
      roles: null,
      lastPasswordReset: null,
      twoFactorEnabled: false,
      twoFactorType: ['email'],
      twoFactorConfirmed: false, 
      notificationsEnabled: false,
    });

    if (this.user) {
      this.form.patchValue(this.user);
      this.form.controls['userName'].disable();
    } else {
      this.form.patchValue(new User());
    } 

    this.form.controls['twoFactorType'].valueChanges.subscribe(_ => {
      this.form.controls['phoneNumber'].updateValueAndValidity();
    })
    this.form.controls['twoFactorEnabled'].valueChanges.subscribe(_ => {
      this.form.controls['phoneNumber'].updateValueAndValidity();
    })
  }

  ngAfterContentInit(): void {
    if (this.userRoleManager) {
      this.userRoleManager.rolesChanged.subscribe((roles) => {
        const roleControls = this.form.controls['roles'];
        roleControls.setValue(roles);
      });
    }
  }

  public toggle2FA(): void {
    this.form.controls['twoFactorEnabled'].setValue(!this.form.getRawValue()['twoFactorEnabled']);
  }

  public onCancel(): void{
    this.location.back();
  }

  public onSubmitUser(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const currentUser = new User(this.form.getRawValue());
    const twoFAPhoneChange = currentUser.twoFactorEnabled && currentUser.twoFactorType == 'text' && this.user?.phoneNumber != currentUser.phoneNumber;
    if (twoFAPhoneChange || !currentUser.twoFactorEnabled) {
      currentUser.twoFactorConfirmed = false;
    }

    const userChangeRequest: IUser2FAChangeRequest = {
      user: currentUser,
      check2FA: (!this.user?.twoFactorEnabled && currentUser.twoFactorEnabled) || twoFAPhoneChange
    };
    this.submitUser.emit(userChangeRequest);
  }

  imageUploader(file: any) {
    var fr = new FileReader();
  
    fr.onload=(e: any) => {
      var img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        var canvas  = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var maxPixelLength = 400;
        var resizeRatio = 0;

        if (img.width <= maxPixelLength && img.height <= maxPixelLength){
          resizeRatio = 1;
        }
        else if (img.width > img.height) {
          resizeRatio = img.width / maxPixelLength;
        } else {
          resizeRatio = img.height / maxPixelLength;
        }

        canvas.width = img.width / resizeRatio;
        canvas.height = img.height / resizeRatio;

        context.drawImage(img,0,0,canvas.width,canvas.height);
        this.form.controls['profilePic'].setValue(canvas.toDataURL());
      }  
    }

    fr.readAsDataURL(file.files[0]);
  }
}