<form class="password-confirm-form" [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="vaitsn-form-grid-cell vaitsn-field-normal">
    <mat-label>Password</mat-label>
    <div class="vaitsn-form-control">
      <mat-form-field appearance="outline" class="vaitsn-text-field">
        <input id="passwordInput" class="vaitsn-password-field" [type]="showPassword ? 'text' : 'password'" matInput aria-describedby="passwordHelp"  formControlName="password" />
        <span (click)="changeVisibility('password')" class="vaitsn-text-btn">
          {{showPassword ? 'HIDE' : 'SHOW'}}
        </span>
        <mat-error *ngIf="
          form.controls['password'].invalid &&
          (form.controls['password'].dirty ||
          form.controls['password'].touched)" class="vaitsn-invalid-container">
          Password required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="password-requirements">
    <p id="requirementsHeader">
      Password Requirements
    </p>
    <p class="requirement" [ngClass]="getRequirementClass('minLength')">
      <ng-template [ngTemplateOutlet]="isRequirementMet('minLength') ? valid : invalid"></ng-template>
      6 character minimum length
    </p>
    <p class="requirement" [ngClass]="getRequirementClass('character')">
      <ng-template [ngTemplateOutlet]="isRequirementMet('character') ? valid : invalid"></ng-template>
      Contains uppercase and lowercase letters
    </p>
    <p class="requirement" [ngClass]="getRequirementClass('number')">
      <ng-template [ngTemplateOutlet]="isRequirementMet('number') ? valid : invalid"></ng-template>
      Contains at least one number
    </p>
    <p class="requirement" [ngClass]="getRequirementClass('special')">
      <ng-template [ngTemplateOutlet]="isRequirementMet('special') ? valid : invalid"></ng-template>
      Contains at least one special character
    </p>

    <ng-template #invalid>
      <svg id="dot" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
        <path
          d="M1.554 3.094C0.700001 3.094 0 2.394 0 1.554C0 0.7 0.700001 0 1.554 0C2.408 0 3.108 0.7 3.108 1.554C3.108 2.394 2.408 3.094 1.554 3.094Z"
          fill="#9A9A9A" />
      </svg>
    </ng-template>
    <ng-template #valid>
      <svg id="checkmark" xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
        <path
          d="M8.85063 0.207011C8.91267 0.141469 8.98672 0.0894006 9.06844 0.0538547C9.15016 0.0183089 9.23791 0 9.32655 0C9.41519 0 9.50293 0.0183089 9.58465 0.0538547C9.66637 0.0894006 9.74042 0.141469 9.80247 0.207011C10.0625 0.47903 10.0661 0.918591 9.81156 1.19532L4.43599 7.7746C4.37496 7.84399 4.3009 7.89976 4.21833 7.93849C4.13577 7.97723 4.04644 7.99812 3.95581 7.99988C3.86518 8.00164 3.77515 7.98424 3.69124 7.94874C3.60733 7.91324 3.5313 7.86039 3.46779 7.79343L0.196815 4.36166C0.0706642 4.22846 0 4.04935 0 3.8628C0 3.67625 0.0706642 3.49714 0.196815 3.36394C0.258858 3.2984 0.332907 3.24633 0.414627 3.21078C0.496347 3.17524 0.584095 3.15693 0.672734 3.15693C0.761373 3.15693 0.849121 3.17524 0.930841 3.21078C1.01256 3.24633 1.08661 3.2984 1.14865 3.36394L3.92325 6.2752L8.83245 0.227719C8.8381 0.220444 8.84417 0.213529 8.85063 0.207011Z"
          fill="#19954B" />
      </svg>
    </ng-template>
  </div>

  <div class="vaitsn-form-grid-cell vaitsn-field-normal">
    <mat-label>Confirm Password</mat-label>
    <div class="vaitsn-form-control">
      <mat-form-field appearance="outline" class="vaitsn-text-field">
        <input id="confirmPasswordInput"  class="vaitsn-password-field" [type]="showConfirmPassword ? 'text' : 'password'" matInput aria-describedby="confirmPasswordHelp"
        formControlName="confirmedPassword" [errorStateMatcher]="errorMatcher"/>
        <span (click)="changeVisibility('confirm')" class="vaitsn-text-btn">
          {{showConfirmPassword ? 'HIDE' : 'SHOW'}}
        </span>
        <mat-error *ngIf="form.hasError('passwordsDoNotMatch')" class="vaitsn-invalid-container">
          Passwords don't match
        </mat-error>
      </mat-form-field>
    </div>
  </div>


  <vaitsn-button id="submit-btn" type="submit" color="primary" size="large" (clicked)="onSubmit()">Save &
    Submit</vaitsn-button>

</form>