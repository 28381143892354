import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { Tpitos } from "../models/interfaces/tpitos.interface";

@Injectable({
    providedIn: 'root'
})

export class TpitosService {
    constructor (
        private http: HttpClient,
    ) { }

    /**
    * Post Tpitos scores form data to api
    */
    // public postTpitosForm ( TpitosFormSubmittedData : any ) {
    //     return this.http.post<Tpitos>(environment.apiBaseURL + "Tpitos/CreateTpitos", TpitosFormSubmittedData).subscribe();
    // }

    public post(event: Tpitos): Observable<IActionResult> {
        return this.postTpitosForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postTpitosForm ( TpitosFormSubmittedData : Tpitos ): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "Tpitos/CreateTpitos", TpitosFormSubmittedData);
    }
}