import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { AuthAbstractService } from 'src/app/modules/core/services/auth/auth-abstract.service';

@Component({
  selector: 'app-refresh-password',
  templateUrl: './refresh-password.component.html',
  styleUrls: ['./refresh-password.component.scss'],
})
export class RefreshPasswordComponent implements OnInit {
  public token: string;
  public userName: string;
  public submitting: boolean = false;

  constructor(
    private authSvc: AuthAbstractService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    this.userName = this.route.snapshot.queryParams['username'];
  }

  public onPasswordSubmit(password: string): void {
    const resetPasswordRqt = {
      token: this.token,
      username: this.userName,
      password: password,
    };

    this.submitting = true;
    this.authSvc.resetPassword(resetPasswordRqt).pipe(
      tap(() => this.router.navigateByUrl('/login')),
      finalize(() => this.submitting = false)
    ).subscribe();
  }
}
