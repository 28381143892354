import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';
const { DateTime, Settings } = require("luxon");
import { MessageService, Message } from 'primeng/api';

import {
  IEvent,
  IEventType,
  ILookupAssociatedClassProgramChild,
  IParamObj,
  IProgram,
  ITravelLocationType,
  ITravelRecord,
  ITravelVoucher,
  ITravelVoucherRequest,
  IVAUser,
  TravelRecord
} from '../../../models';
import { EventService } from '../../../services/event.service';
import { EventTypeService } from '../../../services/event-type.service';
import { ProgramService } from '../../../services/program.service';
import { TravelLocationService } from '../../../services/travel-location.service';
import { TravelLocationTypeService } from '../../../services/travel-location-type.service';
import { TravelService } from '../../../services/travel.service';
import { VAUserRole, VAUserService } from '../../../services/va-user.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

/**
 * @todo turn into dynamic form (similar to event coaching details form)
 * @todo Coach field should use coach lookup component and be part of the form group
 * @todo test Edit
 * @todo test auto-fill Program / address from event's program
 */
@Component({
  selector: 'app-travel-voucher-details',
  templateUrl: './travel-voucher-details.component.html',
  styleUrls: ['./travel-voucher-details.component.scss'],
})

export class TravelVoucherDetailsComponent implements OnInit, OnDestroy {
  
  VAUserRole = VAUserRole;
  private _subscribedSubjects$ = new Subject<boolean>();
  travelVoucherForm: FormGroup = null;
  public isFormLoading: boolean = true;
  currentTravelVoucherId: number = 0;
  travelVoucherRecords: Array<TravelRecord> = [];
  public isRoundTrip = false;
  public created: Date = null;
  public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  public maxDate = new Date(new Date());
  public minTime = new Date();
  public maxTime = new Date();
  currentVAUser: IVAUser = null;
  currentWorkAddress: string = null;
  public events: Array<IEvent> = [];
  public selectedEvent: IEvent = null;
  public selectedEventProgram: IProgram = null;
  public selectedTraveledDate: Date = new Date();
  public showProgramSearch = true;
  public vaUsers$: Observable<Array<IVAUser>>;
  private vaUserSearchText$ = new Subject<string>();
  public travelLocationTypes: Array<ITravelLocationType> = [];
  public eventTypes: Array<IEventType> = [];

  constructor(
    private travelService: TravelService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    public vaUserService: VAUserService,
    private travelLocationTypeService: TravelLocationTypeService,
    private programService: ProgramService,
    private travelLocationService: TravelLocationService,
    private messages: MessageService,
    private toast: ToastService,
  ) {
    Settings.defaultZone = 'America/New_York';
    this.minTime.setHours(0, 0, 0, 0);
    this.maxTime.setHours(23, 31, 0, 0);
    this.currentTravelVoucherId = +this.activatedRoute.snapshot.paramMap.get('travelVoucherId');
  }

  /**
   * @todo get all required data (including current Travel voucher if editing) before init/display form
   */
  ngOnInit() {
    this.initForm();
    this.getAssociatedVAUser();
    this.getEventTypes();
    this.getTravelLocationTypes();
  }

  public isEditableOnPatch(isEditable: boolean) {
    if (this.currentTravelVoucherId > 0) return isEditable;
    return true;
  }

  getAssociatedVAUser() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(u => u !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        // if logged in as coach, auto-fill
        if (this.vaUserService.userHasRole(vaUser, VAUserRole.Coach)) {
          this.currentVAUser = vaUser;
          this.getWorkAddress();
        }
        else if (this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.getVAUsers();
        }
        else { //if user is only a supervisor, return to travel grid
          this.toast.error('Supervisors cannot perform data entry for Travel.');
          this.router.navigate(['app/travel']);
        }
       
        this.getEventWithFilters();
        if (this.currentTravelVoucherId > 0) {
          this.getCurrentTravelVoucher();
        } else {
          this.travelVoucherRecords.push(new TravelRecord());
          this.travelVoucherRecords.push(new TravelRecord());
        }
      })).subscribe();
  }

  private initForm() {
    this.travelVoucherForm = new FormGroup({
      traveled: new FormControl({ value: new Date(), disabled: (!this.isEditableOnPatch(false)) }, [Validators.required]),
      event: new FormControl({ value: null, disabled: (!this.isEditableOnPatch(false)) }),
      isRoundTrip: new FormControl({ value: false, disabled: (!this.isEditableOnPatch(false)) })
    });
  }

  public vaUserSearch(searchString: string) {
    this.vaUserSearchText$.next(searchString);
  }

  public vaUserDisplay = (user: IVAUser): string => (user) ? user.firstName + ' ' + user.lastName + ' | ' + user.username : '';

  private getVAUsers() {
    this.vaUsers$ = this.vaUserSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        const params = { $filter: ODataFilterBuilder('and')
          .eq('Deleted', null)
          .and(`VAUserRoles/any(userrole: userrole/role/normalizedName eq '${VAUserRole.Coach}')`)
          .and(ODataFilterBuilder('or')
            .contains('firstName', searchString)
            .contains('lastName', searchString)
            .contains('username', searchString)
        )
        .toString(), $top: 10 } as IParamObj;
        return this.vaUserService.get(params);
      }),
    );
  }

  public onVAUserChanged(event: any) {
    this.travelVoucherRecords.forEach((tv) => {
      if (tv.locationTypeId === 1) {
        tv.address = this.currentVAUser?.homeAddress;
      }
    });
    this.getWorkAddress();
  }

  public getEventValue(event: Event): any {
    return (event.target as HTMLInputElement).value;
  }

  /**
   * @todo Retrieve TravelVoucher as core object with TravelRecords expanded
   *       this should allow TV to be pulled only once and filtered by Deleted
   */
  public getCurrentTravelVoucher() {
    const filters = ODataFilterBuilder()
      .eq('travelVoucherId', this.currentTravelVoucherId)
      .eq('deleted', null);

    const params = { 
      $filter: filters.toString(), 
      $expand: 'TravelVoucher($expand=VAUser), FromProgram, Event($expand=Program), ToProgram' 
    } as IParamObj;

    this.travelService.get(params).pipe(
      takeUntil(this._subscribedSubjects$),
      map((travelRecords: Array<ITravelRecord>) => {
        if(travelRecords.length === 0) {
          const toastMessage: Message = {severity: 'error', summary: 'Error finding Travel Voucher with Id: ' + this.currentTravelVoucherId};
          this.messages.add(toastMessage);
          return this.router.navigate(['app/travel']);
        }

        return this.mapCurrentTravelVoucherAndRecords(travelRecords);
      }), tap((_) => this.isFormLoading = false
      )).subscribe({
        error: (e) => {
          console.error(e);
          this.router.navigate(['app/travel']);
        }
      });
  }

/**
 * @todo Fix timezone conversions
 * @param travelRecords
 * DateTime.fromFormat(travelRecords[0].travelVoucher.traveled, "yyyy-MM-dd'T'hh':'mm':'ss'Z'").toJSDate();
 */
  private mapCurrentTravelVoucherAndRecords(travelRecords: Array<ITravelRecord>) {
    this.created = new Date(travelRecords[0].travelVoucher.created);
    this.selectedTraveledDate = new Date(travelRecords[0].travelVoucher.traveled);
    travelRecords.forEach((tr, index) => {
      if (index === 0) {
        const fromRecord = new TravelRecord();
        fromRecord.id = tr.id;
        fromRecord.travelVoucherId = this.currentTravelVoucherId;
        fromRecord.locationTypeId = tr.fromTravelLocationTypeId
        fromRecord.eventId = tr.eventId
        fromRecord.program = tr.fromProgram;
        fromRecord.address = tr.from
        this.selectedEvent = (tr.event) ? tr.event : { id: 0, typeId: 1 } as IEvent;
        this.selectedEventProgram = (tr.event) ? tr.event.program : null;
        this.events.push(this.selectedEvent);
        this.isRoundTrip = (tr.typeId === 3)
        this.travelVoucherForm.patchValue({
          traveled: this.selectedTraveledDate,
          event: this.selectedEvent,
          isRoundTrip: this.isRoundTrip
        });
        this.updateMinMaxTime(DateTime.fromISO(tr.traveled).toJSDate());
        this.currentVAUser = tr.travelVoucher.vaUser;
        this.travelVoucherRecords.push(fromRecord);
      }
      const toRecord = new TravelRecord();
      toRecord.id = tr.id;
      toRecord.travelVoucherId = this.currentTravelVoucherId;
      toRecord.traveled = DateTime.fromISO(tr.traveled).toJSDate();
      toRecord.locationTypeId = tr.toTravelLocationTypeId;
      toRecord.eventId = tr.eventId;
      toRecord.program = tr.toProgram;
      toRecord.address = tr.to;
      toRecord.mileage = tr.mileage;
      toRecord.tolls = tr.tolls;
      toRecord.comment = tr.comment;
      toRecord.typeId = tr.typeId;
      this.travelVoucherRecords.push(toRecord);
    });
  }

  public updateMinMaxTime(currentDate: Date) {
    this.minTime = new Date(currentDate);
    this.minTime.setHours(0, 0, 0, 0);
    this.maxTime = new Date(currentDate);
    this.maxTime.setHours(23, 31, 0, 0);
  };

  public getMinTime(index: number): Date {
    if (index === 1) {
      return this.minTime;
    }
    const dt = new Date(this.travelVoucherRecords[index - 1]?.traveled);
    if(dt) dt.setHours(dt.getHours(), dt.getMinutes() + 30, 0, 0);
    return dt;
  }

  public getMaxTime(index: number): Date {
    if (index < this.travelVoucherRecords.length - 1 && this.travelVoucherRecords.length > 2) {
      const dt = new Date(this.travelVoucherRecords[index + 1]?.traveled);
      if(dt) dt.setHours(dt.getHours(), dt.getMinutes() - 30, 0, 0);
      return dt;
    }
    return this.maxTime;
  }

  dateTraveledChanged(event: any) {
    //console.debug('dateTraveledChanged');
    this.selectedTraveledDate = event.value;
    this.getEventWithFilters();
  }

  public eventDisplay = (event: IEvent): string => (event) ? (event.id === 0) ? 'Travel Only' : event.id + ' | ' + this.findEventType(event.typeId) : null;

  public onRoundTripChanged(event: any) {
    this.isRoundTrip = event.checked;
    if (this.isRoundTrip) {
      this.travelVoucherRecords.splice(2, (this.travelVoucherRecords.length - 2));
    }
  }

  private getEventWithFilters() {
    let date = this.selectedTraveledDate ? DateTime.fromJSDate(this.selectedTraveledDate) : this.selectedTraveledDate;
    const filters = { $filter: `eventDate eq ${date ? date.toFormat('yyyy-MM-dd') : null}` } as IParamObj;
    this.getEvents(filters);
  }

  private getEvents(filters: IParamObj) {
    this.eventService.get(filters).pipe(
      filter(e => e !== null),
      takeUntil(this._subscribedSubjects$),
      map((events) => {
        events.push({ id: 0, typeId: 1 } as IEvent)
        this.events = events;
        this.selectedEvent = null; //events.find(e => e.id === 0);
        this.travelVoucherForm.patchValue({
          event: this.selectedEvent,
        });
      }), tap((_) => (this.currentTravelVoucherId > 0) ? this.isFormLoading : this.isFormLoading = false)).subscribe();
  }

  public eventChanged(event: any) {
    this.selectedEvent = event.value;
    if (this.selectedEvent.id !== 0) {
      const filters = { 
        $filter: ODataFilterBuilder().eq('id', event.value.programId).toString() 
      } as IParamObj;
      this.programService.get(filters).pipe(
        filter(e => e !== null),
        takeUntil(this._subscribedSubjects$),
        map((p) => {
          this.selectedEventProgram = p[0];
          //this.cleanupTravelRecordProgramsWithUpdatedEvent();
        }),
        tap((_) => this.showProgramSearch = false)
      ).subscribe();
    } else {
      this.selectedEventProgram = null;
      this.showProgramSearch = true;
      //this.cleanupTravelRecordProgramsWithUpdatedEvent();
    }
  }

  /**
   * @todo Should this be reimplemented?
   */
  // private cleanupTravelRecordProgramsWithUpdatedEvent() {
  //   this.travelVoucherRecords.forEach((tv) => {
  //     if (tv.locationTypeId === 3) {
  //       tv.program = this.selectedEventProgram;
  //       tv.address = (this.selectedEventProgram) ? this.selectedEventProgram.address : null;
  //     }
  //   });
  // }

  private getEventTypes() {
    this.eventTypeService.get().pipe(
      filter(r => r !== null),
      takeUntil(this._subscribedSubjects$),
      map((eventTypes) => {
        this.eventTypes = eventTypes;
      })).subscribe();
  }

  private getTravelLocationTypes() {
    this.travelLocationTypeService.get().pipe(
      filter(t => t !== null),
      takeUntil(this._subscribedSubjects$),
      map((types) => {
        this.travelLocationTypes = types;
      })).subscribe();
  }

  public locationTypeChanged(event: any, index: number) {
    if (event.value == 1) {
      //get home address
      this.travelVoucherRecords[index].address = this.currentVAUser?.homeAddress;
      this.travelVoucherRecords[index].program = null;
    } else if (event.value == 2) {
      //get work address
      this.travelVoucherRecords[index].address = this.currentWorkAddress;
      this.travelVoucherRecords[index].program = null;
    } 
    else if (event.value == 3) {
      // auto fill from Event program
      this.travelVoucherRecords[index].program = this.selectedEventProgram;
      this.travelVoucherRecords[index].address = this.selectedEventProgram?.address;
    } else {
      //no auto fill
      this.travelVoucherRecords[index].program = null;
      this.travelVoucherRecords[index].address = null;
    }
  }

  public isAddStopDisabled(): boolean {
    return (this.isRoundTrip && this.travelVoucherRecords.length === 2);
  }

  private getWorkAddress() {
    const filters = { $filter: ODataFilterBuilder().eq('regionId', this.currentVAUser?.regionId).toString() } as IParamObj;
    this.travelLocationService.get(filters).pipe(
      filter(tl => tl !== null),
      takeUntil(this._subscribedSubjects$),
      map((tl) => {
        this.currentWorkAddress = tl[0]?.address;
        this.travelVoucherRecords.forEach((tv) => {
          if (tv.locationTypeId === 2) {
            tv.address = this.currentWorkAddress;
          }
        });
      })).subscribe();
  }

  public findEventType(id: number) {
    return this.eventTypes.find(et => et.id === id)?.name;
  }

  public selectedProgram(value: IProgram, index: number) {
    this.travelVoucherRecords[index].program = value;
    this.travelVoucherRecords[index].address = value.address;
  }

  public insertRecord() {
    const tr = new TravelRecord();
    if (this.selectedEvent) {
      tr.program = this.selectedEventProgram;
      tr.address = (this.selectedEventProgram) ? this.selectedEventProgram.address : null;
    }
    this.travelVoucherRecords.push(tr);
  }

  public removeRecord(index: number) {
    this.travelVoucherRecords.splice(index, 1);
  }

  public isInputInvalid = (fieldName: string) => {
    let control = this.travelVoucherForm.controls[fieldName];
    return control.invalid;
  }

  /**
   * pattern: /^(?=(\D*\d\D*){0,15}$)-?([0-9]+)?(\.?[0-9]{1,1})?$/
   * ^ : beginning of string
   * (?=(\D*\d\D*){0,15}$): Positive Lookahead 15 chars max
   * \. : here goes a dot
   * \d{1,x} : there should be between one and x digits here
   * $ : end of the string you're testing
   * @param number The number we are testing against
   * @param precision The precision the decimal number should use
   */
  public isInvalidDecimalFormat (decimal: number, precision: number): boolean {
    if(decimal == null || decimal <= 0) return false; // skip evaluation (falls under required check)

    const validFormat = new RegExp(`^(?=(\\D*\\d\\D*){0,15}$)-?([0-9]+)?(\\.?[0-9]{1,${precision}})?$`)
    return !validFormat.test(decimal.toString());
  }

  public validDataToSubmit(): boolean {
    let isValid = true;
    this.travelVoucherRecords.forEach((tv, index) => {
      if (!tv.locationTypeId) isValid = false;
      else if (index > 0 && !tv.traveled) isValid = false;
      else if (index > 0 && (!tv.mileage || tv.mileage < 0)) isValid = false;
      else if (!tv.address) isValid = false; // when program is selected, address is provided
    })
    return isValid;
  }

  public onSubmit() {
    const request = this.buildTravelVoucherRequest();
    this.travelService.post(request).pipe(
      takeUntil(this._subscribedSubjects$),
      map((newTv) => {
        this.router.navigate(['app/travel']);
      })).subscribe();
  }

  public onUpdate() {
    const request = this.buildTravelVoucherRequest();
    this.travelService.patch(request).pipe(
      takeUntil(this._subscribedSubjects$),
      map((updatedTv) => {
        this.router.navigate(['app/travel']);
      })).subscribe();
  }

  public buildTravelVoucherRequest(): ITravelVoucherRequest {
    let travelVoucher: ITravelVoucherRequest = {
      travelVoucher: {} as ITravelVoucher,
      travelRecords: []
    } as ITravelVoucherRequest;

    travelVoucher.travelVoucher.id = this.currentTravelVoucherId;
    travelVoucher.travelVoucher.vaUserId = this.currentVAUser.id;
    travelVoucher.travelVoucher.traveled = this.travelVoucherForm.value.traveled;
    travelVoucher.travelVoucher.eventId = (this.travelVoucherForm.value.event.id === 0) ? null : this.travelVoucherForm.value.event.id;
    travelVoucher.travelVoucher.createdByUser = this.currentVAUser.username;
    travelVoucher.travelVoucher.created = (this.created) ? DateTime.fromJSDate(this.created).toFormat('yyyy-MM-dd') : DateTime.now().toFormat('yyyy-MM-dd');
    travelVoucher.travelVoucher.lastUpdatedByUser = this.currentVAUser.username;
    travelVoucher.travelVoucher.lastUpdated = DateTime.now().toFormat('yyyy-MM-dd');

    for (let index = 1; index < this.travelVoucherRecords.length; index++) {
      let travelRecord: ITravelRecord = {} as ITravelRecord;
      travelRecord.id = this.travelVoucherRecords[index].id;
      travelRecord.travelVoucherId = this.currentTravelVoucherId;
      travelRecord.eventId = (this.travelVoucherForm.value.event.id === 0) ? null : this.travelVoucherForm.value.event.id;
      travelRecord.traveled = DateTime.fromJSDate(this.travelVoucherRecords[index].traveled).toString();
      travelRecord.alpha = 'X';
      travelRecord.fromTravelLocationTypeId = this.travelVoucherRecords[index - 1].locationTypeId;
      travelRecord.fromProgramId = (this.travelVoucherRecords[index - 1].program) ? this.travelVoucherRecords[index - 1].program.id : null;
      travelRecord.from = this.travelVoucherRecords[index - 1].address;
      travelRecord.toTravelLocationTypeId = this.travelVoucherRecords[index].locationTypeId;
      travelRecord.toProgramId = (this.travelVoucherRecords[index].program) ? this.travelVoucherRecords[index].program.id : null;
      travelRecord.to = this.travelVoucherRecords[index].address;
      travelRecord.mileage = (this.travelVoucherRecords[index].mileage) ? this.travelVoucherRecords[index].mileage : 0;
      travelRecord.tolls = (this.travelVoucherRecords[index].tolls) ? this.travelVoucherRecords[index].tolls : null;
      travelRecord.comment = (this.travelVoucherRecords[index].comment) ? this.travelVoucherRecords[index].comment.trim() : null;
      if (this.isRoundTrip) {
        travelRecord.typeId = 3;
      } else {
        if (index === this.travelVoucherRecords.length) {
          travelRecord.typeId = 1;
        } else {
          travelRecord.typeId = 2;
        }
      }
      travelVoucher.travelRecords.push(travelRecord);
    }

    return travelVoucher;
  }

  ngOnDestroy(): void {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
