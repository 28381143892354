import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VAITSNRoutingModule } from './vaitsn-routing.module';
import { CoreModule } from '../core/core.module';
import { EventModule } from './pages/event/event.module';
import { TravelModule } from './pages/travel/travel.module';
import { UsersModule } from '../admin/users/users.module';

// PrimeNG Modules
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ButtonModule } from 'primeng/button';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';

// DevExtreme
import { DxModule } from '../dx.module';

//Services
import { AuthService } from '../core/services/auth/auth.service';
import { DashboardService } from './services/dashboard.service';
import { DXODataService } from './services/dx-odata.service';
import { ChildReferralService } from './services/child-referral.service';
import { VAUserService } from './services/va-user.service';
import { ReportService } from './services/report.service';
import { ClassEnvironmentScoreService } from './services/class-environment-score.service';

// App
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ASQComponent } from './pages/asq/asq.component';
import { ASQSEComponent } from './pages/asqse/asqse.component';
import { ClassScoresComponent } from './pages/class-scores/class-scores.component';
import { MChatRScoresComponent } from './pages/m-chat-rscores/m-chat-rscores.component';
import { PiccoloScoresComponent } from './pages/piccolo-scores/piccolo-scores.component';
import { TpitosComponent } from './pages/tpitos/tpitos.component';
import { ChildReferralComponent } from './pages/child-referral/child-referral.component';
import { ChildSpecificScaleComponent } from './pages/child-specific-scale/child-specific-scale.component';
import { ReportComponent } from './pages/report/report.component';
import { VAITSNComponentsModule } from './components/vaitsn-components.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ClassEnvironmentScoreComponent } from './pages/class-environment-score/class-environment-score.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ASQComponent,
    ASQSEComponent,
    ClassScoresComponent,
    MChatRScoresComponent,
    PiccoloScoresComponent,
    TpitosComponent,
    ChildReferralComponent,
    ChildSpecificScaleComponent,
    ReportComponent,
    ClassEnvironmentScoreComponent,
  ],
  imports: [
    CoreModule,
    VAITSNRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EventModule,
    TravelModule,
    VAITSNComponentsModule,
    UsersModule,
    // PrimeNG Modules
    InputTextModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    InputNumberModule,
    ToggleButtonModule,
    ButtonModule,
    // Material Modules
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatListModule,
    // DevExtreme
    DxModule
  ],
  exports: [
    CommonModule
  ],
  providers: [
    AuthService,
    DashboardService,
    VAUserService,
    ChildReferralService,
    DXODataService,
    ReportService,
    ClassEnvironmentScoreService,
  ]
})
export class VAITSNModule { }
