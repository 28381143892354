<div class="dashboard-container">
    <div>
        <div class="events-container">
            <div class="main-container">
                <div class="events-tbl-header-grid">
                    <div>
                        <h1>Events</h1>
                    </div>
                    <div *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async) || (vaUserService.hasRole(VAUserRole.Coach) | async)">
                        <vaitsn-button size="custom" customWidth="103px" customHeight="30px" (click)="newEvent()">
                            New Event
                        </vaitsn-button>
                    </div>
                </div>
                <div *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async) || (vaUserService.hasRole(VAUserRole.Supervisor) | async)"
                    class="region-dropdown">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input type="text" matInput [(ngModel)]="selectedRegion"
                            (keyup)="filterRegions(getEventValue($event))" placeholder="Region Filter"
                            [matAutocomplete]="regionAuto">
                        <mat-autocomplete #regionAuto="matAutocomplete" [displayWith]="regionDisplay"
                            (optionSelected)="onRegionChange($event)">
                            <mat-option *ngFor="let region of filteredRegions" [value]="region">
                                {{region.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div>
                    <dx-data-grid #eventReportGrid id="event-report-grid" [dataSource]="eventsReportDataSource"
                        [dateSerializationFormat]="null" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [columnAutoWidth]="true" [showBorders]="true">

                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

                        <dxo-filter-row [visible]="true"></dxo-filter-row>

                        <dxi-column cssClass="no-border" dataField="id" dataType="number" [width]="70" alignment="left"></dxi-column>
                        <dxi-column cssClass="no-border" dataField="eventDate" dataType="date" caption="Date" [width]="250" 
                            [allowFiltering]="(vaUserService.hasRole(VAUserRole.Admin) | async) || (vaUserService.hasRole(VAUserRole.Supervisor) | async)"
                            [filterValue]="defaultDates" [selectedFilterOperation]="'between'" sortOrder="desc">
                        </dxi-column>
                        <dxi-column cssClass="no-border" dataField="mainPresenter" dataType="string"
                            caption="Coach">
                        </dxi-column>
                        <dxi-column cssClass="no-border" dataField="eventType" dataType="string" caption="Type">
                        </dxi-column>
                        <dxi-column cssClass="no-border" caption="" [allowFiltering]="false" [allowSorting]="false" 
                            [width]="70" cellTemplate="view-row" alignment="right">
                        </dxi-column>
                        <div *dxTemplate="let row of 'view-row'">
                            <div>
                                <div class="grid-row-edit"
                                    [ngClass]="{'disabled': !(vaUserService.hasRole(VAUserRole.Admin) | async) && !(vaUserService.hasRole(VAUserRole.Coach) | async)}"
                                    (click)="editEvent($event, row.data.id)">
                                    Edit
                                </div>
                            </div>
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>
