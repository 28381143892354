import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Subject,
  map,
  takeUntil,
  filter,
} from 'rxjs';

import {
  ChildReferralRequest,
  IClassroom,
  ILanguage,
  IRegion,
  IChildStatus,
  IVAUser,
} from '../../models';
import { RegionService } from '../../services/region.service';
import { ChildStatusService } from '../../services/child-status.service';
import { LanguageService } from '../../services/language.service';
import { ChildReferralService } from '../../services/child-referral.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { VAUserRole, VAUserPermission, VAUserService } from '../../services/va-user.service';

@Component({
  selector: 'app-child-referral',
  templateUrl: './child-referral.component.html',
  styleUrls: ['./child-referral.component.scss']
})

export class ChildReferralComponent implements OnInit, OnDestroy {
  public VAUserRole = VAUserRole;
  public VAUserPermission = VAUserPermission;
  private _associatedVAUserSubj$ = new Subject<boolean>();
  private currentVAUser: IVAUser = null;
  public formGroup: FormGroup = null;
  private formDataAsRequest: ChildReferralRequest = null;
  private _subscribedSubjects$ = new Subject<boolean>();
  public regions: Array<IRegion> = [];
  public filteredRegions: Array<IRegion> = [];
  public childStatus: Array<IChildStatus> = [];
  public languages: Array<ILanguage> = [];
  public homeLanguage: string;
  public YesNos: Array<any> = [];
  public ChildGenders: Array<any> = [];
  public YesNoNulls: Array<any> = [];
  public regionFilter: number = null;

  constructor(
    public vaUserService: VAUserService,
    private regionService: RegionService,
    private childStatusService: ChildStatusService,
    private languageService: LanguageService,
    private childReferralService: ChildReferralService,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getVAUserDetail();
    this.getRegions();
    this.getChildStatus();
    this.getLanguages();
    this.getYesNo();
    this.getChildGender();
    this.getYesNoNull();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter((c) => c !== null),
      takeUntil(this._associatedVAUserSubj$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.formGroup.patchValue({ User: vaUser.firstName + ' ' + vaUser.lastName });
          this.formGroup.patchValue({ RegionId: vaUser.regionId });
          this.regionFilter = vaUser.regionId;
        }
      })
    ).subscribe();
  }

  initForm() {
    this.formGroup = new FormGroup({
      Created: new FormControl({ value: new Date(), disabled: true }),
      User: new FormControl(null, [Validators.required]),
      DOB: new FormControl('', [Validators.required]),
      AgeMonth: new FormControl('', [Validators.required]),
      RegionId: new FormControl(null, [Validators.required]),
      City: new FormControl('', [Validators.required]),
      ZipCode: new FormControl('', [Validators.required]),
      HomeLanguage: new FormControl('', [Validators.required]),
      HomeLanguage2: new FormControl('', [Validators.required]),
      ProgramId: new FormControl('', [Validators.required]),
      ClassroomId: new FormControl('', [Validators.required]),
      ClassStudentCount: new FormControl('', [Validators.required]),
      NeedsInterpreter: new FormControl('', [Validators.required]),
      NeedsFosterCare: new FormControl('', [Validators.required]),
      Race: new FormControl('', [Validators.required]),
      IsHispanic: new FormControl('', [Validators.required]),
      Gender: new FormControl('', [Validators.required]),
      IsIFSP: new FormControl('', [Validators.required]),
      IFSPSvc: new FormControl({ value: '', disabled: true }),
      Diagnosis: new FormControl(''),
      Medications: new FormControl(''),
      Allergies: new FormControl(''),
      Referral: new FormControl('', [Validators.required]),
      OtherAgencies: new FormControl(''),
      HasExpulsionRisk: new FormControl('', [Validators.required]),
      ReceivesSubsidy: new FormControl('', [Validators.required]),
      StartedAttending: new FormControl('', [Validators.required]),
      HasAdaptiveBehavior: new FormControl('', [Validators.required]),
      HasAffect: new FormControl('', [Validators.required]),
      HasAggression: new FormControl('', [Validators.required]),
      HasAttachment: new FormControl('', [Validators.required]),
      HasAutonomy: new FormControl('', [Validators.required]),
      HasCompliance: new FormControl('', [Validators.required]),
      HasInteraction: new FormControl('', [Validators.required]),
      HasSelfRegulation: new FormControl('', [Validators.required]),
      HasSocialCommunication: new FormControl('', [Validators.required]),
      ReferralNotes: new FormControl(''),
      ReferralAgency: new FormControl(''),
      OtherReferralSource: new FormControl(''),
      StatusId: new FormControl('', [Validators.required]),
    });
  }

  private getRegions() {
    this.regionService.get().pipe(
      takeUntil(this._subscribedSubjects$),
      map((regions) => {
        this.regions = regions;
        this.filteredRegions = regions;
      })).subscribe();
  }

  public filterRegions(searchString: string) {
    this.filteredRegions = this.regions.filter(region => region.name.includes(searchString) || region.code.includes(searchString));
  }

  public regionDisplay = (region: IRegion): string => region.name;

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  /**
   * On select coach,
   * - set user and user's region
   * - if user's region is different than previous user's region,
   * will trigger 'app-search-program-classroom-odata' to reset prog/class inputs
   */
  public selectedVAUser(value: IVAUser) {
    this.currentVAUser = value;
    this.formGroup.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName });
    this.formGroup.patchValue({ RegionId: this.currentVAUser.regionId });
    this.regionFilter = this.currentVAUser.regionId;
  }

  /**
   * When `app-search-program-classroom-odata` component outputs selection,
   * set selected { Classroom + associated Program } values in this form
   * 
   * note: if search result is null
   * that likely means newly selected VAUser's region doesn't contain the currently selected prog/class
   * so reset prog/class fields
   */
  public selectedProgramClassroom(value: IClassroom) {
    console.debug('selectedProgramClassroom', JSON.stringify(value));
    if(value != null)
    {
      this.formGroup.patchValue({
        ProgramId: value.programId ?? null,
        ClassroomId: value.id,
      });
    }
    else {
      this.formGroup.controls['ProgramId'].reset();
      this.formGroup.controls['ClassroomId'].reset();
    }
  }

  private getChildStatus() {
    this.childStatusService.get().pipe(
      map((childStatus) => {
        this.childStatus = childStatus;
      })
    ).subscribe();
  }

  private getLanguages() {
    this.languageService.get().pipe(
      map((languages) => {
        this.languages = languages;
      })
    ).subscribe();
  }

  private getYesNo() {
    this.YesNos = [
      { YesNoID: 1, YesNoCD: 'No', boolValue: false },
      { YesNOID: 2, YesNoCD: 'Yes', boolValue: true }
    ];
  }

  private getYesNoNull() {
    this.YesNoNulls = [
      { YesNoNullID: 1, YesNoNullCD: 'No', boolValue: false },
      { YesNoNullID: 2, YesNoNullCD: 'Yes', boolValue: true },
      { YesNoNullID: 3, YesNoNullCD: 'N/A', boolValue: null }
    ];
  }

  private getChildGender() {
    this.ChildGenders = [
      { ChildGenderID: 1, ChildGenderCD: 'Male' },
      { ChildGenderID: 2, ChildGenderCD: 'Female' }
    ];
  }

  getDisabledValue(event: any) {
    if (event.value.YesNoCD == 'Yes') {
      this.formGroup.controls['IFSPSvc'].enable();
    }
    else {
      this.formGroup.controls['IFSPSvc'].disable();
    }
  }

  public isInputValid = (formControlName: string): boolean => (!this.formGroup.get(formControlName)?.valid && (this.formGroup.get(formControlName).dirty || (this.formGroup.get(formControlName).touched)));

  /**
   * On click form Submit button:
   * 1. check if valid
   * 2. if not valid, cancel submit
   * 3. if valid, build request from form data
   * 4. post form data request
   * 5. on success, display success message + generated Child.Code
   */
  public onSubmit() {
    this.homeLanguageAppend();
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      console.warn(this.formGroup.value);
    } else {
      this.buildRequestFromFormData();
      this.childReferralService.post(this.formDataAsRequest)
        .subscribe({
          next: (n) => {
            this.toast.success("Form submitted successfully.");
            this.toast.custom({
              severity: 'info',
              summary: 'New Child',
              detail: `New child created with code: ${n.code} \n Please note this code for future reference.`,
              sticky: true,
            });
            this.router.navigate(['app/dashboard']);
          }
        });
    }
  }

  buildRequestFromFormData() {
    this.formGroup.patchValue({ User: this.currentVAUser.id });
    this.formDataAsRequest = {
      DOB: this.formGroup.controls['DOB'].value,
      AgeMonth: this.formGroup.controls['AgeMonth'].value,
      VAUserId: this.formGroup.controls['User'].value,
      RegionId: this.formGroup.controls['RegionId'].value,
      City: this.formGroup.controls['City'].value,
      ZipCode: this.formGroup.controls['ZipCode'].value.toString(),
      HomeLanguage: this.homeLanguage,
      ClassroomId: this.formGroup.controls['ClassroomId'].value,
      ProgramId: this.formGroup.controls['ProgramId'].value,
      ClassStudentCount: this.formGroup.controls['ClassStudentCount'].value,
      NeedsInterpreter: this.formGroup.controls['NeedsInterpreter'].value.boolValue,
      NeedsFosterCare: this.formGroup.controls['NeedsFosterCare'].value.boolValue,
      Race: this.formGroup.controls['Race'].value,
      IsHispanic: this.formGroup.controls['IsHispanic'].value.boolValue,
      Gender: this.formGroup.controls['Gender'].value,
      IsIFSP: this.formGroup.controls['IsIFSP'].value.boolValue,
      IFSPSvc: this.formGroup.controls['IFSPSvc'].value,
      Diagnosis: this.formGroup.controls['Diagnosis'].value,
      Medications: this.formGroup.controls['Medications'].value,
      Allergies: this.formGroup.controls['Allergies'].value,
      Referral: this.formGroup.controls['Referral'].value,
      OtherAgencies: this.formGroup.controls['OtherAgencies'].value,
      HasExpulsionRisk: this.formGroup.controls['HasExpulsionRisk'].value.boolValue,
      ReceivesSubsidy: this.formGroup.controls['ReceivesSubsidy'].value.boolValue,
      StartedAttending: this.formGroup.controls['StartedAttending'].value,
      HasAdaptiveBehavior: this.formGroup.controls['HasAdaptiveBehavior'].value.boolValue,
      HasAffect: this.formGroup.controls['HasAffect'].value.boolValue,
      HasAggression: this.formGroup.controls['HasAggression'].value.boolValue,
      HasAttachment: this.formGroup.controls['HasAttachment'].value.boolValue,
      HasAutonomy: this.formGroup.controls['HasAutonomy'].value.boolValue,
      HasCompliance: this.formGroup.controls['HasCompliance'].value.boolValue,
      HasInteraction: this.formGroup.controls['HasInteraction'].value.boolValue,
      HasSelfRegulation: this.formGroup.controls['HasSelfRegulation'].value.boolValue,
      HasSocialCommunication: this.formGroup.controls['HasSocialCommunication'].value.boolValue,
      ReferralNotes: this.formGroup.controls['ReferralNotes'].value,
      ReferralAgency: this.formGroup.controls['ReferralAgency'].value,
      OtherReferralSource: this.formGroup.controls['OtherReferralSource'].value,
      StatusId: this.formGroup.controls['StatusId'].value,
    };
  }

  homeLanguageAppend() {
    var re = /,Other/gi;
    var re2 = /Other/gi;
    var temp = this.formGroup.controls['HomeLanguage'].value.toString();
    var temp2 = this.formGroup.controls['HomeLanguage2'].value;

    if (!temp.includes('Other')) {
      this.formGroup.patchValue({ HomeLanguage2: ' ' });
      return this.homeLanguage = temp;
    }
    else if (temp.indexOf('Other') == 0) {
      var replace = temp.replace(re2, "");
      return this.homeLanguage = (replace + ' ' + temp2);
    }
    else {
      var replace = temp.replace(re, "");
      return this.homeLanguage = (replace + ',' + temp2);
    }
  }

  ngOnDestroy() {
    this._associatedVAUserSubj$.next(true);
    this._associatedVAUserSubj$.complete();
  }

}
