<div *ngIf="isNotSearching; else isSearching" class="program-class-not-searching-grid">
    <div class="program-class-col">
        <vaitsn-button color="primary" (click)="initSearchFilters()" size="square" customWidth="100%"
            customHeight="45px">
            <mat-icon class="search-icon" aria-hidden="false" aria-label="search" fontIcon="search"></mat-icon>
        </vaitsn-button>
    </div>
    <div class="program-class-col-v2">
        <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="text" matInput placeholder="Classroom"
                [value]="(currentClassroom == null ? '' : currentClassroom?.id + ' | ' + currentClassroom?.name)" readonly>
        </mat-form-field>
    </div>
</div>


<ng-template #isSearching>
    <div class="program-search-border-box">
        <div class="program-class-grid">
            <div>
                <a><mat-icon class="cancel-icon" aria-hidden="false" (click)="isNotSearching = !isNotSearching" aria-label="cancel"
                        fontIcon="cancel"></mat-icon></a>
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.programCode" placeholder="Program code" matInput
                    (keyup)="programSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.name" placeholder="Program name" matInput
                    (keyup)="programSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.address" placeholder="Program address" matInput
                    (keyup)="programSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.classroom" placeholder="Classroom name"
                    matInput (keyup)="programSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.childCode" placeholder="Child code"
                    matInput (keyup)="programSearch(getEventValue($event))">
            </div>
        </div>
        <div>
            <div *ngIf="(associatedClassPrograms$ | async)?.length <= 0" class="no-search-results-text">
                No results found. Search using the above filter fields...
            </div>
            <a *ngFor="let associatedClassProgram of associatedClassPrograms$ | async"
                (click)="onSelect(associatedClassProgram)">
                <div class="program-class-grid data">
                    <div></div>
                    <div class="program-class-data-col">
                        {{associatedClassProgram.program.code}}
                    </div>
                    <div class="program-class-data-col">
                        {{associatedClassProgram.program.name}}
                    </div>
                    <div class="program-class-data-col">
                        {{associatedClassProgram.program.address}}
                    </div>
                    <div class="program-class-data-col">
                        {{associatedClassProgram.classroom.id + ' | ' + associatedClassProgram.classroom.name}}
                    </div>
                    <div class="program-class-data-col">
                        {{associatedClassProgram.child?.code }}
                    </div>
                </div>
            </a>
        </div>
    </div>
</ng-template>