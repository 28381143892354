import { Injectable } from "@angular/core";

export class ASQSE {
    VAUserId: string;
    Created: string;
    Date: string;
    LookupAssociatedClassProgramChildId: number;
    AgeMonth: number;
    SEScore: number;
    CreatedByUser: string;
    LastUpdatedUser: string;
  
    constructor() {
        this.VAUserId = null;
        this.Created = new Date().toDateString();
        this.Date = new Date().toDateString();
        this.LookupAssociatedClassProgramChildId = null;
        this.AgeMonth = null;
        this.SEScore = null;
        this.CreatedByUser = null;
        this.LastUpdatedUser = null;
    }
  }