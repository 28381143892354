import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, switchMap, of } from 'rxjs';
import { UserRole } from '../../shared/constants';
import { UserService } from '../services/user/user.service';
import { AuthGuard } from './auth.guard';
import { VAUserRole, VAUserService } from '../../vaitsn/services/va-user.service';

@Injectable()
export class AdminGuard implements CanLoad {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private vaUserService: VAUserService,
    private authGuard: AuthGuard
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.checkRoleForRoute(route, segments);
  }

  private checkRoleForRoute(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> {
    return this.authGuard.canLoad(route, segments).pipe(
      switchMap((successOrUrlTree: boolean | UrlTree) => {
        if (successOrUrlTree instanceof UrlTree)
          return of(successOrUrlTree as UrlTree);
        return this.vaUserService.currentAssociatedVAUser$.pipe(
          map((vaUser) => {
            if (this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) return true;
            return this.router.createUrlTree(['/forbidden']);
          })
        );
      })
    );
  }
}
