import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// App
//import { EventReportPreviewComponent } from './event-report-preview/event-report-preview.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventCoachingComponent } from './event-coaching/event-coaching.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { EventTrainingComponent } from './event-training/event-training.component';

const routes: Route[] = [
    {
        path: 'details/:eventId',
        component: EventDetailsComponent,
    },
    {
        path: 'coaching/:eventId',
        component: EventCoachingComponent,
    },
    {
        path: 'participants/:eventId',
        component: EventParticipantsComponent,
    },
    {
        path: 'training/:eventId',
        component: EventTrainingComponent,
    },
    // {
    //     path: 'preview/:eventId',
    //     component: EventReportPreviewComponent,
    // },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EventRoutingModule { }
