<div class="shadow table-border">
  <p-table
    [value]="users$ | async"
    [resizableColumns]="true"
    responsiveLayout="stack"
    [rowHover]="true"
    sortField="firstName"
  >
  <ng-template pTemplate="caption">
    <div class="d-flex justify-content-between">
      <div><h2 >User Admin</h2></div>
      <button pButton type="button"  routerLink="add"><i class="pi pi-plus me-2"></i>User</button>
    </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="firstName" pResizableColumn>
          First <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th pSortableColumn="lastName" pResizableColumn>
          Last <p-sortIcon field="lastName"></p-sortIcon>
        </th>
        <th pSortableColumn="userName" pResizableColumn>
          Email <p-sortIcon field="userName"></p-sortIcon>
        </th>
        <th pSortableColumn="phoneNumber" pResizableColumn>
          Phone Number <p-sortIcon field="phoneNumber"></p-sortIcon>
        </th>
        <th pResizableColumn>Role(s)</th>
        <th pResizableColumn>Invite Status</th>
        <th pResizableColumn></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>
          <span class="p-column-title">First Name</span>{{ user.firstName }}
        </td>
        <td>
          <span class="p-column-title">Last Name</span>{{ user.lastName }}
        </td>
        <td><span class="p-column-title">Email</span>{{ user.userName }}</td>
        <td style="width: 145px">
          <span class="p-column-title">Phone</span>{{ user.phoneNumber }}
        </td>
        <td style="width: 150px">
          <span class="p-column-title">Role(s)</span>
          <ng-container *ngFor="let role of user.roles; last as last">
            <div id={{role}}>
            <ng-container *ngIf="!last; else lastRole">
              {{ role.charAt(0).toUpperCase()+ role.slice(1) }},&nbsp;
            </ng-container>
            <ng-template #lastRole>
              {{ role.charAt(0).toUpperCase()+ role.slice(1) }}
            </ng-template>
            </div>
          </ng-container>
        </td>
        <td style="width: 130px">
          <span class="p-column-title">Invite Status</span>
          <ng-container *ngIf="user.emailConfirmed && user.emailSent"
            >Confirmed</ng-container
          >
          <ng-container *ngIf="!user.emailConfirmed && user.emailSent"
            >Sent</ng-container
          >
          <ng-container *ngIf="!user.emailSent">Not sent</ng-container>
        </td>
        <td style="width: 148px">
          <button
          type="button"
          pButton
          pRipple
          tooltipPosition="top"
          pTooltip="Edit"
          class="p-button-text p-button-rounded p-button-plain p-mr-2"
          icon="bi bi-pencil-square"
          routerLink="/app/admin/users/{{ user.id }}/edit"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          pTooltip="Delete"
          tooltipPosition="top"
          class="p-button-text p-button-rounded p-button-plain p-mr-2"
          icon="bi bi-trash3-fill"
          (click)="deleteUser(user.id)"
        ></button>
          <button
            type="button"
            pButton
            pRipple
            class="p-button-text p-button-rounded p-button-plain p-mr-2"
            icon="bi bi-envelope-fill"
            (click)="resendEmail(user.id)"
            [disabled]="user.emailConfirmed && user.emailSent"
            pTooltip="Send Invite Email"
            tooltipPosition="top"
          >
          </button>
          <button
          type="button"
          pButton
          pRipple
          class="p-button-text p-button-rounded p-button-plain p-mr-2"
          icon="bi bi-arrow-repeat"
          (click)="forceResetPassword(user.id)"
          pTooltip="Reset Password"
          tooltipPosition="left"
          >
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Delete User" [(visible)]="displayModal" [modal]="true" [style]="{width: '400px'}" [baseZIndex]="5000"
    [draggable]="false" [resizable]="false">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column">
      <div class="text-center">
        <p>Are you sure you want to delete this user?<br>This user will be deleted immediately. You can't undo this action.</p>
      </div>
    </div>
  </ng-template>
  
  <ng-template let-message pTemplate="footer">
    <button pButton type="button" (click)="onReject()" label="Cancel" class="p-button-primary p-button-text"></button>
    <button pButton type="button" (click)="onConfirm()" label="Delete" class="p-button-danger p-button-text"></button>
  </ng-template>
</p-dialog>