import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { DialogService } from 'primeng/dynamicdialog';
import { ExcelReaderService } from './modules/vaitsn/services/excel-reader.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

// DevExtreme
import { DxModule } from './modules/dx.module';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthFlowModule } from './modules/auth-flow/auth-flow.module';
import { CoreModule } from './modules/core/core.module';
import { environment } from 'src/environments/environment';
import { VAITSNModule } from './modules/vaitsn/vaitsn.module';
import { VAITSNComponentsModule } from './modules/vaitsn/components/vaitsn-components.module';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AuthFlowModule,
    BrowserAnimationsModule, // required animations module
    ToastModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    VAITSNModule,
    VAITSNComponentsModule,
    MsalModule.forRoot( 
      new PublicClientApplication({
        auth: {
          clientId: environment.azureADAuthProvider.clientId, // Application (client) ID from the app registration
          authority: `https://login.microsoftonline.com/${environment.azureADAuthProvider.tenantId}`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: '/'// This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        }
      }), 
      null, 
      null
    ),
    // Material Modules
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    // DevExtreme
    DxModule
  ],
  declarations: [AppComponent],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent,
  ],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    ExcelReaderService
  ]
})
export class AppModule {}