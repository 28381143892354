import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { IUser2FAChangeRequest } from 'src/app/modules/core/models/user-2fa-change-request.interface';
import { AddUserService } from '../../services/add-user/add-user.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  providers: [AddUserService],
})
export class AddUserComponent {
  public submitting: boolean = false;

  constructor(
    private addUserSvc: AddUserService,
    private router: Router,
    private location: Location
  ) {
  }

  public onSubmit(userChangeRequest: IUser2FAChangeRequest): void {
    const user = userChangeRequest.user;

    this.submitting = true;
    if (!user.lastPasswordReset){
      user.lastPasswordReset = new Date(Date.now());
    }
    this.addUserSvc.create(user).pipe(
      tap(() => this.router.navigateByUrl('/app/admin/users')),
      finalize(() => this.submitting = false)
    ).subscribe();
  }

  public onCancel(): void {
    this.location.back(); 
  }
}
