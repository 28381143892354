import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { MessageService, Message } from 'primeng/api';
import { IApiResponse } from '../models/api-response.interface';
import { LoginResponseStatus } from '../services/auth/auth.service';
import { AuthAbstractService } from '../services/auth/auth-abstract.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private messages: MessageService, private injector: Injector) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((res: HttpErrorResponse) => {
                const apiError = res.error as IApiResponse;
                const toastMessage: Message = {severity: 'error', summary: apiError?.message ?? 'ERROR'}
                if (!res.ok) {
                    switch (res.status) {
                        case LoginResponseStatus.PasswordExpired:
                            this.messages.add(toastMessage);
                            return next.handle(request);                                                 
                        
                        case 400:
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case 401:
                            toastMessage.summary = 'User authentication expired, please log back in.',
                            this.messages.add(toastMessage);
                            this.injector.get(AuthAbstractService).logout().subscribe();
                            return throwError(() => new Error(apiError?.message));

                        case 403:
                            toastMessage.summary = 'User requested a forbidden action, please log back in to verify user account and permissions.';
                            this.messages.add(toastMessage);
                            this.injector.get(AuthAbstractService).logout().subscribe();
                            return next.handle(request);
                            
                        case 404:
                            toastMessage.summary = 'Not Found. Please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case 500:
                            toastMessage.summary = 'A 500 error was encountered, please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case 503:
                            toastMessage.summary = 'A 503 error was encountered, please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        default:
                            toastMessage.summary = `Unexpected error code received: ${res.status}`;
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));
                    }
                }
                return next.handle(request);
            })
        );
    }
}
