import { Injectable } from "@angular/core";
import { Observable, concatMap, filter, map, shareReplay, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IActionOdataResult, IActionResult } from "../models/interfaces/action-result.interface";
import { IParamObj, IParamQuery } from "../models/interfaces/param-query.interface";
import { IVAUser, IVAUserRequest } from "../models/interfaces/va-user.interface";
import { UserService } from "../../core/services/user/user.service";
import ODataFilterBuilder from "odata-filter-builder";
import { IUser, User } from "../../core/models/user";
import { ToastService } from "../../core/services/toast/toast.service";
import { AuthService } from "../../core/services/auth/auth.service";

export enum VAUserRole {
    Admin = "ADMIN",
    Supervisor = "SUPERVISOR",
    Coach = 'COACH'
}

export enum VAUserPermission {
    Specialist = "SPECIALIST",
    Consultant = "CONSULTANT",
    Statewide = 'STATEWIDE',
    UserManagement = 'USERMANAGEMENT'
}

@Injectable({
    providedIn: 'root'
})
export class VAUserService {
    private USER_API = `${environment.apiBaseURL}VAUser`;

    public currentAssociatedVAUser$ = this.userService.user$.pipe(filter(user => user != null), concatMap((user) => this.getAssociatedVAUser(user)), shareReplay(1));

    constructor(private http: HttpClient, private userService: UserService,
        private authService: AuthService,
        private toastService: ToastService
    ) {
    }

    private getAssociatedVAUser(user: User): Observable<IVAUser> {
        const filters = { $filter: ODataFilterBuilder('and').eq('normalizedEmail', user.userName.toUpperCase()).toString(), $expand: 'VAUserRoles($expand=Role), VAUserPermissions($expand=Permission)' } as IParamObj;
        return this.get(filters).pipe(
            map((result) => {
                var vaUser = (result as Array<IVAUser>)[0];
                if (vaUser == null) {
                    this.toastService.error('No User Found with Identity, Contact you administrator for assistance.');
                    this.authService.logout().subscribe();
                    return null;
                }
                return vaUser;
            }));
    }

    /**
     * Returns an array of vaUsers based on paramQuery
     */
    public get(params?: IParamObj): Observable<Array<IVAUser>> {
        const paramQuery: IParamQuery = (params) ? { params: params } : null;
        return this.getVAUsers(paramQuery).pipe(map((result) => result.value as Array<IVAUser>));
    }

    private getVAUsers(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/VAUsers', httpParams);
    }

    public getVaUserId( id: string): Observable <IVAUser> {
        return this.http.get<IVAUser>(environment.apiBaseURL + 'odata/VAUsers/' + id);
    }

    /**
     * Use when you have already subscribed to get the associated user
     * @param user 
     * @param role 
     * @returns if the user has the role that is passed
     */
    public userHasRole = (user: IVAUser, role: VAUserRole): boolean => user.vaUserRoles.some(uRole => uRole.role.normalizedName === role)

    /**
     * Use on DOM or in observable flows
     * @param role 
     * @returns if the user has role that is passed (as Observable)
     */
    public hasRole = (role: VAUserRole): Observable<boolean> => this.currentAssociatedVAUser$.pipe(map((user) => this.userHasRole(user, role)))

    /**
     * Use when you have already subscribed to get the associated user
     * @param user 
     * @param permission 
     * @returns if the user has the permission that is passed
     */
    public userHasPermission = (user: IVAUser, permission: VAUserPermission): boolean => user.vaUserPermissions.some(uPermission => uPermission.permission.normalizedName === permission)

    /**
     * Use on DOM or in observable flows
     * @param permission 
     * @returns if the user has permission that is passed (as Observable)
     */
    public hasPermission = (permission: VAUserPermission): Observable<boolean> => this.currentAssociatedVAUser$.pipe(map((user) => this.userHasPermission(user, permission)))

    public createNewUser(req: IVAUserRequest) {
        return this.postUser(req).pipe(map((result) => result.data as IVAUser))
    }


    private postUser(userRequestBody: IVAUserRequest): Observable<IActionResult> {
        return this.http.post<IActionResult>(
            `${this.USER_API}`,
            userRequestBody
        );
    }

    public updateUser(req: IVAUserRequest) {
        return this.putUser(req).pipe(map((result) => result.data as IVAUser))
    }

    private putUser(userRequestBody: IVAUserRequest): Observable<IActionResult> {
        return this.http.put<IActionResult>(
            `${this.USER_API}`,
            userRequestBody
        );
    }

    public deleteUser(id: string): Observable<IActionResult> {
        return this.http.delete<IActionResult>(`${this.USER_API}?id=${id}`);
    }

}
