import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { SimplePageLayoutComponent } from './layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/error-pages/not-found/not-found.component';
import { ForbiddenComponent } from './pages/error-pages/forbidden/forbidden.component';
import { UnauthorizedComponent } from './pages/error-pages/unauthorized/unauthorized.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { AuthAbstractService } from './services/auth/auth-abstract.service';
import { AuthService } from './services/auth/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { LoginGuard } from './guards/login.guard';
import { AdminGuard } from './guards/admin.guard';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DragDropModule } from 'primeng/dragdrop';
import { DocumentsByPropertyComponent } from './pages/documents-by-property/documents-by-property.component';
import { DocumentsByCategoryComponent } from './pages/documents-by-category/documents-by-category.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { DividerModule } from 'primeng/divider';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TreeSelectModule } from 'primeng/treeselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NestedSelectorComponent } from './components/nested-selector/nested-selector.component';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DocumentStatusComponent } from './components/document-status-dropdown/document-status.component';
import { CategoryStatusComponent } from './components/category-status-dropdown/category-status.component';
import { DocumentCategoryComponent } from './components/document-category-dropdown/document-category.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { HasRoleDirective } from './directives/has-role.directive';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SimplePageLayoutComponent,
    AppLayoutComponent,
    NotFoundComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    ErrorCardComponent,
    DocumentsByPropertyComponent,
    DocumentsByCategoryComponent,
    FileSelectorComponent,
    UploadDocumentsComponent,
    DocumentDetailsComponent,
    NestedSelectorComponent,
    DocumentStatusComponent,
    DocumentCategoryComponent,
    CategoryStatusComponent,
    UploadProgressComponent,
    HasRoleDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    ButtonModule,
    BreadcrumbModule,
    DialogModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    TableModule,
    DragDropModule,
    FileUploadModule,
    AutoCompleteModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    DividerModule,
    TreeSelectModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    TooltipModule,
    ChipModule,
    ConfirmPopupModule,
    ProgressBarModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  providers: [
    AdminGuard,
    AuthGuard,
    LoginGuard,
    UserManagementService,
    { provide: AuthAbstractService, useClass: AuthService },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [
    HasRoleDirective
  ]
})
export class CoreModule {}
