<div class="vaitsn-form-grid-90col">
    <div class="vaitsn-form-grid-cell">
        <div class="vaitsn-form-control">
            <mat-form-field appearance="outline" class="vaitsn-text-field">
                <input type="text" matInput (keyup)="vaUserSearch(getEventValue($event))"
                    [matAutocomplete]="coachesAuto" placeholder="Search by Name or Username" [value]="(currentvaUser == null ? '' : currentvaUser.firstName + ' ' + currentvaUser.lastName)">
                <mat-autocomplete #coachesAuto="matAutocomplete" [displayWith]="vaUserDisplay">
                    <mat-option *ngFor="let vaUser of vaUsers$ | async" (click)="onSelect(vaUser)" [value]="vaUser">
                        {{ vaUser.firstName + ' ' + vaUser.lastName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</div>