import { Injectable } from "@angular/core";

export class MChatR{
    VAUserId: string;
    AgeMonth: number;
    Created: string;
    LookupAssociatedClassProgramChildId: number;
    Observation: string;
    LowRiskScore: number;
    MediumRiskScore: number;
    HighRiskScore: number;
    ReferralAgency: string;
    CreatedByUser: string;
    LastUpdatedUser: string;
  
    constructor(){
        this.VAUserId = null;
        this.AgeMonth = null;
        this.Created = new Date().toDateString();
        this.LookupAssociatedClassProgramChildId = null;    
        this.Observation = new Date().toLocaleDateString();
        this.LowRiskScore = null;
        this.MediumRiskScore = null;
        this.HighRiskScore = null;
        this.ReferralAgency = '';
        this.CreatedByUser = null;
        this.LastUpdatedUser = null;
    }
}