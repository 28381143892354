export enum EventType {
    Coaching = 1,
    ChildCoaching = 2,
    ClassroomTraining = 3,
    ConclusionVisit = 4,
    //PostAssessmentDebrief = 5,
    TechnicalAssistance = 6,
    ClusterTraining = 7,
    DirectorForum = 8,
    CombinedTraining = 9,
    ConferencePresentation = 10,
    MarketingActivities = 11,
    Networking = 12,
    ChildConclusionVisit = 107
};

export enum EventTypeGroup {
    Coaching = 'coaching',
    ChildCoaching = 'childcoaching',
    Training = 'training',
    Other = 'other'
};

export enum EventLocationType {
    Program = "Program",
    Other = "Other"
}

export enum TravelLocationType {
    Home = 1,
    Work = 2,
    Program = 3,
    Other = 4
}

export class VAConstants {
    public static EVENT_LOCATION_TYPES: string[] = [
        EventLocationType.Program,
        EventLocationType.Other
    ];
}
