import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Subject,
  catchError,
  combineLatest,
  filter,
  takeUntil,
  throwError
} from 'rxjs';
const { DateTime, Settings } = require("luxon");

import {
  ClassEnvironmentScore,
  ILookupAssociatedClassProgramChild,
  IVAUser
} from '../../models';
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { ClassEnvironmentScoreService } from '../../services/class-environment-score.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

/** 
 * @todo fix double toaster if form submit fails.
 * @todo if clear a lookup type field, should clear form value immediately
 * @todo improve Score validation to use custom formgroup validator
 */
@Component({
  selector: 'app-class-environment-score',
  templateUrl: './class-environment-score.component.html',
  styleUrls: ['./class-environment-score.component.scss']
})
export class ClassEnvironmentScoreComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  public form: FormGroup = null;
  public isFormLoading: boolean = true;
  public observedMinDate: Date;
  public observedMaxDate: Date;
  public classRegionFilter: number = null;

  constructor(
    public vaUserService: VAUserService,
    private classScoresService: ClassEnvironmentScoreService,
    private router: Router,
    private toast: ToastService,
  ) {
    Settings.defaultZone = 'America/New_York';
  }

  ngOnInit(): void {
    this.getRequiredData().subscribe({
      next: data => {
        this.initForm();
        
        if (!this.vaUserService.userHasRole(data[0], VAUserRole.Admin)) {
          this.setSelectedCoach(data[0]);
        }

        this.isFormLoading = false;
      },
      error: err => {
        console.error(err);
        this.handlePageLoadError('Error gathering required data.');
      }
    });
  }

  /**
   * Notify user of error and return to dashboard.
   * @param err Error to log.
   */
  private handlePageLoadError(err?: string) {
    err && console.error(err);
    this.toast.error('Error loading form. Please try again.');
    this.router.navigate(['app/dashboard']);
  }

  /**
   * Initialize form with defaults.
   */
  private initForm() {
    let now = DateTime.now();
    this.observedMinDate = now.minus({years: 1}).toJSDate();
    this.observedMaxDate = now.toJSDate();

    this.form = new FormGroup({
      Coach: new FormControl(null, [Validators.required]),
      DateSubmitted: new FormControl({ value: this.observedMaxDate, disabled: true }),
      Observed: new FormControl(this.observedMaxDate, [Validators.required]),
      ClassroomId: new FormControl(null, [Validators.required]),
      PreOrPost: new FormControl(null, [Validators.required]),
      Score: new FormControl(null, [Validators.required, Validators.min(0.01), Validators.max(3)]),
    });
  }

  /**
   * Load required page data from services.
   * @returns Combined data[] or throws error if any request fails.
   * Add any additional service calls into combineLastest sources array.
   */
  private getRequiredData = () => {
    const currentUser = this.getVAUserDetail();
    return combineLatest([currentUser]);
  }

  private getVAUserDetail = () => {
    return this.vaUserService.currentAssociatedVAUser$.pipe(
      catchError(err => {
        console.error(err);
        return throwError('Error getting current user data.');
      }),
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
    );
  }

  public selectedProgramClass(value: ILookupAssociatedClassProgramChild) {
    this.form.patchValue({
      ClassroomId: value?.classroomId || null,
    });
  }

  public setSelectedCoach(coach: IVAUser) {
    this.classRegionFilter = coach.regionId;
    this.form.patchValue({ Coach: coach });
  }

  public isInputValid = (formControlName: string): boolean => (
    !this.form.get(formControlName)?.valid 
    && (this.form.get(formControlName).dirty || (this.form.get(formControlName).touched))
  );

  /**
   * pattern: /^(?=(\D*\d\D*){0,15}$)-?([0-9]+)?(\.?[0-9]{1,1})?$/
   * ^ : beginning of string
   * (?=(\D*\d\D*){0,15}$): Positive Lookahead 15 chars max
   * \. : here goes a dot
   * \d{1,x} : there should be between one and x digits here
   * $ : end of the string you're testing
   * @param number The number we are testing against
   * @param precision The precision the decimal number should use
   * @todo test if skips for min/max errors same as required
   */
  public isInvalidDecimalFormat(formControlName: string, precision: number): boolean {
    const control = this.form.get(formControlName);
    if(!control?.value) { // Skip evaluation (falls under required/min checks)
      return false; 
    }

    const validFormat = new RegExp(`^(?=(\\D*\\d\\D*){0,15}$)-?([0-9]+)?(\\.?[0-9]{1,${precision}})?$`)
    return !validFormat.test(control.value.toString());
  }

  public onSubmit = () => {
    //console.debug(this.form.getRawValue());
    this.form.markAllAsTouched();
    if (!this.form.valid || this.isInvalidDecimalFormat('Score', 2)) {
      console.warn("Form not valid yet: ", this.form.value);
    } else {
      this.postForm();
    }
  }

  private postForm = () => {
    let formPayload: ClassEnvironmentScore = {
      vaUserId: this.form.controls['Coach'].value.id,
      observed: DateTime.fromJSDate(this.form.controls['Observed'].value).toFormat('yyyy-MM-dd'),
      classroomId: this.form.controls['ClassroomId'].value,
      preOrPost: this.form.controls['PreOrPost'].value,
      score: this.form.controls['Score'].value,
    };

    this.classScoresService.postForm(formPayload)
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        },
        error: (e) => {
          this.toast.error("Form failed to submit. Please check the data.");
        }
      });
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
