<div class="d-flex flex-row justify-content-center align-item-start">
  <div class="card shadow-sm" style="width: 500px">
    <div class="card-body">
      <h2 class="card-title">{{ title }}</h2>
      <p>{{ message }}</p>
      <div class="d-flex flex-row align-items-bottom justify-content-end">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
