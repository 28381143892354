import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { ASQ } from "../models/interfaces/asq3.interface";
import { ASQSE } from "../models/interfaces/asqse.interface";


@Injectable({
    providedIn: 'root'
})

export class ASQSEService {

    constructor (        
        private http: HttpClient,
    ) { }

    /**
    * Post asqse form data to api
    */
    // public postASQSEForm ( ASQSEFormSubmittedData : any ) {
    //     return this.http.post<ASQSE>(environment.apiBaseURL + "Asqse/CreateASQSE", ASQSEFormSubmittedData).subscribe();
    // }
    public post(event: ASQSE): Observable<IActionResult> {
        return this.postASQSEForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postASQSEForm( ASQSEFormSubmittedData: ASQSE): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "Asqse/CreateASQSE", ASQSEFormSubmittedData);
    }

}