import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IEvent} from "../models/interfaces/event.interface";
import { environment } from "src/environments/environment";
import { Observable, map} from "rxjs";
import { IActionOdataResult } from "../models/interfaces/action-result.interface";
import { IParamObj, IParamQuery } from "../models/interfaces/param-query.interface";
import { IEventParticipantStaffTitle } from "../models/interfaces/event-participant.interface";

@Injectable({
    providedIn: 'root'
})
export class EventParticipantStaffTitleService {
    constructor(private http: HttpClient) { }

    /**
     * Returns an array of event participant staff titles based on http query params
     */
    public get(params?: IParamObj): Observable<Array<IEventParticipantStaffTitle>> {
        const paramQuery: IParamQuery = (params) ? { params: params } : null;
        return this.getStaffTitles(paramQuery).pipe(map((result) => result.value as Array<IEventParticipantStaffTitle>));
    }

    private getStaffTitles(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/EventParticipantStaffTitles', httpParams);
    }
}