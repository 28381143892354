import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TreeSelect } from 'primeng/treeselect';
import { ISelectionNodeData, SelectionNode } from '../../models/selection-node';

@Component({
    selector: 'app-nested-selector',
    styleUrls: ['./nested-selector.component.scss'],
    template: `
    <p-treeSelect #treeSelect 
        class="col-6 nested-selector" 
        placeholder="Select Item" 
        appendTo="body" 
        selectionMode="single"
        [options]="options"
        (onNodeSelect)="onValueChange($event.node)" 
        (onHide)="treeSelect.resetExpandedNodes()" 
        >
        <ng-template pTemplate="value" let-value>
            <button type="button" pButton [icon]="icon" [label]="label" class="p-button-text" style="outline: auto; color: #6c757d !important;"></button>
        </ng-template>
    </p-treeSelect>
    `
})
export class NestedSelectorComponent {
    @Input() options: SelectionNode[];
    @Input() label: string;
    @Input() icon: string;
    @Output() selectionChange: EventEmitter<ISelectionNodeData> = new EventEmitter<ISelectionNodeData>();
    @ViewChild('treeSelect') treeSelect!: TreeSelect;
    selected: SelectionNode;

    constructor() { }

    onValueChange(node: SelectionNode) {
        this.selectionChange.emit(node.data);
        this.treeSelect.writeValue(null);
        this.treeSelect.resetExpandedNodes();
    }
}