import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";
import { IActionResult } from "../models/interfaces/action-result.interface";
import { ASQ } from "../models/interfaces/asq3.interface";

@Injectable({
    providedIn: 'root'
})


export class ASQService {

    constructor (
        private http: HttpClient,
    ) { }

    public post(event: ASQ): Observable<IActionResult> {
        return this.postASQForm( event ).pipe(map((result) => result.data as IActionResult));
    }

    public postASQForm( AsqFormSubmittedData: ASQ): Observable<IActionResult> {
        return this.http.post<IActionResult>(environment.apiBaseURL + "Asq/CreateASQ", AsqFormSubmittedData);
    }


}