import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// App
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ASQComponent } from './pages/asq/asq.component';
import { ASQSEComponent } from './pages/asqse/asqse.component';
import { ClassScoresComponent } from './pages/class-scores/class-scores.component';
import { MChatRScoresComponent } from './pages/m-chat-rscores/m-chat-rscores.component';
import { PiccoloScoresComponent } from './pages/piccolo-scores/piccolo-scores.component';
import { TpitosComponent } from './pages/tpitos/tpitos.component';
import { ChildReferralComponent } from './pages/child-referral/child-referral.component';
import { ChildSpecificScaleComponent } from './pages/child-specific-scale/child-specific-scale.component';
import { ReportComponent } from './pages/report/report.component';
import { AdminGuard } from '../core/guards/admin.guard';
import { ClassEnvironmentScoreComponent } from './pages/class-environment-score/class-environment-score.component';

const routes: Route[] = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'asq',
    component: ASQComponent,
  },
  {
    path: 'asqse',
    component: ASQSEComponent,
  },
  {
    path: 'child-specific-scale',
    component: ChildSpecificScaleComponent,
  },
  {
    path: 'child-referral',
    component: ChildReferralComponent,
  },
  {
    path: 'class-scores',
    component: ClassScoresComponent,
  },
  {
    path: 'm-chat-r',
    component: MChatRScoresComponent,
  },
  {
    path: 'piccolo-scores',
    component: PiccoloScoresComponent,
  },
  {
    path: 'tpitos',
    component: TpitosComponent,
  },
  {
    path: 'class-environment-score',
    component: ClassEnvironmentScoreComponent,
  },
  {
    path: 'users',
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./pages/users/users.module').then((t) => t.UsersModule)
  },
  {
    path: 'travel',
    loadChildren: () =>
      import('./pages/travel/travel.module').then((t) => t.TravelModule)
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./pages/event/event.module').then((m) => m.EventModule)
  },
  {
    path: 'reports',
    component: ReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VAITSNRoutingModule { }
