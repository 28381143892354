<body>
  <div class="card-body">
    <form id="forgot-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <p id="forgot-label">
        Forgot Password
      </p>

      <p id="forgot-msg">
        {{"Enter the email associated with your account and we’ll send you a link to help you change your password."}}
      </p>

      <div class="vaitsn-form-grid-cell vaitsn-field-normal">
        <mat-label>Email</mat-label>
        <div class="vaitsn-form-control">
          <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="email" matInput id="usernameInput" formControlName="userName" />
            <mat-error *ngIf="
          form.controls['userName'].invalid &&
          (form.controls['userName'].dirty ||
            form.controls['userName'].touched)" class="vaitsn-invalid-container">
              Email required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <vaitsn-button type="submit" color="primary" size="large" (clicked)="onSubmit()">Submit</vaitsn-button>

      <a id="back-to-login" class="vaitsn-hyperlink" routerLink="/login">Back to Login</a>
    </form>
  </div>
</body>