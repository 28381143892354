import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject, takeUntil, tap, combineLatest } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { IEventPresenter, IParamObj, IVAUser, IEventDetailTopic, IEventDetails } from 'src/app/modules/vaitsn/models';
import { EventType } from '../../../vaitsn-constants';
import { EventService } from '../../../services/event.service';
import { VAUserPermission, VAUserRole, VAUserService } from '../../../services/va-user.service';

/**
 * Custom cross-validator for entire form.
 * Require either pre/post or inc/noinc.
 * @param control `this.eventTrainingForm` (FormGroup)
 * @returns `measurements` validation error if invalid pre/post or inc/noinc. Else, null (FormGroup is valid).
 */
export const measurementsValidator: ValidatorFn = (
  control: AbstractControl, // FormGroup aka eventTrainingForm
): ValidationErrors | null => {
  let formGroup = control as FormGroup;

  if(!formGroup) return { measurements: true };

  let preK = formGroup.get('preK');
  let postK = formGroup.get('postK');
  let regKm2 = formGroup.get('regKm2');
  let regKmPre = formGroup.get('regKmPre');

  //Valid if: clean, or only pre/post or inc/noinc
  let valid = 
  (
    !(preK.touched || preK.dirty || postK.touched || postK.dirty || regKm2.touched || regKm2.dirty || regKmPre.touched || regKmPre.dirty)
    || (preK.value != null && postK.value != null && regKm2.value == null && regKmPre.value == null) 
    || (preK.value == null && postK.value == null && regKm2.value != null && regKmPre.value != null)
  );
  
  return valid ? null : { measurements: true };
};

/**
 * @todo Prevent editing event type and coach during edit
 * @todo clear topic values if change event type or coach during create
 */
@Component({
  selector: 'app-event-training',
  templateUrl: './event-training.component.html',
  styleUrls: ['./event-training.component.scss']
})
export class EventTrainingComponent {
  VAUserPermission = VAUserPermission;
  VAUserRole = VAUserRole;
  public currentEventId: number = 0;
  public isFormLoading: boolean = true;
  public currentMainPresenter: IVAUser = null;
  private _currentVAUser: IVAUser = null;
  public eventTrainingForm: FormGroup = null;
  private _subscribedSubjects$ = new Subject<boolean>();
  public vaUsers: Array<IVAUser> = [];
  public additionalPresenters: Array<IVAUser> = [];
  public eventTopics: IEventDetailTopic[] = [];
  private _EventType = EventType;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private eventService: EventService,
    public vaUserService: VAUserService,
  ) { }

  /**
   * Load required data from services
   * Determine which topics should be shown
   * Init dynamic form
   */
  public ngOnInit() {
    this.currentEventId = +this.activatedRoute.snapshot.paramMap.get('eventId');

    this.getRequiredData().subscribe({
      next: value => {
        this.vaUsers = value[0];
        this._currentVAUser = value[1];

        this.filterEventDetailTopics();

        this.initForm();
      },
      error: err => {
        this.handlePageLoadError();
      }
    });
  }

  /**
   * Notify user of error
   * Return to previous step
   * @param err 
   */
  private handlePageLoadError(err?: string) {
    err && console.error(err);
    this.router.navigate(['app/event/details/', this.currentEventId]);
  }

  /**
   * Get required data for form from services
   * 1: Get list of all VAUsers. Used to populate existing Additional Presenters data / select new ones
   * 2: Get current VAUser
   * @returns 
   * @todo if current user is needed (TBD), maybe use take1 and forkjoin instead; else can make single observable
   */
  private getRequiredData = () => {
    const params = { $expand: 'VAUserRoles($expand=Role), VAUserPermissions($expand=Permission)' } as IParamObj;
    const getVAUsers = this.vaUserService.get(params);
    const getCurrentUser = this.vaUserService.currentAssociatedVAUser$.pipe(takeUntil(this._subscribedSubjects$));
    return combineLatest([getVAUsers, getCurrentUser]);
  }

  /**
   * Determine which topics to show based on event type (training) + main presenter's coach type
   * @returns 
   */
  private filterEventDetailTopics() {
    const eventObj = this.eventService.$currentEvent.value;

    this.currentMainPresenter = this.vaUsers.find(u => u.id === eventObj.event.mainPresenterId);
    if (!this.currentMainPresenter) {
      return this.handlePageLoadError('Coach data not found.');
    }

    let specialTrainings = [this._EventType.ClusterTraining, this._EventType.CombinedTraining];
    let coachIsITBC = this.vaUserService.userHasPermission(this.currentMainPresenter, VAUserPermission.Consultant);
    let coachIsITS = this.vaUserService.userHasPermission(this.currentMainPresenter, VAUserPermission.Specialist);

    this.eventTopics = this.eventService.EVENT_DETAIL_TOPICS.filter(topic =>
      (topic.showIfITBC === coachIsITBC || topic.showIfITS === coachIsITS)
      && (
        // Show if Cluster or Combined Trainings
        (specialTrainings.includes(eventObj.eventType.id) && topic.showIfClusterOrCombinedTraining === true)
        // Show if other Trainings
        || (!specialTrainings.includes(eventObj.eventType.id) && topic.showIfTraining)
      )
    );

    this.eventTopics.sort((a, b) =>{
      if(a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1;
      else if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
      else return 0;
    });
  }

  /**
   * Init form with default values
   * Then patch form if Editing an event
   * @todo move get full event details refreshCurrentEvent() to required data gathering step
   */
  private initForm() {
    const eventObj = this.eventService.$currentEvent.value;
    const group: any = {};

    // Add static non-checkbox fields to form group
    group['preK'] = new FormControl<number|null>(null, [Validators.min(0), Validators.max(100)]);
    group['postK'] = new FormControl<number|null>(null, [Validators.min(0), Validators.max(100)]);
    group['regKm2'] = new FormControl<number|null>(null, [Validators.min(0)]);
    group['regKmPre'] = new FormControl<number|null>(null, [Validators.min(0)]);
    group['otopic2'] = new FormControl<string|null>(null);

    // Add dynamic topics to form group (checkboxes)
    this.eventTopics.forEach(topic => {
      group[topic.entityName] = new FormControl(false);
    });

    // Add validator(s) if event type != DirectorForum
    // Require eval avg and measurements
    if(eventObj.eventType?.id === EventType.DirectorForum) {
      group['evalAvg'] = new FormControl<number|null>(null, [Validators.min(1), Validators.max(5)]);
      this.eventTrainingForm = new FormGroup(group);
    } else {
      group['evalAvg'] = new FormControl<number|null>(null, [Validators.required, Validators.min(1), Validators.max(5)]);
      this.eventTrainingForm = new FormGroup(group, { validators: measurementsValidator });
    }
    
    if (this.currentEventId > 0 && !this.eventService.$currentEvent.value?.event?.regionId) {
      this.eventService.refreshCurrentEvent(this.currentEventId).pipe(
        takeUntil(this._subscribedSubjects$),
        tap((_) => {
          this.patchFormFromCurrentEvent();
          this.isFormLoading = false;
        })
      ).subscribe();
    } else {
      if (this.eventService.$currentEvent.value?.event?.regionId) {
        this.patchFormFromCurrentEvent();
      }

      this.isFormLoading = false;
    }
  }

  /**
   * For edit, load event data into form
   */
  private patchFormFromCurrentEvent() {
    const eventObj = this.eventService.$currentEvent.value;
    const patch: Partial<IEventDetails> = {};

    for (const key of Object.keys(this.eventTrainingForm.controls)) {
        patch[key] = eventObj.eventDetail[key];
    }

    this.eventTrainingForm.patchValue(patch);

    eventObj.eventPresenters.forEach(ep => {
      this.additionalPresenters.push(this.vaUsers.find(u => u.id === ep.vaUserId));
    });
  }

  public insertNewPresenterRow() {
    this.additionalPresenters.push(null as IVAUser);
  }

  public removeAdditionalPresenter(index: number) {
    this.additionalPresenters.splice(index, 1);
  }

  public selectedPresenter(presenter: IVAUser, index: number) {
    this.additionalPresenters[index] = presenter;
  }

  public isInputInvalid = (formControlName: string): boolean => this.eventTrainingForm.controls[formControlName].invalid 
    && (this.eventTrainingForm.controls[formControlName].dirty || this.eventTrainingForm.controls[formControlName].touched);

    /**
     * Manually check validation and show any/all errors
     * Else save values to service and continue to next step in Event form
     */
  public onSubmit() {
    this.eventTrainingForm.markAllAsTouched();
    this.eventTrainingForm.updateValueAndValidity();
    if (!this.eventTrainingForm.valid) {
      console.warn(this.eventTrainingForm.value);
    } else {
      this.saveFormValuesToCurrentEvent();
      this.router.navigate(['app/event/participants/', this.currentEventId]);
    }
  }

  public onBackClicked(): void {
    if (this.eventTrainingForm.valid) {
      this.saveFormValuesToCurrentEvent();
    }

    this.router.navigate(['app/event/details/', this.currentEventId]);
  }

  /**
   * On submit, assign latest form values to service eventObj
   */
  private saveFormValuesToCurrentEvent() {
    let eventObj = this.eventService.$currentEvent.value;

    for (const [key, control] of Object.entries(this.eventTrainingForm.controls)) {
      eventObj.eventDetail[key] = control.value;
    }

    eventObj.eventPresenters = [];
    this.additionalPresenters.forEach(ap => {
      if(ap != null) {
        eventObj.eventPresenters.push({
          eventId: (this.currentEventId > 0) ? this.currentEventId : 0,
          vaUserId: ap.id
        } as IEventPresenter);
      }
    });

    this.eventService.$currentEvent.next(eventObj);
  }

  public ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
