import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class ExcelReaderService {
    XLSX = require("xlsx");

    /**
     * Will return an observable of data rows from the passed excel file
     * @param file an excel file
     * @returns the data of the rows translated from the file
     */
    public readExcel(file: any): Observable<any> {
        let workbook;
        let excelInJSON;

        const fileReader = new FileReader();
        // init read
        fileReader.readAsArrayBuffer(file);

        return new Observable((observer: Subscriber<any[]>): void => {
            // if success
            fileReader.onload = ((ev: ProgressEvent): void => {
                let binary = "";
                let bytes = new Uint8Array((<any>ev.target).result);
                let length = bytes.byteLength;
                for (let i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }

                // Converts the excel data in to json
                workbook = this.XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
                // only first sheet
                excelInJSON = this.XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

                observer.next(excelInJSON);
                observer.complete();
            })
            // if failed
            fileReader.onerror = (error: any): void => {
                observer.error(error);
            }
        });
    }
}
