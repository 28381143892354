<body>
  <div class="card-body">
    <form id="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <img id="logo" src="assets/VAITSN_Logo_Transparent.png">

      <div class="vaitsn-form-grid-cell vaitsn-field-normal">
        <mat-label>Email</mat-label>
        <div class="vaitsn-form-control">
          <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="email" matInput id="usernameInput" formControlName="username" />
            <mat-error
              *ngIf="form.controls['username'].invalid && (form.controls['username'].dirty || form.controls['username'].touched)"
              class="vaitsn-invalid-container">
              Email required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="vaitsn-form-grid-cell vaitsn-field-normal">
        <mat-label>Password</mat-label>
        <div class="vaitsn-form-control">
          <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input id="passwordInput" class="vaitsn-password-field" [type]="showPassword ? 'text' : 'password'" matInput
              aria-describedby="passwordHelp" formControlName="password" />
            <span (click)="changeVisibility()" id="password-toggle" class=" vaitsn-text-btn">
              {{showPassword ? 'HIDE' : 'SHOW'}}
            </span>
            <mat-error *ngIf="
          form.controls['password'].invalid &&
          (form.controls['password'].dirty ||
            form.controls['password'].touched)" class="vaitsn-invalid-container">
              Password required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <vaitsn-button id="submit-btn" class="login-button" type="submit" color="primary" size="large"
        (clicked)="onSubmit()">Log In</vaitsn-button>

      <a routerLink="/forgot-password" id="forgot-pass" class="vaitsn-hyperlink">Forgot Password</a>
    </form>
  </div>
</body>