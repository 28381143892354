import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ToastService } from '../../core/services/toast/toast.service';

export interface ReportPayloadDto {
    coachEmail: string | null;
    begin: Date | null;
    end: Date | null;
}

/**
 * @todo move blob download to helper function
 */
@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(
        private http: HttpClient,
        private toastService: ToastService,
    ) { }

    private downloadFile = (reportName: string, blobResponse: HttpResponse<Blob>, ) => {
        try {
            const contentDisposition = blobResponse.headers.get('content-disposition');
            const match = contentDisposition?.match(/attachment; filename=([^;]+)/i);
            const fileName = match && match[1];

            // Trigger download of file
            const blobbyLight = new Blob([blobResponse.body], { type: 'application/pdf' });
            const blobUrl = window.URL.createObjectURL(blobbyLight);
            let fileLink = document.createElement('a');
            fileLink.href = blobUrl;
            fileLink.download = fileName ?? reportName; // Set the name of the downloaded file
            fileLink.click();

            this.toastService.success('Download complete.');
        } catch {
            // Catch issue transforming/downloading file response
            this.toastService.error('Download failed.');
        }
    }

    generateReport(reportName: string, payload: ReportPayloadDto) {
        this.toastService.custom({
            severity: 'info',
            summary: 'Downloading report...',
        });
        
        return this.http.post(
            environment.apiBaseURL + `Reports/${reportName}`,
            payload,
            { observe: 'response', responseType: 'blob' })
            .pipe(map((blobResponse: HttpResponse<Blob>) => {
                this.downloadFile(reportName, blobResponse);
            }));
    }
}
