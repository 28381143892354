<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">Child Specific Scale</h1>
    </div>

    <form [formGroup]="ChildSpecificScaleForm" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Consultant]" (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach>
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Referral Name</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="ReferralName">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ReferralName')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Referral Name is Required.
                    </div>
                </div>
            </div>

        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Program/ Classroom/ Child</mat-label>
            <div class="vaitsn-form-control">
                <app-lookup-program-classroom-child (select$)="selectedProgram($event)" ></app-lookup-program-classroom-child>
            </div>
            <div *ngIf="isInputValid('LookupId')" class="vaitsn-invalid-container">
                <div class="alert alert-danger">
                    Program, Classroom, and Child are Required. Click the magnifying glass to search.
                </div>
            </div>
        </div>
        
        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child Age (in months)</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="AgeMonth"  min="0" max="36" type="number">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('AgeMonth')" class="vaitsn-invalid-container-small" >
                    <div class="alert alert-danger">
                        Child Age is Required.
                    </div>
                </div>
            </div>
        </div>


        <!-- for future reference on making the checkboxes autogenerate instead of being static -->
        <!-- <div class="vaitsn-form-grid-cell">
            <mat-label>Health</mat-label>
            <div class="vaitsn-form-control" *ngFor="let question of HealthQuestions">
                <mat-checkbox>{{ question.HealthQuestion }}</mat-checkbox>
            </div>
        </div> -->

        <div class="vaitsn-form-grid-cell">
            <mat-label>Health</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion1">Premature/traumatic birth experience/low birth
                    weight/extended hospital stay</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion2">On prescribed medication/has medical diagnosis/had
                    surgery</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion3">Eats/mouths non-edibles after 15 months of
                    age</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion4">Poor sleep habits - difficulty falling asleep/staying
                    asleep/routinely falls asleep during engaging activities</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion5">Allergies to
                    food/animals/medications/environment</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion6">Poor appetite/picky eater/swallows food without
                    chewing/overstuffs mouth</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="HealthQuestion7">Mouth breathes/drools beyond teething
                    period</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Development</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion1">Concerned child is behind in one/more areas of
                    their development</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion2">Child referred for developmental testing/to Infant
                    Toddler Connection/receiving therapy services</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion3">Developmentally above their peers</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion4">Stiff,tight muscles/very flexible/floppy like a rag
                    doll/drags or does not use one side of the body</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion5">Sensory loss or deficit (hearing/vision, etc.)/loss
                    of limb (arm,leg)</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion6">Toddler does not imitate words or actions of
                    others/pays attention to others' words or actions</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion7">Does not use gestures such as waving, pointing or
                    head shaking to communicate</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion8">Toddles does not seem to know the function of
                    common household object (brush, cellphone, fork, spoon, cup, etc.)</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion9">Does not play with toys as expected for age/does
                    not respond to name</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="DevelopmentQuestion10">Loss or regression of skills or
                    behaviors</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Social/Emotional Development</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion1">Delayed/different social-emotional patterns
                    when compared to peers</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion2">Does not express emotions or affection/does not
                    respond appropriately to others' emotions/becomes easily upset/fearful/worries</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion3">Attached to only one person/not attached to any
                    person/extreme difficulty separating</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion4">Does not initiate play/does not explore the
                    environment/cannot play alone for a few minutes</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion5">Rigid with routines/extreme difficulty waiting
                    for something they want/fixated on one object or person</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion6">Toddles does not express their wants and needs
                    verbally.does not regulate their emotions</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion7">Does not use gestures such as waving, pointing,
                    or head shaking to communicate</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="SocialEmotionalQuestion8">Does not smile/avoids eye contact/ fleeting eye
                    contact</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Behavior</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion1">Concerns for child's safety/child doesn't recognize
                    danger/does not respond or register pain</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion2">Too passive/refuses to try/easily frustrated/refuses
                    to follow requests</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion3">Tantrums lasting more than 30 minutes/frequent
                    tantrums throughout the day</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion4">Constantly in motion/lethargic/hand
                    flopping/twirling</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion5">Sits very close to others/enjoys rough
                    housing/bumps/runs away</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion6">Frequently call parent to pick the child up
                    early/prefers when child is absent</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion7">Hits/kicks/bites/spits/throws object/falls
                    out</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion8">Self-injurious behaviors/head banging/skin
                    picking/rocking</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="BehaviorQuestion9">Sensitive to or prefers certain sounds, light, or
                    textures</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Engagement</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion1">Toddles is often withdrawn/prefers to be alone/in
                    their world</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion2">Toddler has difficulty taking turns/playing with
                    others</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion3">Difficulty maintaining the child's interest in
                    activities/objects/people</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion4">Another teacher can handle/address the child's needs
                    better</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion5">Challenging/difficult to interact with/bond with the
                    child</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion6">Difficult to acknowledge the child
                    physically/verbally praise the child</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EngagementQuestion7">Toddler struggles with personal
                    space/boundaries</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Trauma</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="TraumaQuestion1">Household changes in the last 6 months -
                    deployment/incarceration, divorce/separation, hospitalization</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="TraumaQuestion2">CPS involvement/foster care</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="TraumaQuestion3">Custodial issues/grandparents raising child/other
                    custodians raising child</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="TraumaQuestion4">Family experienced adversities/hardships in the last 6
                    months (death, pet death, disability, etc.)</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="TraumaQuestion5">Known history of trauma/abuse/neglect</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Environmental/Cultural</mat-label>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion1">Bilingual/English as a second
                    language/deaf household</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion2">Concerns for neighborhood safety/gang
                    violence concerns</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion3">Family lacks transportation/access to
                    grocery store or other community resources</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion4">Family experienced a natural
                    disaster/fire/accident/burglary</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion5">Family has housing instability/lives with
                    others/resides in a shelter</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion6">Family receives child care
                    subsidy</mat-checkbox>
            </div>
            <div class="vaitsn-form-control">
                <mat-checkbox class="vaitsn-checkbox" formControlName="EnvironmentalCulturalQuestion7">Family is isolated/no community
                    involvement</mat-checkbox>
            </div>
        </div>

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>
    </form>
</div>