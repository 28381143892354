import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { 
  Subject, 
  filter, 
  map, 
  takeUntil, 
} from 'rxjs';
//for vaUsers
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
//lookup program and classroom and child
import { ILookupAssociatedClassProgramChild } from '../../models/interfaces/lookup-associated-class-program-child.interface';
//ASQ3 -- used for post
import { ASQ } from '../../models/interfaces/asq3.interface';
import { ASQService } from '../../services/asq3.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
  selector: 'app-asq',
  templateUrl: './asq.component.html',
  styleUrls: ['./asq.component.scss']
})

export class ASQComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermisson = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;
  public ASQForm: FormGroup = null; 
  public ASQFormSubmit: ASQ = null;

  constructor(
    public vaUserService: VAUserService,
    private asqService: ASQService,
    private router: Router,
    private toast: ToastService,
    ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
  }

  getVAUserDetail() {
      this.vaUserService.currentAssociatedVAUser$.pipe(
        filter(c => c !== null),
        takeUntil(this._subscribedSubjects$),
        map((vaUser) => {
          this.currentVAUser = vaUser;
          if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
            this.ASQForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
          }
        })
      ).subscribe();
  }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.ASQForm.patchValue({
      Program: value.programId, 
    });
    this.ASQForm.patchValue({
      Classroom: value.classroomId, 
    });
    this.ASQForm.patchValue({
      Child: value.childId, 
    });
    this.ASQForm.patchValue({
      LookupId: value.id
    })
  }

  public selectedVAUser( value: IVAUser){
    this.currentVAUser = value;
    this.ASQForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
  }

  private initForm() {
    this.ASQForm = new FormGroup({
      User: new FormControl(null, [Validators.required] ),
      DateSubmitted: new FormControl({ value: new Date(), disabled: true }),
      LookupId: new FormControl('', [Validators.required]),
      Date: new FormControl(new Date(), [Validators.required]),
      AgeMonth: new FormControl('', [Validators.required]),
      Communication: new FormControl(null, [Validators.required]),
      GrossMotor: new FormControl(null, [Validators.required]),
      FineMotor: new FormControl(null, [Validators.required]),
      ProblemSolving: new FormControl(null, [Validators.required]),
      PersonalSocial: new FormControl(null, [Validators.required]),
      AdjustForPrematurity: new FormControl(null, [Validators.required]),
      }
    );
  }

  public isInputValid = (formControlName: string): boolean => (!this.ASQForm.get(formControlName)?.valid && (this.ASQForm.get(formControlName).dirty || (this.ASQForm.get(formControlName).touched)));

  public onSubmit() {
    this.ASQForm.markAllAsTouched();
    if (!this.ASQForm.valid ) {
      console.warn(this.ASQForm.value);
    } else {
      this.finalizeAsqFormData();
      this.asqService.post(this.ASQFormSubmit)
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }

  finalizeAsqFormData(){
    this.ASQForm.patchValue({ User: this.currentVAUser.id})
    this.ASQFormSubmit = {
      VAUserId: this.ASQForm.controls['User'].value,
      Created: this.ASQForm.controls['DateSubmitted'].value,
      LookupAssociatedClassProgramChildId: this.ASQForm.controls['LookupId'].value,
      Date: this.ASQForm.controls['Date'].value,
      AgeMonth: this.ASQForm.controls['AgeMonth'].value,
      Communication: this.ASQForm.controls['Communication'].value,
      GrossMotor: this.ASQForm.controls['GrossMotor'].value,
      FineMotor: this.ASQForm.controls['FineMotor'].value,
      ProblemSolving: this.ASQForm.controls['ProblemSolving'].value,
      PersonalSocial: this.ASQForm.controls['PersonalSocial'].value,
      IsAdjustedForPrematurity: this.ASQForm.controls['AdjustForPrematurity'].value,
      CreatedByUser: 'a',
      LastUpdatedUser: 'b',
    }
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
  
}
