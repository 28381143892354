import { User } from "./user";

export interface IDocument {
  id: string;
  name: string;
  propertyId: string;
  categoryId: string;
  comment: string;
  status: string;
  hasSensitiveInfo: boolean;
  createDate: Date;
  createdBy: User;
}

export class Document implements IDocument {
  id: string;
  name: string;
  propertyId: string;
  categoryId: string;
  comment: string;
  status: string;
  hasSensitiveInfo: boolean;
  createDate: Date;
  createdBy: User;

  constructor(init?: Partial<IDocument>) {
    if (init?.id) this.id = init.id;
    if (init?.name) this.name = init.name;
    if (init?.propertyId) this.propertyId = init.propertyId;
    if (init?.categoryId) this.categoryId = init.categoryId;
    if (init?.comment) this.comment = init.comment;
    if (init?.status) this.status = init.status;
    this.hasSensitiveInfo = init.hasSensitiveInfo;
    if (init?.createDate) this.createDate = init.createDate;
    if (init?.createdBy) this.createdBy = init.createdBy;
  }
}

export interface ICategoryGroup {
  id: string;
  name: string;
  number: string;
  status: string;
  fileInfos: IDocument[];
}

export class CategoryGroup {
  id: string;
  name: string;
  number: string;
  status: string;
  documents: Document[] = [];
  documentNames: string[] = [];

  constructor(init?: Partial<ICategoryGroup>) {
    if (init?.id) this.id = init.id;
    if (init?.name) this.name = init.name;
    if (init?.number) this.number = init.number;
    if (init?.status) this.status = init.status;
    if (init?.fileInfos) {
      this.documents = init.fileInfos.map(file => new Document(file));
      this.documentNames = init.fileInfos.map(file => file.name);
    }
  }
}

export interface IFileUpload {
  file: File;
  categoryId: string;
  comment: string;
  status: string;
  hasSensitiveInfo: boolean;
  name: string;
}

export interface IDocumentCategory {
  id: string;
  name: string;
  number: number;
  propertyId: string;
}
  