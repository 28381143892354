import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";
import { IActionOdataResult } from "../models/interfaces/action-result.interface";
import { IProgram } from "../models/interfaces/program.interface";
import { IParamObj, IParamQuery } from "../models/interfaces/param-query.interface";

@Injectable({
    providedIn: 'root'
})
export class ProgramService {

    constructor(private http: HttpClient, private userService: UserService) { }
    /**
     * Returns an array of regions based on queryParams
     */
    public get(params?: IParamObj): Observable<Array<IProgram>> {
        const paramQuery: IParamQuery = (params) ? {params: params} : null;
        return this.getPrograms(paramQuery).pipe(map((result) => result.value as Array<IProgram>));
    }

    private getPrograms(paramQuery: IParamQuery): Observable<IActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<IActionOdataResult>(environment.apiBaseURL + 'odata/VPrograms', httpParams);
    }
}