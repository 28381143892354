import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Subject,
  filter,
  map,
  takeUntil,
} from 'rxjs';
//for vaUsers
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';
import { IVAUser } from '../../models/interfaces/va-user.interface';
//lookup program and classroom and child
import { ILookupAssociatedClassProgramChild } from '../../models/interfaces/lookup-associated-class-program-child.interface';
//ASQSE -- used for posting
import { ASQSE } from '../../models/interfaces/asqse.interface';
import { ASQSEService } from '../../services/asqse.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
  selector: 'app-asqse',
  templateUrl: './asqse.component.html',
  styleUrls: ['./asqse.component.scss']
})

export class ASQSEComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;
  public ASQSEForm: FormGroup = null;
  public ASQSEFormSubmit: ASQSE = null;

  constructor(
    public vaUserService: VAUserService,
    private asqseService: ASQSEService,
    private router: Router,    
    private toast: ToastService,

  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();
  }

  getVAUserDetail() {
      this.vaUserService.currentAssociatedVAUser$.pipe(
        filter(c => c !== null),
        takeUntil(this._subscribedSubjects$),
        map((vaUser) => {
          this.currentVAUser = vaUser;
          if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
            this.ASQSEForm.patchValue({ User: this.currentVAUser.id })
          }
        })
      ).subscribe()
    }

  public selectedProgram(value: ILookupAssociatedClassProgramChild) {
    this.ASQSEForm.patchValue({
      Program: value.programId,
    });
    this.ASQSEForm.patchValue({
      Classroom: value.classroomId,
    });
    this.ASQSEForm.patchValue({
      Child: value.childId,
    });
    this.ASQSEForm.patchValue({
      LookupId: value.id
    })
  }

  public selectedVAUser(value: IVAUser){
    this.currentVAUser = value;
    this.ASQSEForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
  }

  private initForm() {
    this.ASQSEForm = new FormGroup({
      User: new FormControl(null, [Validators.required]),
      DateSubmitted: new FormControl({ value: new Date(), disabled: true }, [Validators.required]),
      Program: new FormControl('', [Validators.required]),
      Classroom: new FormControl('', [Validators.required]),
      Child: new FormControl('', [Validators.required]),
      LookupId: new FormControl('', [Validators.required]),
      Date: new FormControl(new Date(), [Validators.required]),
      AgeMonth: new FormControl('', [Validators.required]),
      SocialEmotionalScore: new FormControl(null, [Validators.required]),
    }
    );
  }

  public isInputValid = (formControlName: string): boolean => (!this.ASQSEForm.get(formControlName)?.valid && (this.ASQSEForm.get(formControlName).dirty || (this.ASQSEForm.get(formControlName).touched)));

  public onSubmit() {
    this.ASQSEForm.markAllAsTouched();
    if (!this.ASQSEForm.valid) {
      console.warn(this.ASQSEForm.value);
    } else {
      this.finalizeAsqseFormData();
      this.asqseService.post(this.ASQSEFormSubmit)
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }

  finalizeAsqseFormData(){
    this.ASQSEForm.patchValue({ User: this.currentVAUser.id });
    this.ASQSEFormSubmit = {
      VAUserId: this.ASQSEForm.controls['User'].value,
      Created: this.ASQSEForm.controls['DateSubmitted'].value,
      LookupAssociatedClassProgramChildId: this.ASQSEForm.controls['LookupId'].value,
      Date: this.ASQSEForm.controls['Date'].value,
      AgeMonth: this.ASQSEForm.controls['AgeMonth'].value,
      SEScore: this.ASQSEForm.controls['SocialEmotionalScore'].value,
      CreatedByUser: 'a',
      LastUpdatedUser: 'b',
    }
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
