import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { ClassScoresRequest, IActionResult } from "../models";

@Injectable({
    providedIn: 'root'
})

export class ClassScoresService {
    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Post CLASS scores form data to api
     */
    public post(event: ClassScoresRequest): Observable<IActionResult> {
        return this.postClassScoresForm(event).pipe(map((result) => result.data as IActionResult));
    }

    public postClassScoresForm(ClassScoresFormSubmittedData: ClassScoresRequest) {
        return this.http.post<IActionResult>(environment.apiBaseURL + "ClassScores/CreateClassScores", ClassScoresFormSubmittedData);
    }
}