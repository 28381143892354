<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">ASQSE Form</h1>
    </div>
    
    <form [formGroup]="ASQSEForm" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">
            
            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Consultant]" (selectVAUser$)="selectedVAUser($event)" subscriptSizing="dynamic"></app-lookup-coach>
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Program/ Classroom/ Child</mat-label>
            <div class="vaitsn-form-control">
                <app-lookup-program-classroom-child (select$)="selectedProgram($event)" ></app-lookup-program-classroom-child>
                <div *ngIf="isInputValid('LookupId')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Program, Classroom, and Child are Required. Click the magnifying glass to search.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-3col">
            <div class="vaitsn-form-grid-cell">
                <mat-label>Observation Date</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="Date" formControlName="Date" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="Date"></mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                </div>

                <div *ngIf="isInputValid('Date')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Observation Date is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child Age (in months)</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min="0" max="36" placeholder="" matInput formControlName="AgeMonth">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('AgeMonth')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Child Age is Required.
                    </div>
                </div>
            </div>
            
            <div class="vaitsn-form-grid-cell">
                <mat-label>SE Score</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01"  matInput placeholder="0.00" formControlName="SocialEmotionalScore">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('SocialEmotionalScore')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Social Emotional Score is Required.
                    </div>
                </div>
            </div>
        </div>
    
        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>

    </form>
</div>