<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">CLASS Scores</h1>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach>
                    <div *ngIf="isInputInvalid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <br>
        <label>If you are not the observer you can change the name manually</label>
        <br>

        <div class="vaitsn-form-grid-3col">
            
            <div class="vaitsn-form-grid-cell">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Observation Date</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field appearance="outline" class="vaitsn-date">
                            <input matInput [matDatepicker]="ObservationDate" formControlName="ObservationDate" placeholder="MM/DD/YYYY">
                            <mat-datepicker-toggle matIconSuffix [for]="ObservationDate"></mat-datepicker-toggle>
                            <mat-datepicker #ObservationDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isInputInvalid('ObservationDate')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Observation Date is required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Observer First Name</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="text" matInput formControlName="ObserverFirstName">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('ObserverFirstName')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Observer First Name is required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Observer Last Name</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="text" matInput formControlName="ObserverLastName">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputInvalid('ObserverLastName')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Observer Last Name is required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid">
            <div class="vaitsn-form-grid-cell">
                <mat-label>Classroom</mat-label>
                <div class="vaitsn-form-control">
                    <app-lookup-program-classroom (select$)="selectedProgram($event)" ></app-lookup-program-classroom >
                </div>
                <div *ngIf="isInputInvalid('ClassroomId')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Classroom is required. Click the magnifying glass to search.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Class Notes</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="ClassNotes" placeholder="Enter Class Notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-4col-custom">
            
            <div class="vaitsn-form-grid-cell">
                <mat-label>Pre or Post</mat-label>
                <div class="vaitsn-form-control">
                    <mat-radio-group formControlName="PrePost" class="vaitsn-radio-list">
                        <ng-container  *ngFor="let prepost of PrePosts"> 
                            <mat-radio-button class="vaitsn-radio-button" [value]="prepost">
                                {{ prepost.PrePostCD }}
                            </mat-radio-button>
                            <br>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputInvalid('PrePost')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Pre or Post is required. Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Tool Type</mat-label>
                <div class="vaitsn-form-control">
                    <ng-container *ngFor="let toolType of toolTypeOptions">
                        <input type="radio" [value]="toolType.value" formControlName="toolType"> {{ toolType.label }}
                        <br/>
                    </ng-container>
                </div>
                <div *ngIf="isInputInvalid('toolType')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Tool Type is required. Please make a selection.
                    </div>
                </div>
            </div>
        </div>
        
        
        <div formArrayName="formArray">
            <div *ngFor="let group of formArray.controls; let i = index" [formGroupName]="i">
                <div class="vaitsn-form-grid-3col-custom">
                    <ng-container *ngFor="let controlName of toolTypeControls">
                        <div class="vaitsn-form-grid-cell">
                            <mat-label>{{controlName}}</mat-label>
                            <div class="vaitsn-form-control" class="vaitsn-text-field">
                                <mat-form-field appearance="outline">
                                    <input matInput type="number" step="0.01" placeholder="0.00"
                                        [formControlName]="controlName">
                                </mat-form-field>
                            </div>
                            <div *ngIf="isInputInvalid(controlName, true, i) || isInvalidDecimalFormat(controlName, 2, true, i)"
                                class="vaitsn-invalid-container-small">
                                <div class="alert alert-danger">
                                    {{controlName}} is required (1.00-7.00).
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
                
        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>

    </form>
</div>