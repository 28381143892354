import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import { IParamObj, IVAUser } from '../../models';
import { VAUserPermission, VAUserRole, VAUserService } from '../../services/va-user.service';

/**
 * @todo optional input to filter program-class lookup by selected coach's region
 */
@Component({
  selector: 'app-lookup-coach',
  templateUrl: './lookup-coach.component.html',
  styleUrls: ['./lookup-coach.component.scss']
})

export class LookupCoachComponent implements OnInit {
  @Input() currentvaUser: IVAUser = null;
  @Input() permissionFilter: VAUserPermission[] = null;
  @Output() selectVAUser$ = new EventEmitter<IVAUser>();

  public isNotSearching: boolean = true;
  public vaUsers$: Observable<Array<IVAUser>>;
  private vaUsersSearchText$ = new Subject<string>();
  private vaUserODataFilterBuilder = ODataFilterBuilder();

  constructor(
    private vaUserService: VAUserService,
  ) { }

  ngOnInit(): void {
    this.getvaUserList();
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public vaUserSearch(searchString: string) {
    this.buildFilter(searchString);
    this.vaUsersSearchText$.next(searchString);
  }

  public vaUserDisplay = (vaUser: IVAUser): string => vaUser.firstName + " " + vaUser.lastName

  private buildFilter(searchString: string) {

    this.vaUserODataFilterBuilder = ODataFilterBuilder('and')
      .eq('Deleted', null)
      .and(`VAUserRoles/any(userrole: userrole/role/normalizedName eq '${VAUserRole.Coach}')`)
      .and(
        ODataFilterBuilder('or')
          .contains('firstName', searchString)
          .contains('lastName', searchString)
          .contains('UserName', searchString)
      );

    if(this.permissionFilter){
        this.vaUserODataFilterBuilder.and(() => {
            let odataPermFilter = ODataFilterBuilder();
            this.permissionFilter.forEach(element => {
                odataPermFilter.or(`VAUserPermissions/any(userpermission: userpermission/permission/normalizedName eq '${element.toString()}' )`);
            });
            return odataPermFilter;
        });
    }
  }

  onSelect(vaUsers: IVAUser) {
    this.selectVAUser$.emit(vaUsers);
  }

  getvaUserList() {
    this.vaUsers$ = this.vaUsersSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        const params = {
          $filter: this.vaUserODataFilterBuilder.toString(),
          $expand: 'VAUserPermissions($expand=Permission)',
          $top: 10
        } as IParamObj;
        return this.vaUserService.get(params)
      })
    );
  }
}