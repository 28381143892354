import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { IActionResult, IClassEnvironmentScore, } from "../models";

@Injectable()
export class ClassEnvironmentScoreService {
    private readonly endpoint = environment.apiBaseURL + 'ClassEnvironmentScore/';
    
    constructor(
        private http: HttpClient,
    ) { }
    
    public postForm(event: IClassEnvironmentScore): Observable<IActionResult> {
        return this.post(event).pipe(map((result) => result.data as IActionResult));
    }

    private post(ClassScoresFormSubmittedData: IClassEnvironmentScore) {
        return this.http.post<IActionResult>(this.endpoint + 'Create', ClassScoresFormSubmittedData);
    }
}