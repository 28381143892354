<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1>M-Chat-R Entry Form</h1>
    </div>

    <form [formGroup]="MChatRForm" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Consultant]" (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach>
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DateSubmitted" formControlName="DateSubmitted">
                        <mat-datepicker-toggle matIconSuffix [for]="DateSubmitted"></mat-datepicker-toggle>
                        <mat-datepicker #DateSubmitted></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <mat-label>Program/ Classroom/ Child</mat-label>
            <div class="vaitsn-form-control">
                <app-lookup-program-classroom-child (select$)="selectedProgram($event)" ></app-lookup-program-classroom-child>
            </div>
            <div *ngIf="isInputValid('LookupId')" class="vaitsn-invalid-container">
                <div class="alert alert-danger">
                    Program, Classroom, and Child are Required. Click the magnifying glass to search.
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-2col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child Age (in months)</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" min="2" max="48" placeholder="" matInput formControlName="AgeMonth">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('AgeMonth')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Child Age is Required.
                    </div>
                </div>
            </div>      

            <div class="vaitsn-form-grid-cell">
                <mat-label>Observation Date</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="ObservationDate" formControlName="ObservationDate" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="ObservationDate"></mat-datepicker-toggle>
                        <mat-datepicker #ObservationDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ObservationDate')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Observation Date is Required.
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Low Risk Score</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" matInput formControlName="LowRiskScore">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('LowRiskScore')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Low Risk Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Medium Risk Score</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" matInput formControlName="MediumRiskScore">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('MediumRiskScore')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Medium Risk Score is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>High Risk Score</mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <input type="number" matInput formControlName="HighRiskScore">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HighRiskScore')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        High Risk Score is Required.
                    </div>
                </div>
            </div>
        </div>
        
        <div class="vaitsn-form-grid-2col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Referral</mat-label>
                <div class="vaitsn-form-control">
                    <mat-radio-group formControlName="Referral">
                        <mat-radio-button class="vaitsn-radio-button" [value]="true">Yes</mat-radio-button>
                        <br>
                        <mat-radio-button class="vaitsn-radio-button" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="isInputValid('Referral')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Referral is Required. Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell" *ngIf="MChatRForm.controls['Referral'].value == true">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Referral Agency</mat-label>
                    <div class="vaitsn-form-control">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput formControlName="ReferralAgency"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>
    </form>
</div>