<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <h1 class="form-h1">Participants</h1>

    <div class="widget-container flex-box">
        <dx-file-uploader #fileUploader name="fileUploader" (onValueChanged)="onFileChange($event)" [multiple]="false"
            [allowedFileExtensions]="['.csv','.xlsx', '.xls']" [accept]="'.csv,.xlsx,.xls'" uploadMode="useForm"
            dialogTrigger="#dropzone-external" dropZone="#dropzone-external" selectButtonText="Import Participants"
            [visible]="true" [showFileList]="false">
        </dx-file-uploader>
    </div>

    <div class="participants-container">
        <div class="participants-header participants-grid">
            <div>First Name</div>
            <div>Last Name</div>
            <div>Staff Title</div>
            <div>Partner</div>
            <div>IsVqb5</div>
            <div></div>
        </div>
        <hr />
        <div *ngFor="let participant of participants; let i = index" class="participants-grid p-row">
            <div class="participant-col">
                <input style="background:white;border:1px solid darkgray;" 
                        placeholder="Required" class="participant-input" 
                        [(ngModel)]="participant.firstName"/>
            </div>
            <div class="participant-col">
                <input style="background:white;border:1px solid darkgray;" 
                        placeholder="Required" class="participant-input" 
                        [(ngModel)]="participant.lastName" />
            </div>
            <div class="participant-col">
                <mat-form-field appearance="outline" class="vaitsn-select">
                    <mat-select placeholder="Required" [(ngModel)]="participant.staffTitleId">
                        <mat-option *ngFor="let st of staffTitles" [value]="st.id">{{ staffTitleDisplay(st) }}</mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="participant-col">
                <input style="background:white;border:1px solid darkgray;"
                        class="participant-input"
                        [(ngModel)]="participant.partner" />
            </div>
            <div class="participant-col">
                <mat-checkbox [(ngModel)]="participant.isVqb5"></mat-checkbox>
            </div>
            <div class="participant-col">
                <a><mat-icon class="cancel-icon" aria-hidden="false" (click)="removeParticipant(i)" aria-label="cancel"
                        fontIcon="cancel"></mat-icon></a>
            </div>
        </div>
    </div>
    <div class="insert-row-container">
        <vaitsn-button color="primary" [hasLoading]="false" size="square" customWidth="100%" customHeight="40px" 
            class="vaitsn-btn-primary" (click)="insertNewRow()">
            Insert New Participant
        </vaitsn-button>
    </div>
    <div class="remove-container">
        <div>
            <vaitsn-button color="secondary" class="vaitsn-btn-primary" (click)="removeAll()">
                Remove All
            </vaitsn-button>
        </div>
    </div>
    <div class="custom-mat-error">
        <mat-error *ngIf="this.participantRequired">
            One or more valid Participants is required.
        </mat-error>
        <mat-error *ngIf="invalidParticipantsInList">
            <strong>Please make sure that all the participants' First Name, Last Name, and Staff Title fields
            are filled in.</strong>
        </mat-error>
    </div>
    <div class="vaitsn-form-submit-container">
        <vaitsn-button color="secondary" (click)="onBackClicked()">
            Previous
        </vaitsn-button>
        <div></div>
        <vaitsn-button *ngIf="this.currentEventId === 0; else editSubmit" 
            color="primary" class="vaitsn-btn-primary" role="submit" (submitted)="onSubmit()">
            Finish & Submit
        </vaitsn-button>
        <ng-template #editSubmit>
            <vaitsn-button color="primary" class="vaitsn-btn-primary" role="submit" (submitted)="onSubmit()">
                Update
            </vaitsn-button>
        </ng-template>
    </div>
</div>