import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription, takeUntil, timer } from 'rxjs';
import { EventService } from '../../services/event/event.service';

@Component({
    selector: 'app-upload-status',
    template: `
    <div class="text-center">
        <ng-container *ngIf="uploadedCount > 0">
            {{uploadedCount}} file{{uploadedCount>1?'s':''}} uploaded
        </ng-container>
        <ng-container *ngFor="let upload of uploadsInProgress; let i = index;">
            <ng-container *ngIf="i < 2">
                <div>{{upload.fileCount}} file{{upload.fileCount>1?'s':''}} {{upload.progress < 100 ? 'uploading': 'uploaded'}}</div>
                <p-progressBar [value]="upload.progress"></p-progressBar>
                <div *ngIf="upload.failed" style="color: #EF4444;">
                    <i class="pi pi-exclamation-circle" style="font-size: 1rem;"></i>
                    Failed
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="uploadsInProgress.length > 2">
            + {{uploadsInProgress.length - 2}} more
        </ng-container>
    </div>
  `,
    styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<boolean> = new Subject();
    uploadedCount: number = 0;
    closeDialogSubscription: Subscription;
    uploadsInProgress: UploadProgress[] = [];

    constructor(
        private dialogRef: DynamicDialogRef,
        private eventService: EventService,
    ) {}

    ngOnInit() {
        this.dialogRef.onClose.subscribe(_ => {
            this.eventService.uploadProgressClosedNotify();
        })

        this.eventService.uploadsInProgress$.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
            this.closeDialogSubscription?.unsubscribe();
            const id = new Date().toString();
            this.uploadsInProgress.push({
                id,
                progress: 0,
                fileCount: event.fileCount,
                failed: false,
                fakeApiProgressSubscription: null
            });

            const upload = this.uploadsInProgress.find(u => u.id == id);
        
            event.progressSubject.subscribe((progress) => {
                this.handleProgressUpdates(progress, upload);
            })
        })
    }

    handleProgressUpdates(progress: number, upload: UploadProgress): void {
        if (progress == -1) { // Error
            upload.failed = true;
            upload.fakeApiProgressSubscription?.unsubscribe();
        
        } else {
            upload.progress = progress;
            
            if (upload.progress == 50) { // data was sent to API

                // after data was sent to the API we just wait for a response from the API
                // increase the progress per minute spent waiting
                upload.fakeApiProgressSubscription = timer(1000*60).subscribe(() => {
                    if (upload.progress >= 85) { 
                        // at this point either it's taking way too long (more than 35 minutes) 
                        // or there was an error.
                        upload.fakeApiProgressSubscription?.unsubscribe();
                    } else {
                        upload.progress++;
                    }
                });
            }
            else if (upload.progress == 100) { // 100 means we got a final response from API
                this.uploadedCount += upload.fileCount;
                upload.fakeApiProgressSubscription?.unsubscribe();
                const uploadIndex = this.uploadsInProgress.findIndex(u => u.id == upload.id);
                if (uploadIndex > -1) {
                    this.uploadsInProgress.splice(uploadIndex, 1);
                }
                if (this.uploadsInProgress.length == 0) {
                    this.closeDialogSubscription = timer(1000 * 10).subscribe(_ => {
                        this.dialogRef.close();
                    })
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
        this.uploadsInProgress.forEach(u => {
            u.fakeApiProgressSubscription.unsubscribe();
        });
    }
}

export interface UploadProgress {
    id: string,
    progress: number,
    fileCount: number,
    failed: boolean,
    fakeApiProgressSubscription: Subscription;
}
