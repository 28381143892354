<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="editor-page">
    <div class="card-body">
      <form [formGroup]="form" id="user-editor-form" (ngSubmit)="onSubmitUser()">

        <!-- first name -->
        <div class="input-container">
          <label for="firstName" class="vaitsn-input-label">First Name:
          </label>
            <input type="text" id="firstName" placeholder="First name" class="vaitsn-text-box" [ngClass]="{
                'form-control': true,
                'is-invalid':
                  form.controls['firstName'].invalid
                  &&
                  (form.controls['firstName'].dirty || form.controls['firstName'].touched)
              }" formControlName="firstName" />
            <ng-container *ngIf="
                form.controls['firstName'].invalid
                &&
                (form.controls['firstName'].dirty || form.controls['firstName'].touched)
              ">
              <div class="vaitsn-invalid-feedback" *ngIf="form.controls['firstName'].errors?.['required']">
                Please enter a first name.
              </div>
            </ng-container>
        </div>

        <!-- last name -->
        <div class="input-container">
          <label for="lastName" class="vaitsn-input-label">Last Name:
          </label>
            <input type="text" id="lastName" placeholder="Last name" class="vaitsn-text-box" [ngClass]="{
                'form-control': true,
                'is-invalid':
                  form.controls['lastName'].invalid
                  &&
                  (form.controls['lastName'].dirty || form.controls['lastName'].touched)
              }" formControlName="lastName" />
            <ng-container *ngIf="
                form.controls['lastName'].invalid
                &&
                (form.controls['lastName'].dirty || form.controls['lastName'].touched)
              ">
              <div class="vaitsn-invalid-feedback" *ngIf="form.controls['lastName'].errors?.['required']">
                Please enter a last name.
              </div>
            </ng-container>
        </div>

        <!-- Email .... it wants an email -->
        <div class="input-container">
          <label for="userName" class="vaitsn-input-label">Email:
          </label>
            <input type="email" id="userName" placeholder="Email address" class="vaitsn-text-box" formControlName="userName" [ngClass]="{
                'form-control': true,
                'is-invalid':
                  form.controls['userName'].invalid
                  &&
                  (form.controls['userName'].dirty || form.controls['userName'].touched)
              }" />
            <ng-container *ngIf="
                form.controls['userName'].invalid
                &&
                (form.controls['userName'].dirty || form.controls['userName'].touched)
              ">
              <div class="vaitsn-invalid-feedback" *ngIf="form.controls['userName'].errors?.['required']">
                Please enter a email.
              </div>
              <div class="vaitsn-invalid-feedback" *ngIf="form.controls['userName'].errors?.['email']">
                Please enter a valid email.
              </div>
            </ng-container>
        </div>

        <!-- phone number -->
        <div class="input-container">
          <label for="phoneNumber" class="vaitsn-input-label">Phone Number:
          </label>
          <!-- TODO: Replace PrimeNG phone number with Material -->
            <p-inputMask mask="(999) 999-9999" placeholder="(999) 999-9999" formControlName="phoneNumber" class="vaitsn-text-box"
              [ngClass]="{'is-invalid':form.controls['phoneNumber'].invalid }">
            </p-inputMask>
          <br />
        </div>

       
        <!-- roles and submission  -->
        <ng-content select="[roleManagement]"></ng-content>
        <div class="d-flex flex-row-reverse mt-2">
          <button id="submit-btn" class="vaitsn-btn-primary" type="submit">Submit</button>
          <button type="button" id="cancel-btn" class="vaitsn-btn-primary" (click)="onCancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>