import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// App
import { TravelVoucherDetailsComponent } from './travel-voucher-details/travel-voucher-details.component';
import { TravelVouchersComponent } from './travel-vouchers/travel-vouchers.component';

const routes: Route[] = [
    {
        path: '',
        component: TravelVouchersComponent,
    },
    {
        path: 'voucher/:travelVoucherId',
        component: TravelVoucherDetailsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TravelRoutingModule { }
