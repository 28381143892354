import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  IClassroom,
  ILookupAssociatedClassProgramChild,
  IParamObj,
  IProgram,
  IProgramSearchQuery,
} from '../../models';
import { LookupAssociatedClassProgramChildService } from '../../services/lookup-associated-class-program-child.service';

@Component({
  selector: 'app-lookup-program-classroom',
  templateUrl: './lookup-program-classroom.component.html',
  styleUrls: ['./lookup-program-classroom.component.scss']
})
export class LookupProgramClassroomComponent implements OnInit, OnChanges {
  @Input() currentProgram: IProgram = null;
  @Input() currentClassroom: IClassroom = null;
  @Output() select$ = new EventEmitter<ILookupAssociatedClassProgramChild>();
  @Input() classRegionFilter: number = null;

  public isNotSearching: boolean = true;
  public associatedClassPrograms$: Observable<Array<ILookupAssociatedClassProgramChild>>;
  private programSearchText$ = new Subject<string>();
  private programODataFilterBuilder = ODataFilterBuilder();
  public searchQuery: IProgramSearchQuery;

  constructor(
    private lacpcService: LookupAssociatedClassProgramChildService
  ) { }

  ngOnInit() {
    this.getPrograms();
  }

  /**
   * When change coach/region in parent component,
   * clear selected prog/class (switching regions so no longer valid class selection)
   * and if searching, update region search filter/results
   * emit change to form
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['classRegionFilter'] != null && !changes['classRegionFilter'].firstChange) {
      this.currentProgram = null;
      this.currentClassroom = null;
      this.select$.emit(null);

      if (!this.isNotSearching) {
        this.searchQuery.region = this.classRegionFilter;
        this.programSearch(null);
      }
    }
  }

  onSelect(associatedClassPrograms: ILookupAssociatedClassProgramChild) {
    this.currentProgram = associatedClassPrograms.program;
    this.currentClassroom = associatedClassPrograms.classroom;
    this.isNotSearching = true;
    this.select$.emit(associatedClassPrograms);
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public programSearch(searchString: string) {
    this.buildFilter();
    this.programSearchText$.next(searchString);
  }

  public initSearchFilters() {
    this.isNotSearching = false;
    this.searchQuery = {
      region: this.classRegionFilter,
      code: '',
      name: '',
      address: '',
      classroom: '',
      created: ''
    };
  }

  private buildFilter() {
    this.programODataFilterBuilder = ODataFilterBuilder("and")
      .eq('childId', null)
      .contains('Program/code', this.searchQuery.code)
      .contains('Program/name', this.searchQuery.name)
      .contains('Program/address', this.searchQuery.address)
      .contains('Classroom/name', this.searchQuery.classroom);

    this.searchQuery.region && this.programODataFilterBuilder.eq('Program/regionId', this.searchQuery.region);
  }

  private getPrograms() {
    this.associatedClassPrograms$ = this.programSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        const params = { $filter: this.programODataFilterBuilder.toString(), $expand: 'Program($orderby=created desc),Classroom', $top: 8 } as IParamObj;
        return this.lacpcService.get(params)
      })
    );
  }
}
